import {html} from "lit";

export const legacyEnergyLogo = {
    "6-0": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M37.42 33.21L30.64 33.21L30.64 28.26L43.41 28.26L43.41 39.96L43.41 39.96Q41.55 41.76 38.01 43.13L38.01 43.13L38.01 43.13Q34.48 44.50 30.86 44.50L30.86 44.50L30.86 44.50Q26.25 44.50 22.83 42.57L22.83 42.57L22.83 42.57Q19.41 40.64 17.68 37.04L17.68 37.04L17.68 37.04Q15.96 33.45 15.96 29.23L15.96 29.23L15.96 29.23Q15.96 24.64 17.88 21.08L17.88 21.08L17.88 21.08Q19.81 17.51 23.51 15.61L23.51 15.61L23.51 15.61Q26.33 14.15 30.54 14.15L30.54 14.15L30.54 14.15Q36.00 14.15 39.07 16.44L39.07 16.44L39.07 16.44Q42.15 18.74 43.03 22.78L43.03 22.78L37.14 23.88L37.14 23.88Q36.52 21.72 34.81 20.47L34.81 20.47L34.81 20.47Q33.10 19.22 30.54 19.22L30.54 19.22L30.54 19.22Q26.65 19.22 24.36 21.68L24.36 21.68L24.36 21.68Q22.07 24.14 22.07 28.99L22.07 28.99L22.07 28.99Q22.07 34.21 24.39 36.82L24.39 36.82L24.39 36.82Q26.71 39.44 30.48 39.44L30.48 39.44L30.48 39.44Q32.34 39.44 34.21 38.70L34.21 38.70L34.21 38.70Q36.08 37.97 37.42 36.93L37.42 36.93L37.42 33.21Z"/>
        </svg>`
    },
    "6-1": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f36f21" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M22.95 44L17.02 44L17.02 14.65L37.14 14.65L37.14 19.62L22.95 19.62L22.95 26.56L35.20 26.56L35.20 31.53L22.95 31.53L22.95 44Z"/>
        </svg>`
    },
    "6-2": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fdb813" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M39.30 44L16.98 44L16.98 14.65L38.74 14.65L38.74 19.62L22.91 19.62L22.91 26.12L37.64 26.12L37.64 31.07L22.91 31.07L22.91 39.06L39.30 39.06L39.30 44Z"/>
        </svg>`
    },
    "6-3": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff100" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M16.96 44L16.96 14.65L27.79 14.65L27.79 14.65Q31.46 14.65 33.38 15.21L33.38 15.21L33.38 15.21Q35.96 15.97 37.80 17.91L37.80 17.91L37.80 17.91Q39.65 19.86 40.61 22.67L40.61 22.67L40.61 22.67Q41.57 25.48 41.57 29.61L41.57 29.61L41.57 29.61Q41.57 33.23 40.67 35.85L40.67 35.85L40.67 35.85Q39.56 39.06 37.52 41.04L37.52 41.04L37.52 41.04Q35.98 42.54 33.36 43.38L33.36 43.38L33.36 43.38Q31.40 44 28.11 44L28.11 44L16.96 44ZM25.55 19.62L22.89 19.62L22.89 39.06L27.31 39.06L27.31 39.06Q29.80 39.06 30.90 38.77L30.90 38.77L30.90 38.77Q32.34 38.41 33.29 37.55L33.29 37.55L33.29 37.55Q34.24 36.69 34.84 34.72L34.84 34.72L34.84 34.72Q35.44 32.75 35.44 29.35L35.44 29.35L35.44 29.35Q35.44 25.94 34.84 24.12L34.84 24.12L34.84 24.12Q34.24 22.30 33.16 21.28L33.16 21.28L33.16 21.28Q32.08 20.26 30.42 19.90L30.42 19.90L30.42 19.90Q29.17 19.62 25.55 19.62L25.55 19.62Z"/>
        </svg>`
    },
    "6-4": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#bed62f" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M35.76 33.21L35.76 33.21L41.51 35.03L41.51 35.03Q40.19 39.84 37.11 42.17L37.11 42.17L37.11 42.17Q34.04 44.50 29.31 44.50L29.31 44.50L29.31 44.50Q23.47 44.50 19.71 40.51L19.71 40.51L19.71 40.51Q15.94 36.51 15.94 29.59L15.94 29.59L15.94 29.59Q15.94 22.26 19.73 18.20L19.73 18.20L19.73 18.20Q23.51 14.15 29.68 14.15L29.68 14.15L29.68 14.15Q35.06 14.15 38.42 17.33L38.42 17.33L38.42 17.33Q40.43 19.22 41.43 22.74L41.43 22.74L35.56 24.14L35.56 24.14Q35.04 21.86 33.39 20.54L33.39 20.54L33.39 20.54Q31.74 19.22 29.38 19.22L29.38 19.22L29.38 19.22Q26.11 19.22 24.08 21.56L24.08 21.56L24.08 21.56Q22.05 23.90 22.05 29.15L22.05 29.15L22.05 29.15Q22.05 34.71 24.05 37.07L24.05 37.07L24.05 37.07Q26.05 39.44 29.25 39.44L29.25 39.44L29.25 39.44Q31.62 39.44 33.32 37.93L33.32 37.93L33.32 37.93Q35.02 36.43 35.76 33.21Z"/>
        </svg>`
    },
    "6-5": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#50b748" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M17.00 44L17.00 14.65L28.73 14.65L28.73 14.65Q32.22 14.65 33.93 14.94L33.93 14.94L33.93 14.94Q35.64 15.23 36.99 16.15L36.99 16.15L36.99 16.15Q38.34 17.07 39.24 18.61L39.24 18.61L39.24 18.61Q40.15 20.14 40.15 22.04L40.15 22.04L40.15 22.04Q40.15 24.10 39.03 25.82L39.03 25.82L39.03 25.82Q37.92 27.54 36.02 28.40L36.02 28.40L36.02 28.40Q38.70 29.19 40.15 31.07L40.15 31.07L40.15 31.07Q41.59 32.95 41.59 35.49L41.59 35.49L41.59 35.49Q41.59 37.49 40.66 39.39L40.66 39.39L40.66 39.39Q39.73 41.28 38.11 42.41L38.11 42.41L38.11 42.41Q36.50 43.54 34.14 43.80L34.14 43.80L34.14 43.80Q32.66 43.96 26.99 44L26.99 44L17.00 44ZM26.33 19.54L22.93 19.54L22.93 26.32L26.81 26.32L26.81 26.32Q30.28 26.32 31.12 26.22L31.12 26.22L31.12 26.22Q32.64 26.04 33.51 25.17L33.51 25.17L33.51 25.17Q34.38 24.30 34.38 22.88L34.38 22.88L34.38 22.88Q34.38 21.52 33.63 20.67L33.63 20.67L33.63 20.67Q32.88 19.82 31.40 19.64L31.40 19.64L31.40 19.64Q30.52 19.54 26.33 19.54L26.33 19.54ZM27.71 31.21L22.93 31.21L22.93 39.06L28.41 39.06L28.41 39.06Q31.62 39.06 32.48 38.88L32.48 38.88L32.48 38.88Q33.80 38.63 34.63 37.70L34.63 37.70L34.63 37.70Q35.46 36.77 35.46 35.21L35.46 35.21L35.46 35.21Q35.46 33.89 34.82 32.97L34.82 32.97L34.82 32.97Q34.18 32.05 32.97 31.63L32.97 31.63L32.97 31.63Q31.76 31.21 27.71 31.21L27.71 31.21Z"/>
        </svg>`
    },
    "6-6": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
        </svg>`
    },
    "6-7": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
        </svg>`
    },
    "6-8": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
        </svg>`
    },
    "6-9": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
            <path fill="white" d="M85.62 29.91L81.87 29.91L81.87 24.33L76.25 24.33L76.25 20.48L81.87 20.48L81.87 14.89L85.62 14.89L85.62 20.48L91.26 20.48L91.26 24.33L85.62 24.33L85.62 29.91Z"/>
        </svg>`
    },
    "7-0": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M37.42 33.21L30.64 33.21L30.64 28.26L43.41 28.26L43.41 39.96L43.41 39.96Q41.55 41.76 38.01 43.13L38.01 43.13L38.01 43.13Q34.48 44.50 30.86 44.50L30.86 44.50L30.86 44.50Q26.25 44.50 22.83 42.57L22.83 42.57L22.83 42.57Q19.41 40.64 17.68 37.04L17.68 37.04L17.68 37.04Q15.96 33.45 15.96 29.23L15.96 29.23L15.96 29.23Q15.96 24.64 17.88 21.08L17.88 21.08L17.88 21.08Q19.81 17.51 23.51 15.61L23.51 15.61L23.51 15.61Q26.33 14.15 30.54 14.15L30.54 14.15L30.54 14.15Q36.00 14.15 39.07 16.44L39.07 16.44L39.07 16.44Q42.15 18.74 43.03 22.78L43.03 22.78L37.14 23.88L37.14 23.88Q36.52 21.72 34.81 20.47L34.81 20.47L34.81 20.47Q33.10 19.22 30.54 19.22L30.54 19.22L30.54 19.22Q26.65 19.22 24.36 21.68L24.36 21.68L24.36 21.68Q22.07 24.14 22.07 28.99L22.07 28.99L22.07 28.99Q22.07 34.21 24.39 36.82L24.39 36.82L24.39 36.82Q26.71 39.44 30.48 39.44L30.48 39.44L30.48 39.44Q32.34 39.44 34.21 38.70L34.21 38.70L34.21 38.70Q36.08 37.97 37.42 36.93L37.42 36.93L37.42 33.21Z"/>
        </svg>`
    },
    "7-1": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M22.95 44L17.02 44L17.02 14.65L37.14 14.65L37.14 19.62L22.95 19.62L22.95 26.56L35.20 26.56L35.20 31.53L22.95 31.53L22.95 44Z"/>
        </svg>`
    },
    "7-2": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f36f21" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M39.30 44L16.98 44L16.98 14.65L38.74 14.65L38.74 19.62L22.91 19.62L22.91 26.12L37.64 26.12L37.64 31.07L22.91 31.07L22.91 39.06L39.30 39.06L39.30 44Z"/>
        </svg>`
    },
    "7-3": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fdb813" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M16.96 44L16.96 14.65L27.79 14.65L27.79 14.65Q31.46 14.65 33.38 15.21L33.38 15.21L33.38 15.21Q35.96 15.97 37.80 17.91L37.80 17.91L37.80 17.91Q39.65 19.86 40.61 22.67L40.61 22.67L40.61 22.67Q41.57 25.48 41.57 29.61L41.57 29.61L41.57 29.61Q41.57 33.23 40.67 35.85L40.67 35.85L40.67 35.85Q39.56 39.06 37.52 41.04L37.52 41.04L37.52 41.04Q35.98 42.54 33.36 43.38L33.36 43.38L33.36 43.38Q31.40 44 28.11 44L28.11 44L16.96 44ZM25.55 19.62L22.89 19.62L22.89 39.06L27.31 39.06L27.31 39.06Q29.80 39.06 30.90 38.77L30.90 38.77L30.90 38.77Q32.34 38.41 33.29 37.55L33.29 37.55L33.29 37.55Q34.24 36.69 34.84 34.72L34.84 34.72L34.84 34.72Q35.44 32.75 35.44 29.35L35.44 29.35L35.44 29.35Q35.44 25.94 34.84 24.12L34.84 24.12L34.84 24.12Q34.24 22.30 33.16 21.28L33.16 21.28L33.16 21.28Q32.08 20.26 30.42 19.90L30.42 19.90L30.42 19.90Q29.17 19.62 25.55 19.62L25.55 19.62Z"/>
        </svg>`
    },
    "7-4": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff100" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M35.76 33.21L35.76 33.21L41.51 35.03L41.51 35.03Q40.19 39.84 37.11 42.17L37.11 42.17L37.11 42.17Q34.04 44.50 29.31 44.50L29.31 44.50L29.31 44.50Q23.47 44.50 19.71 40.51L19.71 40.51L19.71 40.51Q15.94 36.51 15.94 29.59L15.94 29.59L15.94 29.59Q15.94 22.26 19.73 18.20L19.73 18.20L19.73 18.20Q23.51 14.15 29.68 14.15L29.68 14.15L29.68 14.15Q35.06 14.15 38.42 17.33L38.42 17.33L38.42 17.33Q40.43 19.22 41.43 22.74L41.43 22.74L35.56 24.14L35.56 24.14Q35.04 21.86 33.39 20.54L33.39 20.54L33.39 20.54Q31.74 19.22 29.38 19.22L29.38 19.22L29.38 19.22Q26.11 19.22 24.08 21.56L24.08 21.56L24.08 21.56Q22.05 23.90 22.05 29.15L22.05 29.15L22.05 29.15Q22.05 34.71 24.05 37.07L24.05 37.07L24.05 37.07Q26.05 39.44 29.25 39.44L29.25 39.44L29.25 39.44Q31.62 39.44 33.32 37.93L33.32 37.93L33.32 37.93Q35.02 36.43 35.76 33.21Z"/>
        </svg>`
    },
    "7-5": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#bed62f" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M17.00 44L17.00 14.65L28.73 14.65L28.73 14.65Q32.22 14.65 33.93 14.94L33.93 14.94L33.93 14.94Q35.64 15.23 36.99 16.15L36.99 16.15L36.99 16.15Q38.34 17.07 39.24 18.61L39.24 18.61L39.24 18.61Q40.15 20.14 40.15 22.04L40.15 22.04L40.15 22.04Q40.15 24.10 39.03 25.82L39.03 25.82L39.03 25.82Q37.92 27.54 36.02 28.40L36.02 28.40L36.02 28.40Q38.70 29.19 40.15 31.07L40.15 31.07L40.15 31.07Q41.59 32.95 41.59 35.49L41.59 35.49L41.59 35.49Q41.59 37.49 40.66 39.39L40.66 39.39L40.66 39.39Q39.73 41.28 38.11 42.41L38.11 42.41L38.11 42.41Q36.50 43.54 34.14 43.80L34.14 43.80L34.14 43.80Q32.66 43.96 26.99 44L26.99 44L17.00 44ZM26.33 19.54L22.93 19.54L22.93 26.32L26.81 26.32L26.81 26.32Q30.28 26.32 31.12 26.22L31.12 26.22L31.12 26.22Q32.64 26.04 33.51 25.17L33.51 25.17L33.51 25.17Q34.38 24.30 34.38 22.88L34.38 22.88L34.38 22.88Q34.38 21.52 33.63 20.67L33.63 20.67L33.63 20.67Q32.88 19.82 31.40 19.64L31.40 19.64L31.40 19.64Q30.52 19.54 26.33 19.54L26.33 19.54ZM27.71 31.21L22.93 31.21L22.93 39.06L28.41 39.06L28.41 39.06Q31.62 39.06 32.48 38.88L32.48 38.88L32.48 38.88Q33.80 38.63 34.63 37.70L34.63 37.70L34.63 37.70Q35.46 36.77 35.46 35.21L35.46 35.21L35.46 35.21Q35.46 33.89 34.82 32.97L34.82 32.97L34.82 32.97Q34.18 32.05 32.97 31.63L32.97 31.63L32.97 31.63Q31.76 31.21 27.71 31.21L27.71 31.21Z"/>
        </svg>`
    },
    "7-6": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#50b748" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
        </svg>`
    },
    "7-7": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
        </svg>`
    },
    "7-8": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
        </svg>`
    },
    "7-9": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
            <path fill="white" d="M85.62 29.91L81.87 29.91L81.87 24.33L76.25 24.33L76.25 20.48L81.87 20.48L81.87 14.89L85.62 14.89L85.62 20.48L91.26 20.48L91.26 24.33L85.62 24.33L85.62 29.91Z"/>
        </svg>`
    },
    "8-0": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M37.42 33.21L30.64 33.21L30.64 28.26L43.41 28.26L43.41 39.96L43.41 39.96Q41.55 41.76 38.01 43.13L38.01 43.13L38.01 43.13Q34.48 44.50 30.86 44.50L30.86 44.50L30.86 44.50Q26.25 44.50 22.83 42.57L22.83 42.57L22.83 42.57Q19.41 40.64 17.68 37.04L17.68 37.04L17.68 37.04Q15.96 33.45 15.96 29.23L15.96 29.23L15.96 29.23Q15.96 24.64 17.88 21.08L17.88 21.08L17.88 21.08Q19.81 17.51 23.51 15.61L23.51 15.61L23.51 15.61Q26.33 14.15 30.54 14.15L30.54 14.15L30.54 14.15Q36.00 14.15 39.07 16.44L39.07 16.44L39.07 16.44Q42.15 18.74 43.03 22.78L43.03 22.78L37.14 23.88L37.14 23.88Q36.52 21.72 34.81 20.47L34.81 20.47L34.81 20.47Q33.10 19.22 30.54 19.22L30.54 19.22L30.54 19.22Q26.65 19.22 24.36 21.68L24.36 21.68L24.36 21.68Q22.07 24.14 22.07 28.99L22.07 28.99L22.07 28.99Q22.07 34.21 24.39 36.82L24.39 36.82L24.39 36.82Q26.71 39.44 30.48 39.44L30.48 39.44L30.48 39.44Q32.34 39.44 34.21 38.70L34.21 38.70L34.21 38.70Q36.08 37.97 37.42 36.93L37.42 36.93L37.42 33.21Z"/>
        </svg>`
    },
    "8-1": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M22.95 44L17.02 44L17.02 14.65L37.14 14.65L37.14 19.62L22.95 19.62L22.95 26.56L35.20 26.56L35.20 31.53L22.95 31.53L22.95 44Z"/>
        </svg>`
    },
    "8-2": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M39.30 44L16.98 44L16.98 14.65L38.74 14.65L38.74 19.62L22.91 19.62L22.91 26.12L37.64 26.12L37.64 31.07L22.91 31.07L22.91 39.06L39.30 39.06L39.30 44Z"/>
        </svg>`
    },
    "8-3": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f36f21" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M16.96 44L16.96 14.65L27.79 14.65L27.79 14.65Q31.46 14.65 33.38 15.21L33.38 15.21L33.38 15.21Q35.96 15.97 37.80 17.91L37.80 17.91L37.80 17.91Q39.65 19.86 40.61 22.67L40.61 22.67L40.61 22.67Q41.57 25.48 41.57 29.61L41.57 29.61L41.57 29.61Q41.57 33.23 40.67 35.85L40.67 35.85L40.67 35.85Q39.56 39.06 37.52 41.04L37.52 41.04L37.52 41.04Q35.98 42.54 33.36 43.38L33.36 43.38L33.36 43.38Q31.40 44 28.11 44L28.11 44L16.96 44ZM25.55 19.62L22.89 19.62L22.89 39.06L27.31 39.06L27.31 39.06Q29.80 39.06 30.90 38.77L30.90 38.77L30.90 38.77Q32.34 38.41 33.29 37.55L33.29 37.55L33.29 37.55Q34.24 36.69 34.84 34.72L34.84 34.72L34.84 34.72Q35.44 32.75 35.44 29.35L35.44 29.35L35.44 29.35Q35.44 25.94 34.84 24.12L34.84 24.12L34.84 24.12Q34.24 22.30 33.16 21.28L33.16 21.28L33.16 21.28Q32.08 20.26 30.42 19.90L30.42 19.90L30.42 19.90Q29.17 19.62 25.55 19.62L25.55 19.62Z"/>
        </svg>`
    },
    "8-4": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fdb813" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M35.76 33.21L35.76 33.21L41.51 35.03L41.51 35.03Q40.19 39.84 37.11 42.17L37.11 42.17L37.11 42.17Q34.04 44.50 29.31 44.50L29.31 44.50L29.31 44.50Q23.47 44.50 19.71 40.51L19.71 40.51L19.71 40.51Q15.94 36.51 15.94 29.59L15.94 29.59L15.94 29.59Q15.94 22.26 19.73 18.20L19.73 18.20L19.73 18.20Q23.51 14.15 29.68 14.15L29.68 14.15L29.68 14.15Q35.06 14.15 38.42 17.33L38.42 17.33L38.42 17.33Q40.43 19.22 41.43 22.74L41.43 22.74L35.56 24.14L35.56 24.14Q35.04 21.86 33.39 20.54L33.39 20.54L33.39 20.54Q31.74 19.22 29.38 19.22L29.38 19.22L29.38 19.22Q26.11 19.22 24.08 21.56L24.08 21.56L24.08 21.56Q22.05 23.90 22.05 29.15L22.05 29.15L22.05 29.15Q22.05 34.71 24.05 37.07L24.05 37.07L24.05 37.07Q26.05 39.44 29.25 39.44L29.25 39.44L29.25 39.44Q31.62 39.44 33.32 37.93L33.32 37.93L33.32 37.93Q35.02 36.43 35.76 33.21Z"/>
        </svg>`
    },
    "8-5": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff100" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M17.00 44L17.00 14.65L28.73 14.65L28.73 14.65Q32.22 14.65 33.93 14.94L33.93 14.94L33.93 14.94Q35.64 15.23 36.99 16.15L36.99 16.15L36.99 16.15Q38.34 17.07 39.24 18.61L39.24 18.61L39.24 18.61Q40.15 20.14 40.15 22.04L40.15 22.04L40.15 22.04Q40.15 24.10 39.03 25.82L39.03 25.82L39.03 25.82Q37.92 27.54 36.02 28.40L36.02 28.40L36.02 28.40Q38.70 29.19 40.15 31.07L40.15 31.07L40.15 31.07Q41.59 32.95 41.59 35.49L41.59 35.49L41.59 35.49Q41.59 37.49 40.66 39.39L40.66 39.39L40.66 39.39Q39.73 41.28 38.11 42.41L38.11 42.41L38.11 42.41Q36.50 43.54 34.14 43.80L34.14 43.80L34.14 43.80Q32.66 43.96 26.99 44L26.99 44L17.00 44ZM26.33 19.54L22.93 19.54L22.93 26.32L26.81 26.32L26.81 26.32Q30.28 26.32 31.12 26.22L31.12 26.22L31.12 26.22Q32.64 26.04 33.51 25.17L33.51 25.17L33.51 25.17Q34.38 24.30 34.38 22.88L34.38 22.88L34.38 22.88Q34.38 21.52 33.63 20.67L33.63 20.67L33.63 20.67Q32.88 19.82 31.40 19.64L31.40 19.64L31.40 19.64Q30.52 19.54 26.33 19.54L26.33 19.54ZM27.71 31.21L22.93 31.21L22.93 39.06L28.41 39.06L28.41 39.06Q31.62 39.06 32.48 38.88L32.48 38.88L32.48 38.88Q33.80 38.63 34.63 37.70L34.63 37.70L34.63 37.70Q35.46 36.77 35.46 35.21L35.46 35.21L35.46 35.21Q35.46 33.89 34.82 32.97L34.82 32.97L34.82 32.97Q34.18 32.05 32.97 31.63L32.97 31.63L32.97 31.63Q31.76 31.21 27.71 31.21L27.71 31.21Z"/>
        </svg>`
    },
    "8-6": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#bed62f" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
        </svg>`
    },
    "8-7": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#50b748" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
        </svg>`
    },
    "8-8": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
        </svg>`
    },
    "8-9": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
            <path fill="white" d="M85.62 29.91L81.87 29.91L81.87 24.33L76.25 24.33L76.25 20.48L81.87 20.48L81.87 14.89L85.62 14.89L85.62 20.48L91.26 20.48L91.26 24.33L85.62 24.33L85.62 29.91Z"/>
        </svg>`
    },
    "9-0": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M37.42 33.21L30.64 33.21L30.64 28.26L43.41 28.26L43.41 39.96L43.41 39.96Q41.55 41.76 38.01 43.13L38.01 43.13L38.01 43.13Q34.48 44.50 30.86 44.50L30.86 44.50L30.86 44.50Q26.25 44.50 22.83 42.57L22.83 42.57L22.83 42.57Q19.41 40.64 17.68 37.04L17.68 37.04L17.68 37.04Q15.96 33.45 15.96 29.23L15.96 29.23L15.96 29.23Q15.96 24.64 17.88 21.08L17.88 21.08L17.88 21.08Q19.81 17.51 23.51 15.61L23.51 15.61L23.51 15.61Q26.33 14.15 30.54 14.15L30.54 14.15L30.54 14.15Q36.00 14.15 39.07 16.44L39.07 16.44L39.07 16.44Q42.15 18.74 43.03 22.78L43.03 22.78L37.14 23.88L37.14 23.88Q36.52 21.72 34.81 20.47L34.81 20.47L34.81 20.47Q33.10 19.22 30.54 19.22L30.54 19.22L30.54 19.22Q26.65 19.22 24.36 21.68L24.36 21.68L24.36 21.68Q22.07 24.14 22.07 28.99L22.07 28.99L22.07 28.99Q22.07 34.21 24.39 36.82L24.39 36.82L24.39 36.82Q26.71 39.44 30.48 39.44L30.48 39.44L30.48 39.44Q32.34 39.44 34.21 38.70L34.21 38.70L34.21 38.70Q36.08 37.97 37.42 36.93L37.42 36.93L37.42 33.21Z"/>
        </svg>`
    },
    "9-1": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M22.95 44L17.02 44L17.02 14.65L37.14 14.65L37.14 19.62L22.95 19.62L22.95 26.56L35.20 26.56L35.20 31.53L22.95 31.53L22.95 44Z"/>
        </svg>`
    },
    "9-2": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M39.30 44L16.98 44L16.98 14.65L38.74 14.65L38.74 19.62L22.91 19.62L22.91 26.12L37.64 26.12L37.64 31.07L22.91 31.07L22.91 39.06L39.30 39.06L39.30 44Z"/>
        </svg>`
    },
    "9-3": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#ed1c24" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M16.96 44L16.96 14.65L27.79 14.65L27.79 14.65Q31.46 14.65 33.38 15.21L33.38 15.21L33.38 15.21Q35.96 15.97 37.80 17.91L37.80 17.91L37.80 17.91Q39.65 19.86 40.61 22.67L40.61 22.67L40.61 22.67Q41.57 25.48 41.57 29.61L41.57 29.61L41.57 29.61Q41.57 33.23 40.67 35.85L40.67 35.85L40.67 35.85Q39.56 39.06 37.52 41.04L37.52 41.04L37.52 41.04Q35.98 42.54 33.36 43.38L33.36 43.38L33.36 43.38Q31.40 44 28.11 44L28.11 44L16.96 44ZM25.55 19.62L22.89 19.62L22.89 39.06L27.31 39.06L27.31 39.06Q29.80 39.06 30.90 38.77L30.90 38.77L30.90 38.77Q32.34 38.41 33.29 37.55L33.29 37.55L33.29 37.55Q34.24 36.69 34.84 34.72L34.84 34.72L34.84 34.72Q35.44 32.75 35.44 29.35L35.44 29.35L35.44 29.35Q35.44 25.94 34.84 24.12L34.84 24.12L34.84 24.12Q34.24 22.30 33.16 21.28L33.16 21.28L33.16 21.28Q32.08 20.26 30.42 19.90L30.42 19.90L30.42 19.90Q29.17 19.62 25.55 19.62L25.55 19.62Z"/>
        </svg>`
    },
    "9-4": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#f36f21" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M35.76 33.21L35.76 33.21L41.51 35.03L41.51 35.03Q40.19 39.84 37.11 42.17L37.11 42.17L37.11 42.17Q34.04 44.50 29.31 44.50L29.31 44.50L29.31 44.50Q23.47 44.50 19.71 40.51L19.71 40.51L19.71 40.51Q15.94 36.51 15.94 29.59L15.94 29.59L15.94 29.59Q15.94 22.26 19.73 18.20L19.73 18.20L19.73 18.20Q23.51 14.15 29.68 14.15L29.68 14.15L29.68 14.15Q35.06 14.15 38.42 17.33L38.42 17.33L38.42 17.33Q40.43 19.22 41.43 22.74L41.43 22.74L35.56 24.14L35.56 24.14Q35.04 21.86 33.39 20.54L33.39 20.54L33.39 20.54Q31.74 19.22 29.38 19.22L29.38 19.22L29.38 19.22Q26.11 19.22 24.08 21.56L24.08 21.56L24.08 21.56Q22.05 23.90 22.05 29.15L22.05 29.15L22.05 29.15Q22.05 34.71 24.05 37.07L24.05 37.07L24.05 37.07Q26.05 39.44 29.25 39.44L29.25 39.44L29.25 39.44Q31.62 39.44 33.32 37.93L33.32 37.93L33.32 37.93Q35.02 36.43 35.76 33.21Z"/>
        </svg>`
    },
    "9-5": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fdb813" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M17.00 44L17.00 14.65L28.73 14.65L28.73 14.65Q32.22 14.65 33.93 14.94L33.93 14.94L33.93 14.94Q35.64 15.23 36.99 16.15L36.99 16.15L36.99 16.15Q38.34 17.07 39.24 18.61L39.24 18.61L39.24 18.61Q40.15 20.14 40.15 22.04L40.15 22.04L40.15 22.04Q40.15 24.10 39.03 25.82L39.03 25.82L39.03 25.82Q37.92 27.54 36.02 28.40L36.02 28.40L36.02 28.40Q38.70 29.19 40.15 31.07L40.15 31.07L40.15 31.07Q41.59 32.95 41.59 35.49L41.59 35.49L41.59 35.49Q41.59 37.49 40.66 39.39L40.66 39.39L40.66 39.39Q39.73 41.28 38.11 42.41L38.11 42.41L38.11 42.41Q36.50 43.54 34.14 43.80L34.14 43.80L34.14 43.80Q32.66 43.96 26.99 44L26.99 44L17.00 44ZM26.33 19.54L22.93 19.54L22.93 26.32L26.81 26.32L26.81 26.32Q30.28 26.32 31.12 26.22L31.12 26.22L31.12 26.22Q32.64 26.04 33.51 25.17L33.51 25.17L33.51 25.17Q34.38 24.30 34.38 22.88L34.38 22.88L34.38 22.88Q34.38 21.52 33.63 20.67L33.63 20.67L33.63 20.67Q32.88 19.82 31.40 19.64L31.40 19.64L31.40 19.64Q30.52 19.54 26.33 19.54L26.33 19.54ZM27.71 31.21L22.93 31.21L22.93 39.06L28.41 39.06L28.41 39.06Q31.62 39.06 32.48 38.88L32.48 38.88L32.48 38.88Q33.80 38.63 34.63 37.70L34.63 37.70L34.63 37.70Q35.46 36.77 35.46 35.21L35.46 35.21L35.46 35.21Q35.46 33.89 34.82 32.97L34.82 32.97L34.82 32.97Q34.18 32.05 32.97 31.63L32.97 31.63L32.97 31.63Q31.76 31.21 27.71 31.21L27.71 31.21Z"/>
        </svg>`
    },
    "9-6": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#fff100" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
        </svg>`
    },
    "9-7": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#bed62f" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
        </svg>`
    },
    "9-8": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#50b748" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
        </svg>`
    },
    "9-9": {
        icon: html`<svg width="64px" height="32px" viewBox="0 0 120 60" xmlns="http://www.w3.org/2000/svg">
            <path fill="#00a550" d="m0,0 L90,0 L120,30 L90,60 L0,60z"></path>
            <path fill="white" d="M31.70 14.65L43.45 44L37.00 44L34.44 37.33L22.71 37.33L20.29 44L14 44L25.43 14.65L31.70 14.65ZM24.53 32.39L32.54 32.39L28.49 21.50L24.53 32.39Z"/>
            <path fill="white" d="M53.62 29.91L49.87 29.91L49.87 24.33L44.25 24.33L44.25 20.48L49.87 20.48L49.87 14.89L53.62 14.89L53.62 20.48L59.26 20.48L59.26 24.33L53.62 24.33L53.62 29.91Z"/>
            <path fill="white" d="M69.62 29.91L65.87 29.91L65.87 24.33L60.25 24.33L60.25 20.48L65.87 20.48L65.87 14.89L69.62 14.89L69.62 20.48L75.26 20.48L75.26 24.33L69.62 24.33L69.62 29.91Z"/>
            <path fill="white" d="M85.62 29.91L81.87 29.91L81.87 24.33L76.25 24.33L76.25 20.48L81.87 20.48L81.87 14.89L85.62 14.89L85.62 20.48L91.26 20.48L91.26 24.33L85.62 24.33L85.62 29.91Z"/>
        </svg>`
    },
}