import { LitElement, html, css } from "lit";
import strings from "../utils/strings";
import { live } from "lit/directives/live.js";
import { checkboxStyles } from "./searchFilter/checkbox.styles.js";

class StockCheckbox extends LitElement {
	static properties = {
		useStockFilter: { type: Boolean },
		updateStockFilter: {
			type: Function,
		},
	};

	render() {
		return html`<label class="stock-select">
			<input
				class="checkbox"
				type="checkbox"
				.checked=${live(this.useStockFilter)}
				@change=${(e) => this.updateStockFilter(!this.useStockFilter)}
			/>
			<span class="clamp-lines">${strings["showOnlyStock"]}</span>
		</label>`;
	}

	static styles = [
		checkboxStyles,
		css`
			.stock-select {
				display: flex;
				flex-direction: row;
				align-items: center;
                font-size: 14px;
			}

			.stock-select span {
				padding-left: 4px;
			}
		`,
	];
}
customElements.define("stock-checkbox", StockCheckbox);
