import {LitElement, html, nothing} from "lit";
import { saleSetContainerStyles } from "./sale-set-container.styles";
import strings, { updateStrings } from "../../utils/strings.js";

class SaleSetContainer extends LitElement {
    static properties = {
        data: { type: String },
        saleSets: { type: Array },
        strings: { type: Array },
    };

    constructor() {
        super();
        this.saleSets = [];
    }

    connectedCallback() {
        super.connectedCallback();
        updateStrings(this.strings);
        this.loadInitialData();
    }

    loadInitialData() {
        if (this.data) {
            try {
                const parsedData = JSON.parse(this.data);
                if (Array.isArray(parsedData.children)) {
                    this.saleSets = parsedData.children;
                } else {
                    throw new Error('invalid parsedData.children')
                }
            } catch (error) {
                console.error('Error parsing initial data:', error);
            }
        }
    }

    shouldDisplayQuantity(saleSet) {
        return saleSet.some(child => child.quantity > 1);
    }

    render() {
        return html`
            <p class="title">
                ${strings["product.box.saleset.title"]}
            </p>
            <div class="products-flex">
                ${this.saleSets.map(saleSet => html`
                    <div class="product-wrapper">
                        <a class="product" href="${saleSet.url}" target="_blank" rel="noopener noreferrer">
                            <div class="product-image-container">
                                <img
                                    class="product-image"
                                    src="/img/p/200/${saleSet.materialNumber}.jpg"
                                    alt="${saleSet.title}"
                                >
                            </div>
                            <div class="product-text-wrapper">
                                <div class="product-title">
                                    <span class="product-title-text">
                                        ${saleSet.title}
                                    </span>
                                </div>
                                ${this.shouldDisplayQuantity(this.saleSets) ? html`
                                    <div class="product-quantity">
                                        <span class="product-quantity-text">
                                            ${strings["product.box.saleset.quantity"]}: ${saleSet.quantity}
                                        </span>
                                    </div>
                                ` : nothing}
                            </div>
                        </a>
                    </div>
                `)}
            </div>
        `;
    }

    static styles = saleSetContainerStyles;
}

customElements.define('sale-set-container', SaleSetContainer);