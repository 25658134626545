import { LitElement, html, css } from "lit";

import { closeX } from "../../utils/svgProvider.js";
import strings from "../../utils/strings.js";

class SubscribeToStockModalHeader extends LitElement {
  static properties = {
    handleClose: {
      type: Function,
    },
  };

  render() {
    return html`<h3>${strings.remindMe}</h3>
      <a href="#" data-testid="config.service.modal.header.close" @click=${this.handleClose}
        >${closeX}</a
      > `;
  }
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      z-index: 10;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid #cccccc;
      background: #fff;
      padding: 0 24px;
      min-height: 50px;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    h3 {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
      color: #737373;
    }

    a {
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: #222;
      text-decoration: underline;
      position: absolute;
      right: 0;
      padding-right: 12px;
    }
  `;
}

customElements.define("subscribe-to-stock-modal-header", SubscribeToStockModalHeader);
