import { css, html, LitElement } from "lit";
import { ifDefined } from "lit/directives/if-defined.js";

export class Textarea extends LitElement {
  static properties = {
    placeholder: { type: String },
    name: { type: String },
    value: { type: String },
    disabled: { type: Boolean },
    onChange: { type: Function },
    onKeyDown: { type: Function },
    onKeyUp: { type: Function },
    onKeyPress: { type: Function },
    onFocus: { type: Function },
    onFocusOut: { type: Function },
  };

  constructor() {
    super();
    this.value = "";
    this.disabled = false;
    this.onChange = () => {};
    this.onKeyDown = () => {};
    this.onKeyUp = () => {};
    this.onKeyPress = () => {};
    this.onFocus = () => {};
    this.onFocusOut = () => {};
  }

  render() {
    return html`
      <textarea
        name=${ifDefined(this.name)}
        placeholder=${ifDefined(this.placeholder)}
        ?disabled=${this.disabled}
        @change=${(event) => this.onChange(event)}
        @keydown=${(event) => this.onKeyDown(event)}
        @keyup=${(event) => this.onKeyUp(event)}
        @keypress=${(event) => this.onKeyPress(event)}
        @focus=${(event) => this.onFocus(event)}
        @focusout=${(event) => this.onFocusOut(event)}
      >${this.value}</textarea>
    `;
  }

  static styles = css`
    textarea {
      box-sizing: border-box;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      color: #222222;
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #ccc;
      margin: 0;
      padding: 12px 16px;
      outline: none;
      resize: none;
      transition: border-color 0.2s ease;
      width: var(--komplett-textarea-width, auto);
      height: var(--komplett-textarea-height, auto);
    }

    textarea::placeholder {
      color: #737373;
    }

    textarea:hover:not(:disabled),
    textarea:focus,
    textarea:focus-visible {
      border-color: #222222;
    }

    textarea:disabled {
      background-color: #ebebeb;
      cursor: not-allowed;
    }
  `;
}
