import { css } from "lit";

export const optionStyles = css`
  :host {
    margin-top: 16px;
    display: flex;
  }

  .service-option {
    display: flex;
    flex: 1;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 8px;
    padding: 16px;
    border-radius: 8px;
    background: #fff;
    border: 1px solid #cccccc;
    margin-top: 16px;
  }

  label {
    display: flex;
    flex: 1;
    align-items: center;
    line-height: 1;
    width: 100%;
    cursor: pointer;
  }

  label.is-disabled {
    cursor: default;
  }

  input[type="checkbox"] {
    appearance: none;
    margin: 0;
    width: 23px;
    height: 23px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='22' height='22' rx='2.5' fill='white' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
  }

  input[type="radio"]:disabled,
  input[type="checkbox"]:disabled {
    cursor: default;
    opacity: 0.5;
  }

  input[type="radio"] + .service-details,
  input[type="checkbox"] + .service-details {
    padding-left: 16px;
    flex: 1 1 60%;
  }

  input[type="checkbox"]:hover {
    appearance: none;
    width: 23px;
    height: 23px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect x='0.5' y='0.5' width='22' height='22' rx='2.5' fill='white' stroke='%23222222'/%3E%3C/svg%3E%0A");
  }

  input[type="checkbox"]:checked {
    appearance: none;
    width: 23px;
    height: 23px;
    background-image: url("data:image/svg+xml,%3Csvg width='23' height='23' viewBox='0 0 23 23' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Crect width='23' height='23' rx='2' fill='%232A41BB'/%3E%3Cpath d='M16.0262 5.52631L8.84199 12.7105L4.97357 8.84209L3.31567 10.5L8.84199 16.0263L17.6841 7.1842L16.0262 5.52631Z' fill='white'/%3E%3C/svg%3E%0A");
  }

  input[type="radio"] {
    appearance: none;
    margin: 0;
    width: 21px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5 11C20.5 16.5229 16.0228 21 10.5 21C4.97715 21 0.5 16.5229 0.5 11C0.5 5.4772 4.97715 1 10.5 1C16.0228 1 20.5 5.4772 20.5 11Z' stroke='%23CCCCCC'/%3E%3C/svg%3E%0A");
  }

  input[type="radio"]:hover {
    appearance: none;
    width: 21px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5 11C20.5 16.5229 16.0228 21 10.5 21C4.97715 21 0.5 16.5229 0.5 11C0.5 5.4772 4.97715 1 10.5 1C16.0228 1 20.5 5.4772 20.5 11Z' stroke='%23222222'/%3E%3C/svg%3E%0A");
  }

  input[type="radio"]:checked {
    appearance: none;
    width: 21px;
    height: 22px;
    background-image: url("data:image/svg+xml,%3Csvg width='21' height='22' viewBox='0 0 21 22' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M20.5 11C20.5 16.5229 16.0228 21 10.5 21C4.97715 21 0.5 16.5229 0.5 11C0.5 5.4772 4.97715 1 10.5 1C16.0228 1 20.5 5.4772 20.5 11Z' stroke='%23222222'/%3E%3Ccircle cx='10.5' cy='11' r='6.5' fill='%232A41BB'/%3E%3C/svg%3E%0A");
  }

  .service-option.is-selected {
    background: #ffffff;
    border: 1px solid #2a41bb;
    box-shadow: 0px 0px 0px 2px #2a41bb40;
  }

  .service-frame {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 75px;
    width: 100%;
    height: 75px;
    background: transparent;
    margin-right: 16px;
  }

  .service-frame img {
    object-fit: contain;
    width: 100%;
  }

  .service-details {
    display: flex;
    flex-flow: row nowrap;
    width: 100%;
  }

  .service-price {
    font-family: "Barlow Semi Condensed", sans-serif;
    display: flex;
    flex-flow: column nowrap;
    align-items: flex-end;
    justify-content: space-between;
    flex: 1;
    align-self: normal;
    padding: 0 0 0 16px;
  }

  .service-price p {
    font-size: 18px;
    font-weight: 600;
    color: #222;
    margin: 0;
  }

  .service-price a {
    display: flex;
    gap: 8px;
    text-decoration: none;
    color: #2a41bb;
    &:active,
    &:focus {
      color: #2a41bb;
    }
  }

  .service-details {
    cursor: pointer;
  }

  .service-details h3 {
    font-size: 16px;
    font-weight: 500;
    color: #222222;
    margin: 0;
    padding: 0;
  }

  .service-details p {
    font-size: 14px;
    font-weight: 400;
    color: #222222;
    margin: 0;
    padding: 0;
  }

  .service-details span {
    font-size: 14px;
    font-weight: 400;
    color: #737373;
    margin-top: 8px;
  }

  .service-description {
    font-size: 14px;
    color: #222;
    width: 100%;
  }

  .service-description p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  .service-description p.is-description {
    font-size: 14px;
    font-weight: 400;
    color: #222;
  }

  .service-description span {
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    text-align: left;
  }

  .service-description a {
    display: flex;
    gap: 8px;
    padding: 4px 0;
    font-size: 14px;
    text-decoration: underline;
    color: #2a41bb;
    &:active,
    &:focus {
      color: #2a41bb;
    }
  }

  .service-description .service-description-title {
    display: flex;
    width: 60%;
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }

  .service-description .service-description-change {
    display: flex;
    gap: 0;
    padding-left: 8px;
    font-weight: 400;
    text-decoration: none;
    color: #565656;
    &:active,
    &:focus {
      color: #565656;
    }
  }
`;
