import { css, html, LitElement, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";

export class Modal extends LitElement {
  static properties = {
    compactViewBreakpoint: { type: Number },
    isOpen: { type: Boolean },
    onOverlayClick: { type: Function },
    onMount: { type: Function },
    _status: { state: true },
    _compact: { state: true },
  };

  constructor() {
    super();
    this.compactViewBreakpoint = 992;
    this._status = "unmounted";
    this._compact = false;
    this.onMount = () => {};
    this.onOverlayClick = () => {};
    this._resize();
  }

  _resize = () => {
    this._compact = window.innerWidth >= this.compactViewBreakpoint;
  };

  connectedCallback() {
    super.connectedCallback();
    window.addEventListener("resize", this._resize);
  }

  disconnectedCallback() {
    super.disconnectedCallback();
    window.removeEventListener("resize", this._resize);
  }

  updated(changedProperties) {
    if (changedProperties.has("_status") || changedProperties.has("isOpen")) {
      switch (this._status) {
        case "unmounted": {
          if (this.isOpen) {
            this._status = "hidden";
          }
          break;
        }
        case "hidden": {
          if (this.isOpen) {
            this.onMount();
            setTimeout(() => {
              this._status = "visible";
            }, 0);
          } else {
            setTimeout(() => {
              this._status = "unmounted";
            }, 200);
          }
          break;
        }
        case "visible": {
          if (!this.isOpen) {
            this._status = "hidden";
          }
          break;
        }
      }
    }

    if (changedProperties.has("isOpen")) {
      const bodyStyle = document.getElementsByTagName("body")[0].style;
      this.isOpen
        ? bodyStyle.setProperty("overflow", "hidden")
        : bodyStyle.removeProperty("overflow");
    }
  }

  render() {
    return ["hidden", "visible"].includes(this._status)
      ? html`<div tabindex="-1" class="container">
          <div
            class=${classMap({
              overlay: true,
              show: this._status === "visible",
            })}
            @click=${(event) => this.onOverlayClick(event)}
          ></div>
          <div
            class=${classMap({
              content: true,
              compact: this._compact,
              show: this._status === "visible",
            })}
          >
            <slot>Provide content for modal</slot>
          </div>
        </div>`
      : nothing;
  }

  static styles = css`
    .container {
      display: flex;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1001;
    }

    .content {
      position: relative;
      background-color: #fff;
      height: 100%;
      width: 100%;
      opacity: 0;
      transform: translateY(25%);
      transition: opacity 0.2s ease, transform 0.2s ease;
    }

    .compact {
      width: var(--komplett-modal-desktop-width, 750px);
      height: var(--komplett-modal-desktop-height, 100%);
      max-height: 80vh;
      top: var(--komplett-modal-desktop-top, calc(20vh - 10%));
      left: calc((100vw - var(--komplett-modal-desktop-width, 750px)) / 2);
      border-radius: 8px;
    }

    .content.show {
      opacity: 1;
      transform: translateY(0%);
    }

    .overlay {
      position: absolute;
      background-color: #00000040;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: opacity 0.2s ease;
    }

    .overlay.show {
      opacity: 1;
    }
  `;
}
