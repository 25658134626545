import { html, css } from "lit";

export const variantsTemplate = (variantGroups, strings) =>
  html`
    <div class="variants-wrapper">
      ${variantGroups.length
        ? html`<div class="note">${strings.variant}</div>`
        : ""}
      ${variantGroups?.map((variantGroup) => {
        const displayAmount = variantGroup.type === "COLOR" ? 4 : 3;

        return html`<div class="variants">
          ${variantGroup?.products
            ?.slice(0, displayAmount)
            .map((variant, i) =>
              i < displayAmount - 1
                ? html`
                    <a
                      class="variant ${variantGroup.type.toLowerCase()}"
                      href=${variant.url}
                      style="--variant-border: ${variant.value}; --variant-background: ${variant.value};"
                      title=${variant.displayValue}
                      aria-label=${variant.displayValue}
                    >
                      ${variant.displayValue}
                    </a>
                  `
                : `+${variantGroup.products.length - (displayAmount - 1)}`
            )}
        </div>`;
      })}
    </div>
  `;

export const variantsStyles = css`
  .variants-wrapper {
    grid-area: variants;
    display: flex;
    justify-content: space-between;
    container: wrapper / inline-size;
  }

  :host([layout="list"]) .variants-wrapper {
    justify-content: flex-start;
    gap: 24px;
  }

  .variants {
    display: flex;
    gap: 5px;
    align-items: center;
    color: #5f5f5f;
    font-size: 14px;

    .variant {
      height: 20px;
      font-weight: 400;
      color: inherit;
      border: 1px solid;
      border-radius: 4px;
      border-color: color-mix(
        in srgb,
        var(--variant-border, #5f5f5f) 85%,
        #212549
      );
      background-color: var(--variant-background, #fff);
    }

    .variant.color {
      width: 20px;
      color: transparent;
      overflow: hidden;
    }

    .variant.text,
    .variant.image {
      border-color: #5f5f5f;
      background-color: #fff;
      padding: 0 4px;
      text-decoration: none;
      text-wrap: nowrap;
      display: flex;
      align-items: center;
      max-width: 56px;
      overflow: hidden;
    }

    .variant:hover {
      border-color: #222;
    }
  }

  .note {
    display: none;
    color: #5f5f5f;
  }

  @container wrapper (max-width: 237px) {
    .variants {
      display: none;
    }

    .note {
      display: block;
    }
  }
`;