import { LitElement, html, css, nothing } from "lit";
import { choose } from "lit/directives/choose.js";

import strings from "../../utils/strings.js";
import { MODAL_TYPES } from "../configService/config-service.utils.js";
import { remove } from "../../utils/svgProvider.js";

class ModalFooter extends LitElement {
  static properties = {
    modalType: { type: String },
    isMandatoryService: { type: Boolean },
    isServiceOnly: { type: Boolean },
    servicesState: { type: Object },
    selectedAccessoryId: { type: String },
    handleConfirm: {
      type: Function,
    },
    handleClose: {
      type: Function,
    },
    handleReset: {
      type: Function,
    },
  };

  render() {
    return html`
      ${choose(this.modalType, [
        [
          MODAL_TYPES.ACCESSORIES,
          () => html`
            ${this.selectedAccessoryId && !this.isMandatoryService
              ? html`<button
                  class="btn reset"
                  data-testid="config.service.modal.footer.button.remove.selected"
                  @click=${() => this.handleReset()}
                >
                  ${remove}
                  ${strings[
                    "product.box.config.service.additional.services.service.remove.selected"
                  ]}
                </button>`
              : nothing}
            <div class="button-wrapper">
              ${this.isServiceOnly
                ? html`<button
                    class="btn clear"
                    data-testid="config.service.modal.footer.button.close"
                    @click=${() => this.handleClose()}
                  >
                    ${strings[
                      "product.box.config.service.additional.services.service.close"
                    ]}
                  </button>`
                : html` <button
                      class="btn clear"
                      data-testid="config.service.modal.footer.button.close"
                      @click=${() => this.handleClose()}
                    >
                      ${strings[
                        "product.box.config.service.additional.services.service.close"
                      ]}
                    </button>
                    <button
                      class="btn primary"
                      data-testid="config.service.modal.footer.button.add.selected"
                      .disabled=${!this.selectedAccessoryId}
                      @click=${() => this.handleConfirm()}
                    >
                      ${strings[
                        "product.box.config.service.additional.services.service.add.selected"
                      ]}
                    </button>`}
            </div>
          `,
        ],
        [
          MODAL_TYPES.SERVICES,
          () => html`<div class="button-wrapper">
            ${this.servicesState.getSelectedList().length
              ? html`<button
                  class="btn primary"
                  data-testid="config.service.modal.footer.button.save"
                  @click=${() => this.handleClose()}
                >
                  ${strings[
                    "product.box.config.service.additional.services.service.add.selected"
                  ]}
                </button>`
              : html`<button
                  class="btn clear"
                  data-testid="config.service.modal.footer.button.close"
                  @click=${() => this.handleClose()}
                >
                  ${strings[
                    "product.box.config.service.additional.services.service.close"
                  ]}
                </button>`}
          </div>`,
        ],
      ])}
    `;
  }
  static styles = css`
    :host {
      position: absolute;
      bottom: 0;
      z-index: 10;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: normal;
      border-top: 1px solid #cccccc;
      background: #fff;
      padding: 16px 24px;
    }

    .button-wrapper {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: flex-end;
    }

    .btn {
      border: none;
      background: none;
      margin: 0;
      font-size: 14px;
      line-height: 18px;
      padding: 8px 24px;
      border-radius: 4px;
      cursor: pointer;
      transition: background 0.2s ease-in-out;

      + .btn {
        margin-left: 16px;
      }
    }

    .btn.reset {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border: none;
      background: #fff;
      color: #b41400;
      &:disabled {
        cursor: default;
      }
    }

    .btn.clear {
      border: 1px solid #222;
      background: #fff;
      color: #222;
      &:disabled {
        cursor: default;
        background: #ebebeb;
        border: 1px solid #ebebeb;
        color: #737373;
        &:focus,
        &:hover {
          background: #ebebeb;
        }
      }
      &:focus,
      &:hover {
        background: #ebebeb;
      }
    }

    .btn.primary {
      border: 1px solid #1f1a49;
      background: #1f1a49;
      color: #fff;
      &:disabled {
        cursor: default;
        background: #ebebeb;
        color: #737373;
        &:focus,
        &:hover {
          background: #ebebeb;
        }
      }
      &:focus,
      &:hover {
        background: #1f1a4970;
      }
    }
  `;
}

customElements.define("modal-footer", ModalFooter);
