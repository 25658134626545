import { css } from "lit";
export const sliderStyles = css`
	.range-facet {
		width: 100%;
		margin: 5px 0 24px 0;
		border-top: 1px solid #ccc;
	}

	.facet-heading {
		font-size: 16px;
		font-weight: 700;
		align-items: center;
		padding: 10px 0;
		padding-inline-start: 0;
		text-transform: capitalize;
	}

	.manual-input {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin: 5px 0 20px;
	}

	.manual-input .field {
		display: flex;
		width: 60px;
		align-items: center;
		outline: none;
		font-size: 14px;
		border-radius: 5px;
		text-align: center;
		border: 1px solid #999;
		padding: 11px 16px;
	}

	input[type="number"]::-webkit-outer-spin-button,
	input[type="number"]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.slider {
		height: 12px;
		position: relative;
		background: #ddd;
		border-radius: 5px;
		overflow: hidden;
	}

	.slider .progress {
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 5px;
		background: #13102c;
	}

	.range-input {
		position: relative;
	}

	.range-input input {
		position: absolute;
		width: 100%;
		height: 0px;
		top: -7px;
		left: -2px;
		background: none;
		pointer-events: none;
		-webkit-appearance: none;
		-moz-appearance: none;
	}

	input[type="range"]::-webkit-slider-thumb {
		height: 26px;
		width: 26px;
		border: 1px solid #13102c;
		border-radius: 32px;
		background: #ffff;
		pointer-events: auto;
		-webkit-appearance: none;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
	}

	input[type="range"]::-moz-range-thumb {
		height: 26px;
		width: 26px;
		border: 1px solid #13102c;
		border-radius: 32px;
		background: #ffff;
		pointer-events: auto;
		-moz-appearance: none;
		box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
	}
`;
