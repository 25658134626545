import { html, css } from "lit";

export const productNumberTemplate = (materialNumber, manufacturerPartNumber, strings) =>
    html`
        <div class="productnumbers">
            <div class="product-data">
                ${strings.materialNumber}: <span style="cursor: text;">${materialNumber}</span>
                ${manufacturerPartNumber 
                    ? html`/ ${strings.manufacturerPartNumber}: <span style="cursor: text;">${manufacturerPartNumber}</span>`
                    : ""
                }
            </div>
        </div>
    `;


export const productNumberStyles = css`
  .productnumbers {
    grid-area: productnumbers;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 5px;
    color: #737373;
  }

  .product-box .product-data {
    pointer-events: initial;
  }
`;