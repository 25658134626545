import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";

export class Link extends LitElement {
  static properties = {
    external: { type: Boolean },
    href: { type: String },
  };

  constructor() {
    super();
  }

  render() {
    return html`<a
      class="${classMap({ external: this.external })}"
      href=${this.href}
    >
      <slot></slot>
    </a>`;
  }

  static styles = css`
    a {
      display: inline-block;
      font-family: "Roboto", sans-serif;
      font-size: inherit;
      line-height: inherit;
      text-decoration: none;
      color: #000;
      font-weight: 500;
      margin: 0;
      padding: 0;
      position: relative;
      border-radius: 4px;
      outline: 2px solid transparent;
      letter-spacing: 0.2px;
      transition: outline 0.2s ease;
    }

    a::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-bottom: 1px solid #000;
      transition: border-bottom-color 0.2s ease;
    }

    a.external {
      color: #2a41bb;
    }

    a.external::after {
      border-bottom-color: #2a41bb;
    }

    a:hover::after,
    a:focus::after {
      border-bottom-color: transparent;
    }

    a:focus {
      outline-color: #222222;
    }
  `;
}
