import { LitElement, html, css, nothing } from "lit";
import "./config-service-option.js";
import "../modal/modal-container.js";

import ConfigServicesState from "./configService.state.js";
import { MODAL_TYPES } from "./config-service.utils.js";
import strings, { updateStrings } from "../../utils/strings.js";
import { EVENTS, triggerCustomEvent } from "../../utils/customEvents.js";

class ConfigServiceContainer extends LitElement {
  static properties = {
    servicesState: { state: true },
    modalType: { state: true },
    isModalOpen: { state: true },
    scrollPosition: { state: true },
    services: { type: Array },
    strings: { type: Array },
  };

  constructor() {
    super();
    this.services = [];
    this.selectedServicesCount = 0;
    this.previouslySelectedServicesCount = 0;
    this.isModalOpen = false;
    this.modalType = "";

    this.servicesState = null;
    this.initialCardsLength = 2;
  }

  connectedCallback() {
    super.connectedCallback();
    updateStrings(this.strings);

    if (this.services) {
      this.servicesState = new ConfigServicesState(this.services);
      this.selectedServicesCount = this.servicesState.getCount();
      this.previouslySelectedServicesCount =
        this.servicesState.getPreviouslySelectedCount();

      document.addEventListener("buyButtonMount", () => {
        triggerCustomEvent(
          EVENTS.SERVICE_UPDATED,
          this.servicesState.getSelectedList()
        );
      });
    }
  }

  handleScrollLock() {
    if (this.isModalOpen) {
      window.scroll(0, 0);
      document.documentElement.classList.add("scroll-lock-xs");
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.classList.remove("scroll-lock-xs");
      document.body.style.overflow = "visible";
    }
  }

  openModal({ type, serviceGroupId }) {
    this.scrollPosition = window.scrollY;
    this.isModalOpen = true;
    this.modalType = type;

    this.handleScrollLock();

    if (serviceGroupId) {
      this.servicesState.set({
        serviceGroupId,
      });
    }

    this.requestUpdate();
  }

  resetModal() {
    this.isModalOpen = false;
    this.modalType = "";

    this.handleScrollLock();

    window.scroll(0, this.scrollPosition);

    this.requestUpdate();
  }

  handleAccessorySelection({
    selectedServiceGroupId,
    selectedAccessoryId,
    selectedAccessoryPrice,
  }) {
    if (!selectedAccessoryId) {
      this.resetAccessorySelection(selectedServiceGroupId);
      this.requestUpdate();
    } else {
      this.servicesState.set({
        serviceGroupId: selectedServiceGroupId,
        accessoryId: selectedAccessoryId,
        accessoryPrice: selectedAccessoryPrice,
      });
    }

    this.handleScrollLock();

    this.selectedServicesCount = this.servicesState.getCount();
    this.previouslySelectedServicesCount =
      this.servicesState.getPreviouslySelectedCount();

    triggerCustomEvent(
      EVENTS.SERVICE_UPDATED,
      this.servicesState.getSelectedList()
    );
  }

  resetAccessorySelection(serviceGroupId) {
    this.servicesState.reset(serviceGroupId);

    this.selectedServicesCount = this.servicesState.getCount();

    this.handleScrollLock();

    triggerCustomEvent(
      EVENTS.SERVICE_UPDATED,
      this.servicesState.getSelectedList()
    );
  }

  render() {
    if (!this.services?.length) {
      return null;
    }

    const shouldShowMoreServicesLink =
      this.services.length > this.servicesState.getVisibleCount();

    const visibleServicesCount =
      this.services.length - this.servicesState.getVisibleCount();

    return html`<div class="config-service-wrapper">
      <div class="selected-config-services-info">
        <span>
          ${strings[
            "product.box.config.service.additional.services.service.title"
          ]}
        </span>
      </div>
      ${this.services.map((service, index) => {
        if (
          this.servicesState?._state[service.serviceGroupId]
            ?.selectedAccessoryId ||
          this.servicesState?._state[service.serviceGroupId].previouslySelected
        ) {
          return html`<config-service-option
            .service=${service}
            .selectDefaultAccessory=${({ accessoryId, accessoryPrice }) => {
              this.handleAccessorySelection({
                selectedServiceGroupId: service.serviceGroupId,
                selectedAccessoryId: accessoryId,
                selectedAccessoryPrice: accessoryPrice,
              });

              this.requestUpdate();
            }}
            .defaultAccessoryId=${this.servicesState?._state[
              service.serviceGroupId
            ].defaultAccessoryId}
            .selectedAccessoryId=${this.servicesState?._state[
              service.serviceGroupId
            ]?.selectedAccessoryId}
            .openAccessoriesModal=${(serviceGroupId) =>
              this.openModal({
                type: MODAL_TYPES.ACCESSORIES,
                serviceGroupId,
              })}
          ></config-service-option>`;
        } else if (
          this.services.length >= this.initialCardsLength &&
          index <= this.initialCardsLength - 1
        ) {
          return html`<config-service-option
            .service=${service}
            .selectDefaultAccessory=${({ accessoryId, accessoryPrice }) => {
              this.handleAccessorySelection({
                selectedServiceGroupId: service.serviceGroupId,
                selectedAccessoryId: accessoryId,
                selectedAccessoryPrice: accessoryPrice,
              });
              this.requestUpdate();
            }}
            .defaultAccessoryId=${this.servicesState?._state[
              service.serviceGroupId
            ].defaultAccessoryId}
            .selectedAccessoryId=${this.servicesState._state[
              service.serviceGroupId
            ]?.selectedAccessoryId}
            .openAccessoriesModal=${(serviceGroupId) =>
              this.openModal({
                type: MODAL_TYPES.ACCESSORIES,
                serviceGroupId,
              })}
          ></config-service-option>`;
        } else {
          if (
            !this.selectedServicesCount &&
            this.services.length === 1 &&
            index === 0
          ) {
            return html`<config-service-option
              .service=${this.services[0]}
              .selectDefaultAccessory=${({ accessoryId, accessoryPrice }) => {
                this.handleAccessorySelection({
                  selectedServiceGroupId: this.services[0].serviceGroupId,
                  selectedAccessoryId: accessoryId,
                  selectedAccessoryPrice: accessoryPrice,
                });
                this.requestUpdate();
              }}
              .defaultAccessoryId=${this.servicesState?._state[
                this.services[0].serviceGroupId
              ].defaultAccessoryId}
              .selectedAccessoryId=${this.servicesState._state[
                this.services[0].serviceGroupId
              ]?.selectedAccessoryId}
              .openAccessoriesModal=${(serviceGroupId) =>
                this.openModal({
                  type: MODAL_TYPES.ACCESSORIES,
                  serviceGroupId,
                })}
            ></config-service-option>`;
          }
        }
      })}
      ${shouldShowMoreServicesLink
        ? html`<div class="more-options">
            <a
              @click=${(e) => {
                e.preventDefault();
                this.openModal({ type: MODAL_TYPES.SERVICES });
              }}
              class="show-more-link"
              data-testid="config.service.show.all.link"
              href="#"
              >${strings[
                "product.box.config.service.additional.services.service.show.all"
              ]}
              (${visibleServicesCount}
              ${strings[
                "product.box.config.service.additional.services.service.several"
              ]})</a
            >
          </div>`
        : nothing}

      <modal-container
        .services=${this.services}
        .servicesState=${this.servicesState}
        .isModalOpen=${this.isModalOpen}
        .modalType=${this.modalType}
        .resetAccessorySelection=${(serviceGroupId) =>
          this.resetAccessorySelection(serviceGroupId)}
        .onConfirmModal=${({
          selectedServiceGroupId,
          selectedAccessoryId,
          selectedAccessoryPrice,
        }) =>
          this.handleAccessorySelection({
            selectedServiceGroupId,
            selectedAccessoryId,
            selectedAccessoryPrice,
          })}
        .onCloseModal=${() => this.resetModal()}
      >
      </modal-container>
    </div>`;
  }

  static styles = css`
    :host {
      display: block;
    }

    .config-service-wrapper {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
    }

    .more-options {
      display: flex;
      flex: 1;
      flex-flow: column nowrap;
      background: #fff;
      border-radius: 4px;
      border: 1px solid #222;
      margin: 0;
      padding: 8px 24px;
      line-height: 24px;
      font-size: 14px;
      text-align: center;
      margin-top: 16px;
    }

    .more-options p {
      margin: 0;
    }

    .more-options a {
      color: #222;
      text-decoration: none;
    }

    .selected-config-services-info {
      display: flex;
      flex: 1;
      align-items: center;
      justify-content: space-between;
      min-height: 18px;
    }

    .selected-config-services-info a {
      font-size: 14px;
      color: #2a41bb;
      font-weight: 400;
      text-decoration: none;
      &:focus,
      &:hover {
        color: #2a41bb;
      }
    }

    .selected-config-services-info span {
      font-size: 16px;
      font-weight: 500;
      color: #222;
    }

    .selected-config-services-info i {
      font-size: 14px;
      font-weight: 400;
      color: #565656;
    }
  `;
}
customElements.define("config-service-container", ConfigServiceContainer);
