import { css } from "lit";

export const checkboxStyles = css`
  .checkbox {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 21px;
    height: 21px;
    border: 0.1em solid #ccc;
    border-radius: 0.15em;
    display: grid;
    place-content: center;
  }

  .checkbox:hover {
    border: 0.1em solid #333;
  }

  .checkbox::before {
    content: "";
    width: 13px;
    height: 13px;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #222;
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  }

  .checkbox:checked::before {
    transform: scale(1);
  }
`;
