class ConfigServicesState {
  constructor(services) {
    if (ConfigServicesState.instance instanceof ConfigServicesState) {
      return ConfigServicesState.instance;
    }

    this._state = this.createServiceMap(services);

    Object.freeze(this._state);
    Object.freeze(this);
  }

  createServiceMap(services) {
    const updated = services?.reduce((acc, curr, index) => {
      if (!acc[curr.serviceGroupId]) {
        const { selectedAccessoryId, selectedAccessoryPrice, isVisible } =
          this.selectMandatory(curr);

        const isServiceOnly = !curr?.serviceTypeId && curr.children.length > 0;

        acc[curr.serviceGroupId] = {
          isSelected: false,
          previouslySelected: false,
          isVisible:
            isVisible || (services.length >= 1 && (index === 0 || index === 1)) ? true : false,
          isServiceOnly,
          name: curr.name,
          serviceTypeId:
            curr?.serviceTypeId || (isServiceOnly ? curr.children?.[0]?.childId : null),
          defaultAccessoryId: curr.children?.[0]?.childId || curr.serviceGroupId,
          selectedAccessoryId,
          selectedAccessoryPrice,
          configuredProductId: curr.configuredProductId,
        };
      }

      return acc;
    }, {});

    return updated;
  }

  selectMandatory(service) {
    if (service.isMandatory) {
      if (service.children.length) {
        return {
          isVisible: true,
          selectedAccessoryId: service.children[0].childId,
          selectedAccessoryPrice: service.children[0].price,
        };
      } else {
        return {
          isVisible: true,
          selectedAccessoryId: service.serviceGroupId,
          selectedAccessoryPrice: service.price,
        };
      }
    } else {
      return {
        isVisible: false,
        selectedAccessoryId: null,
        selectedAccessoryPrice: null,
      };
    }
  }

  set({ serviceGroupId, accessoryId, accessoryPrice }) {
    if (accessoryId) {
      this._state[serviceGroupId].defaultAccessoryId = accessoryId;
      this._state[serviceGroupId].selectedAccessoryId = accessoryId;
      this._state[serviceGroupId].selectedAccessoryPrice = accessoryPrice;
    }

    this._state[serviceGroupId].previouslySelected = true;
    this._state[serviceGroupId].isVisible = true;

    Object.keys(this._state).forEach((service) => {
      this._state[service].isSelected = serviceGroupId === service;
    });

    if (this._state[serviceGroupId].isServiceOnly) {
      this._state[serviceGroupId].serviceTypeId = accessoryId;
    }
  }

  reset(serviceGroupId) {
    this._state[serviceGroupId].selectedAccessoryId = null;
    this._state[serviceGroupId].selectedAccessoryPrice = null;
  }

  getCount() {
    return Object.values(this._state)?.filter((accessory) => accessory.selectedAccessoryId)?.length;
  }

  getVisibleCount() {
    return Object.values(this._state)?.filter((accessory) => accessory.isVisible)?.length;
  }

  getPreviouslySelectedCount() {
    return Object.values(this._state)?.filter((accessory) => accessory.previouslySelected)?.length;
  }

  getSelectedList() {
    return Object.entries(this._state)
      .map(([serviceGroupId, service]) => {
        if (service.selectedAccessoryId) {
          return {
            serviceGroupId,
            serviceTypeId: service.serviceTypeId,
            optionSkus:
              service.isServiceOnly || service.selectedAccessoryId === serviceGroupId
                ? []
                : [service.selectedAccessoryId],
            price: service.selectedAccessoryPrice,
            serviceName: service.name,
            configuredProductId: service.configuredProductId
          };
        }
      })
      .filter(Boolean);
  }
}

export default ConfigServicesState;