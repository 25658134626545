import { LitElement, html, css } from "lit";

import "./range-slider.js";

class RangeSliders extends LitElement {
	static properties = {
		rangeFacets: { type: Array },
	};

	constructor() {
		super();
	}

	render() {
		return html`
			${this.rangeFacets.map((facet) => {
				const searchParams = new URLSearchParams(window.location.search);
				const totalMinValue = Math.floor(facet.totalMinValue);
				const totalMaxValue = Math.ceil(facet.totalMaxValue);
				let currentMinValue =  totalMinValue;
				let currentMaxValue = totalMaxValue;

				if (searchParams.has(facet.name)) {
					const param = searchParams.get(facet.name);
					const [minValue, maxValue] = param.split("TO").map((val) => val.trim());
					currentMinValue = Math.max(minValue, totalMinValue);
					currentMaxValue = Math.min(maxValue, totalMaxValue);
				}

				return html`
					<range-slider
						.name=${facet.name}
						.currentMinValue=${currentMinValue}
						.currentMaxValue=${currentMaxValue}
						.totalMinValue=${totalMinValue}
						.totalMaxValue=${totalMaxValue}
					></range-slider>
				`;
			})}
		`;
	}

	static styles = css`
		:host {
			display: flex;
			align-items: center;
			justify-content: center;
		}

		:host range-slider {
			flex-grow: 1;
		}
	`;
}
customElements.define("range-sliders", RangeSliders);
