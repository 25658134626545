export const EVENTS = Object.freeze({
	SERVICE_UPDATED: "configServiceUpdated",
	TOGGLE_FILTER: "togglefilter",
	SEARCH_CLICKED: "search-product-clicked",
  	SEARCH_ADD_TO_CART: "search-product-added-to-cart",
	SORT_SEARCH_RESULTS: "sortsearchresults",
	UPDATE_CART_CAAS: "cartUpdatedCaas",
	OPEN_CART_POPUP: "openAddToCartPopup",
	ADD_ALERT_MESSAGE: "addAlertMessage",
});

export function triggerCustomEvent(eventName, data) {
	document.dispatchEvent(new CustomEvent(eventName, { detail: data }));
}
