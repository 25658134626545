import { css, html, LitElement } from "lit";
import { creditWarningSymbol } from "../../utils/svgProvider.js";

class CreditWarning extends LitElement {
  static properties = {
    translations: { type: Object },
  };

  constructor() {
    super();
  }

  render() {
    return html`
      <div class="container">
        <div class="header">${this.translations["header"]}</div>
        <div class="content">
          <div class="icon">${creditWarningSymbol}</div>
          <div class="title">${this.translations["title"]}</div>
          <div class="text">${this.translations["description"]}</div>
        </div>
      </div>
    `;
  }

  static styles = css`
    :host {
      display: block;
    }

    .container {
      display: flex;
      flex-direction: column;
      border-radius: 8px;
      border: 1px solid #ccc;
      overflow: clip;
    }

    .header {
      background-color: #f5f5f5;
      padding: 8px;
      text-align: center;
      font-size: 14px;
      line-height: 18px;
    }

    .content {
      padding: 8px;
    }

    .icon {
      float: left;
      margin-right: 12px;
      display: flex;
      margin-top: 2px;
    }

    .title {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
    }

    .text {
      font-size: 14px;
      line-height: 20px;
      font-weight: 400;
    }
  `;
}

customElements.define("komplett-credit-warning", CreditWarning);
