import { LitElement, html, css } from "lit";
import { subscribeToStockModalStyles } from "./subscribe-to-stock-modal-container.styles.js";
import strings from "../../utils/strings.js";
import { checkmark } from "../../utils/svgProvider.js";
import { SUBSCRIBE_PRODUCT_FORM_ADD_URL } from "../../utils/api.js";
import { validateEmail } from "../../utils";

class SubscribeToStockModalSubscription extends LitElement {
  static properties = {
    isEmailInvalid: { type: Boolean },
    handleClose: { type: Function },
    registeredEmail: { type: String },
    isRegistered: { type: Boolean },
    productId: { type: String },
    isLoading: { type: Boolean },
    errorMessage: { type: String },
    notificationType: { type: String },
  }

  constructor() {
    super();
    this.isEmailInvalid = false;
    this.isRegistered = false;
    this.isLoading = false;
    this.errorMessage = "There was an error subscribing";
    this.notificationType = "StockAvailability";
    this.handleNotifyClick = this.handleNotifyClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.handleCloseClick = this.handleCloseClick.bind(this);
  }

  async handleNotifyClick() {
    const emailInput = this.shadowRoot.querySelector("input").value;
  
    if (validateEmail(emailInput)) {
      this.isEmailInvalid = false;
      this.isLoading = true;
  
      const data = {
        productId: this.productId,
        email: emailInput,
        notificationType: this.notificationType,
      };
  
      try {
        const response = await fetch(SUBSCRIBE_PRODUCT_FORM_ADD_URL, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        });
  
        if (!response.ok) {
          throw new Error(response.statusText);
        }
  
        this.registeredEmail = emailInput;
        this.isRegistered = true;
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    } else {
      this.isEmailInvalid = true;
    }
  }

  handleKeyPress(e) {
    if (e.key === "Enter") {
      this.handleNotifyClick();
    }
  }

  handleCloseClick(e) {
    e.preventDefault();
    this.handleClose();
  }

  render() {
    return html`
      <div class="modal-subscription">
        ${!this.isRegistered
          ? html`
            <div class="modal-subscription-description">
              <h2>${strings.getNotification}</h2>
              <p>${strings.description}</p>
            </div>
            <div class="modal-subscription-content">
              <div class="modal-subscription-email">
                <p class="email-label">${strings.email}</p>
                <input
                  placeholder="${strings.exampleEmail}"
                  class=${this.isEmailInvalid ? 'invalid' : ''}
                  @keydown=${this.handleKeyPress}
                >
                ${this.isEmailInvalid
                  ? html`<p class="error-message">${strings.invalidEmail}</p>`
                  : ''}
              </div>
              <div class="button-wrapper">
                <button
                  data-testid="config.service.modal.footer.button.save"
                  @click=${this.handleNotifyClick}
                  ?disabled=${this.isLoading}
                >
                  ${strings.notifyMe}
                </button>
              </div>
            </div>`
          : html`
            <div class="modal-subscription-registered-description">
              <i class="icon">
                ${checkmark}
              </i>
              <h2>${strings.notificationRegistered}</h2>
              <p>${strings.receiveEmailStart} <strong>${this.registeredEmail}</strong> ${strings.receiveEmailEnd}</p>
            </div>
            <div class="close-link-wrapper">
              <a
                @click=${this.handleCloseClick}
                data-testid="config.service.show.all.link"
                href="#"
              >
                <span>${strings.btnClose}</span>
              </a>
            </div>
          `}
      </div>
    `;
  }

  static styles = [subscribeToStockModalStyles];
}

customElements.define("subscribe-to-stock-modal-subscription", SubscribeToStockModalSubscription);
