import { css, html, LitElement } from "lit";
import strings from "../../utils/strings.js";
import { errorCircle } from "../../utils/svgProvider.js";
import { BUTTON_SIZES } from "../components/error-button/index.js";

class FormError extends LitElement {
  static properties = {
    onCloseButtonClick: { type: Function },
  };

  constructor() {
    super();
    this.onCloseButtonClick = () => {};
  }

  render() {
    return html`
      <div class="container">
        <div class="icon">${errorCircle}</div>
        <komplett-h2>
          ${strings["product.addreview.formerror.title"]}
        </komplett-h2>
        <div class="message">
          ${strings["product.addreview.formerror.message"]}
        </div>
        <komplett-error-button
          size=${BUTTON_SIZES.LARGE}
          .onClick=${() => this.onCloseButtonClick()}
        >
          ${strings["product.addreview.formerror.closeButton"]}
        </komplett-error-button>
      </div>
    `;
  }

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
    }

    .icon {
      display: flex;
    }

    .icon svg {
      width: 60px;
      height: 60px;
    }

    komplett-h2 {
      margin-top: 16px;
      text-align: center;
    }

    .message {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      max-width: 350px;
      text-align: center;
    }

    komplett-error-button {
      margin-top: 24px;
      width: 100%;
    }
  `;
}

customElements.define("komplett-add-review-form-error", FormError);
