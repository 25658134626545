import { html, css, nothing } from "lit";

export const stockTemplate = (stock) =>
  html`<div class="stockstatus">
    ${!stock.isPendingRelease
      ? html`<i
            class="icon instock ${stock.stockIconColor}"
            title="${stock.availabilityText}"
          ></i>
          <span
            title="${stock.availabilityText}"
            class="stockstatus-stock-details"
          >
            ${stock.availabilityText}
          </span>`
      : nothing}
  </div>`;

export const stockStyles = css`
  .stockstatus {
    grid-area: stockstatus;
    font-size: 14px;

    display: flex;
    align-items: center;
    gap: 5px;
    color: #737373;
  }

  .stockstatus span {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    flex: 1 1 calc(100% - 12px);
  }

  .stockstatus .icon {
    height: 10px;
    width: 10px;
    background: var(--stock-color, #1c961c);
    border-radius: 50%;
    display: inline-block;
  }

  .stockstatus .icon.yellow {
    --stock-color: #ffed53;
  }

  .stockstatus .icon.yellowgreen {
    --stock-color: linear-gradient(135deg, #ffed53 50%, #1c961c 50%);
  }

  .stockstatus .icon.blue {
    --stock-color: #0074bd;
  }
    
  .stockstatus .icon.whitegreen {
    --stock-color: white;
    border: 2px solid #1c961c;
  }
`;
