import { css, html, LitElement } from "lit";
import { createRef, ref } from "lit/directives/ref.js";

export class InputError extends LitElement {
  static properties = {
    _displayText: { state: true },
    _height: { state: true },
    text: { type: String },
    marginTop: { type: Number },
  };

  _sizerRef = createRef();

  constructor() {
    super();
    this._displayText = "";
    this._height = 0;
    this.marginTop = 0;
  }

  updated(changedProperties) {
    if (changedProperties.has("_displayText") && this._sizerRef.value) {
      this._height = this._sizerRef.value.offsetHeight;
    }

    if (changedProperties.has("text")) {
      this._displayText = this.text;
    }
  }

  render() {
    return html`<div
      class="container"
      style="height: ${this._height}px; margin-top: ${this.text.length > 0
        ? this.marginTop
        : 0}px"
    >
      ${this._displayText || ""}
      <div class="sizer" ${ref(this._sizerRef)}>${this.text || ""}</div>
    </div>`;
  }

  static styles = css`
    .container {
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: #b41400;
      margin: 0;
      padding: 0;
      height: 0;
      position: relative;
      transition: height 0.2s ease, margin-top 0.2s ease;
      overflow-y: clip;
    }

    .sizer {
      position: absolute;
      top: 0;
      opacity: 0;
    }
  `;
}
