import { css } from "lit";

export const saleSetContainerStyles = css`
        .title {
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
            margin-bottom: 16px;
            margin-top: 0;
        }

        .products-flex {
            display: flex;
            flex-wrap: wrap;
            margin: -8px -8px 0;
        }

        .product-wrapper {
            width: 50%;
            padding: 8px;
            box-sizing: border-box;
        }

        .product {
            max-width: 252px;
            width: 100%;
            height: 96px;
            display: flex;
            align-items: center;
            border: 1px solid #CCCCCC;
            border-radius: 10px;
            padding: 8px 16px;
            box-sizing: border-box;
            text-decoration: none;
            color: inherit;
        }

        .product-text-wrapper{
            display: flex;
            flex-direction: column;
        }

        .product-quantity{
            padding-top: 8px;
        }

        .product-quantity-text{
            font-weight: 400;
            color: rgba(89, 89, 89, 1);
            font-size: 14px;
            line-height: 18px;
            padding-top: 8px;
        }

        .product:active{
            background: rgba(244, 246, 254, 1);
        }

        .product:hover {
            cursor: pointer;
            border: 1px solid rgba(42, 65, 187, 1);
            box-shadow: 0 0 0 2px rgba(42, 65, 187, 0.25);
        }

        .product:hover .product-title-text {
            color: rgba(42, 65, 187, 1);
        }

        .product-image-container {
            width: 33.33%;
            max-width: 80px;
            max-height: 80px;
            margin-right: 12px;
            flex-shrink: 0;
        }

        .product-image {
            width: 100%;
            height: 80px;
            object-fit: contain;
        }

        .product-title {
            flex: 1;
            min-width: 0;
        }

        .product-title-text {
            font-size: 14px;
            line-height: 1.2;
            color: #1F1A49;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            text-decoration: underline;
        }

        @media (min-width: 600px) and (max-width: 1100px) {
            .product-wrapper {
                width: 100%;
            }

            .product {
                max-width: 100%;
            }
        }

        @media (max-width: 599px) {
            .product {
                height: 76px;
            }

            .product-wrapper{
                padding: 4px;
            }

            .products-flex {
                padding: -4px -4px 32px;
            }
        }
    `;
