export const debounce = (callback, delay = 250) => {
  let timeoutId;
  return (...args) => {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      timeoutId = null;
      callback(...args);
    }, delay);
  };
};

export function createStarList(rating = 0) {
  const fill = (n, i) => Array(n).fill(i);
  const floor = Math.floor(rating);
  const ceil = Math.ceil(rating);
  const list = [floor, ceil - floor, 5 - ceil];
  return list.reduce(
    (starList, count, i) => starList.concat(fill(count, i)),
    []
  );
}

export const STORES = Object.freeze({
  KOMPLETT_NO: 310,
  KOMPLETT_SE: 312,
  KOMPLETT_DK: 321,
  KOMPLETT_BEDRIFT: 324,
  ITEGRA_NO: 325,
  ITEGRA_SE: 318,
  KOMPLETT_FORETAG: 344,
});

export function isB2C(storeId) {
  return [STORES.KOMPLETT_NO, STORES.KOMPLETT_DK, STORES.KOMPLETT_SE].includes(
    parseInt(storeId, 10)
  );
}

export function hasFlex(storeId) {
  return [STORES.KOMPLETT_NO, STORES.KOMPLETT_SE].includes(
    parseInt(storeId, 10)
  );
}

export function validateEmail(email) {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegex.test(email);
}

export const isItegra = (storeId) => {
  return [STORES.ITEGRA_NO, STORES.ITEGRA_SE].includes(parseInt(storeId, 10));
};

export const supportsReviews = (storeId) => {
  return [STORES.KOMPLETT_NO, STORES.KOMPLETT_SE, STORES.KOMPLETT_DK, STORES.KOMPLETT_BEDRIFT].includes(parseInt(storeId, 10));
};

export const canDisplayButton = (buttonType) => {
  return buttonType === "AddToCart";
}

export const getStoreColor = (storeId) => {
  return isItegra(storeId) ? "#ea6a20" : "#f8b912";
};

export const calcRightTextColor = (hex) => {
  const hexColor = parseInt(hex.replace(/^#/, ""), 16);
  return hexColor > 0xffffff / 2 ? "#000" : "#fff";
};