import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";

export class Label extends LitElement {
  static properties = {
    size: { type: String },
    bold: { type: Boolean },
    required: { type: Boolean },
  };

  constructor() {
    super();
  }

  render() {
    return html`<label
      class=${classMap({
        large: this.size === "large",
        small: this.size === "small",
        bold: this.bold === true,
        required: this.required === true,
      })}
    >
      <slot></slot>
    </label>`;
  }

  static styles = css`
    label {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }

    label.large {
      font-size: 18px;
      line-height: 24px;
    }

    label.small {
      font-size: 14px;
      line-height: 18px;
    }

    label.bold {
      font-weight: 500;
    }

    label.required::after {
      content: "*";
      color: #b41400;
    }
  `;
}
