import { LitElement, html, css } from "lit";
import "./subscribe-to-stock-modal-container.js";
import strings, {updateStrings} from "../../utils/strings.js";
import { bell } from "../../utils/svgProvider.js";

class SubscribeToStockContainer extends LitElement {
  static properties = {
    isModalOpen: { state: true },
    scrollPosition: { state: true },
    strings: { type: Array },
    productId: { type: String },
  };

  constructor() {
    super();
    this.isModalOpen = false;
    this.handleClick = this.handleClick.bind(this);
    this.resetModal = this.resetModal.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    updateStrings(this.strings);
  }

  handleScrollLock() {
    if (this.isModalOpen) {
      window.scroll(0, 0);
      document.documentElement.classList.add("scroll-lock-xs");
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.classList.remove("scroll-lock-xs");
      document.body.style.overflow = "visible";
    }
  }

  openModal() {
    this.scrollPosition = window.scrollY;
    this.isModalOpen = true;

    this.handleScrollLock();

    this.requestUpdate();
  }

  resetModal() {
    this.isModalOpen = false;

    this.handleScrollLock();

    window.scroll(0, this.scrollPosition);

    this.requestUpdate();
  }

  handleClick(e) {
    e.preventDefault();
    this.openModal();
  }

  render() {
    return html`
      <div class="subscribetostock">
        <a
          @click=${this.handleClick}
          data-testid="config.service.show.all.link"
          href="#"
        >
          <i class="icon" title="${strings.notification}"
            >${bell}</i>
          <span>${strings.notification}</span>
        </a>
        <subscribe-to-stock-modal-container
          .isModalOpen=${this.isModalOpen}
          .onCloseModal=${this.resetModal}
          productId=${this.productId}
        >
        </subscribe-to-stock-modal-container>
      </div>
    `;
  }

  static styles = css`
    :host {
      display: flex;
      justify-content: start;
      align-items: start;
      gap: 5px;
      min-height: 19px;
      margin-top: 8px;
    }

    .subscribetostock svg {
      height: 16px;
      width: auto;
      vertical-align: middle;
    }

    .subscribetostock span {
      vertical-align: middle;
      text-decoration: underline;
      font-weight: 500;
      margin-left: 4px;
      position: relative;
      top: 2px;
    }

    .subscribetostock a {
      color: #000000;
      text-decoration: none;
    }

    .subscribetostock a:hover {
      cursor:pointer;
    }
  `;
}
customElements.define("subscribe-to-stock-container", SubscribeToStockContainer);
