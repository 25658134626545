import { LitElement, html, css } from "lit";
import { listView, groupView } from "../utils/svgProvider";
import strings from "../utils/strings";
import {live} from "lit/directives/live.js";

class LayoutButtons extends LitElement {
  static properties = {
    state: { type: Boolean },
    handleUpdate: {
      type: Function,
    },
  };

  render() {
    return html`<span>${strings.searchFilterLayoutButtonsText}</span>
      <div>
        <button @click=${() => this.handleUpdate(false)} .disabled=${!this.state}>${listView}</button>
        <button @click=${() => this.handleUpdate(true)} .disabled=${this.state}>${groupView}</button>
      </div>`;
  }

  static styles = css`
    :host {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;
    }

    span {
      font-size: 16px;
    }

    div {
      display: flex;
      justify-content: center;
      gap: 3px;
    }

    button {
      display: flex;
      background-color: #fff;
      color: #5f5f5f;
      border: 1px solid #5f5f5f;
      border-radius: 2px;
      padding: 4px;
      cursor: pointer;
    }

    button:disabled {
      background-color: #5f5f5f;
      color: #fff;
    }
  `;
}
customElements.define("button-group", LayoutButtons);
