import { LitElement, html, css } from "lit";
import { choose } from "lit/directives/choose.js";

import { back, close } from "../../utils/svgProvider.js";
import strings from "../../utils/strings.js";
import { MODAL_TYPES } from "../configService/config-service.utils.js";

class ModalHeader extends LitElement {
  static properties = {
    modalType: { type: String },
    handleClose: {
      type: Function,
    },
    openServicesModal: {
      type: Function,
    },
  };

  render() {
    return html`${choose(this.modalType, [
      [
        MODAL_TYPES.ACCESSORIES,
        () => html`
          <h3>
            <a
              data-testid="config.service.modal.header.title"
              href="#"
              @click=${() => this.openServicesModal()}
              >${back}
              ${strings[
                "product.box.config.service.additional.services.title"
              ]}</a
            >
          </h3>
        `,
      ],
      [
        MODAL_TYPES.SERVICES,
        () => html`<h3>
            ${strings["product.box.config.service.additional.services.title"]}
          </h3>
          <a
            href="#"
            data-testid="config.service.modal.header.close"
            @click=${() => this.handleClose()}
            >${close}</a
          >`,
      ],
    ])}`;
  }
  static styles = css`
    :host {
      position: absolute;
      top: 0;
      z-index: 10;
      width: calc(100% - 48px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #cccccc;
      background: #fff;
      padding: 0 24px;
      min-height: 65px;
    }

    h3 {
      font-size: 16px;
      font-weight: normal;
      margin: 0;
    }

    a {
      display: flex;
      justify-content: center;
      font-size: 16px;
      color: #222;
      text-decoration: underline;
    }
  `;
}

customElements.define("modal-header", ModalHeader);
