import { css } from "lit";

export const filterStyles = css`
  * {
    box-sizing: border-box;
  }

  .filter-wrapper {
    padding: 0 16px;
  }

  .filter-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    align-items: center;
    height: 57px;
  }

  .close-filter {
    display: none;
  }

  .sort-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 600ms;
    padding: 5px 0;
  }

  .stock-container {
    padding-bottom: 10px;
  }

  .selected-tags {
    display: block;
    padding-top: 5px;
    padding-bottom: 3px;
    margin: 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: #5f5f5f;
    line-height: 18px;
    font-size: 14px;
  }

  .open .sort-wrapper {
    grid-template-rows: 1fr;
  }

  .open .selected-tags {
    display: none;
  }

  .sort-wrapper search-sort {
    overflow: hidden;
  }

  @media (max-width: 768px) {
    .filter-wrapper {
      width: 100%;
    }

    .filter-header {
      flex-direction: row;
    }

    .close-filter {
      display: block;
    }
  }

  @media (min-width: 769px) and (max-width: 1300px) {
    .filter-wrapper {
      width: 390px;
    }

    .stock-container {
      display: none;
    }
  }

  @media (max-width: 1300px) {
    .filter-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1100;
      transform: translateX(-140%);
      transition: transform 0.3s ease-in;
      background-color: #fff;
    }

    .filter-wrapper.show {
      transform: translateX(0);
      overflow-y: auto;
    }

    .filter-header {
      flex-direction: row;
    }

    .close-filter {
      display: block;
    }
  }

  @media (min-width: 1301px) and (max-width: 1728px) {
    .filter-wrapper {
      width: 240px;
    }
  }

  @media (min-width: 1301px) {
    .sorting-facet {
      display: none;
    }

    .stock-container {
      display: none;
    }
  }

  @media (min-width: 1728px) {
    .filter-wrapper {
      width: 320px;
    }
  }

  .close-filter {
    cursor: pointer;
    background: none;
    border: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .filter-wrapper > div:not(:first-child) {
    padding-top: 10px;
    border-top: 1px solid #ccc;
  }

  .filter-overlay {
    position: fixed;
    z-index: 1000;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    opacity: 0;
  }

  .filter-overlay.is-active {
    display: block;
    opacity: 1;
  }

  .filter-header h2 {
    font-size: 18px;
    font-weight: 700;
    white-space: nowrap;
  }

  .category-name {
    color: #222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 0;
    padding-inline-start: 0;
    background: #fff;
    border: none;
    outline-offset: 4px;
    cursor: pointer;
  }

  .facets-wrapper {
    display: grid;
    grid-template-rows: 0fr;
    transition: grid-template-rows 500ms;
    padding: 5px 0;
  }

  .facets {
    overflow: hidden;
  }

  .facets .show-top-facets {
    display: flex;
    align-items: center;
    margin-left: -7px;
    padding-right: 10px;
    border: 0;
    padding: 0;
    background: #fff;
    font-size: 14px;
    cursor: pointer;
  }

  .facets .show-top-facets svg {
    transform: rotate(90deg);
    width: 20px;
  }

  .parent-name-container {
    margin-top: 10px;
  }

  .parent-name-container .count {
    margin-right: 5px;
  }

  .parent-name {
    font-size: 15px;
    font-weight: 500;
  }

  .facets ul {
    list-style: none;
    padding-inline-start: 0px;
    margin: 0;
    padding: 5px 0;
    overflow: hidden;
    max-height: 208px;
    transition: max-height 500ms;
  }

  .facets-wrapper ul.open {
    max-height: var(--max-height, 1000px);
  }

  .open .facets-wrapper,
  .facets-wrapper:has(input:focus) {
    grid-template-rows: 1fr;
  }

  .show-more {
    cursor: pointer;
    display: inline-block;
    padding: 2px 0;
    margin: 0 6px 6px;
    font-size: 14px;
    font-weight: 500;
    color: #0670a3;
    text-decoration-line: underline;
    background: #fff;
    border: 0;
  }

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    margin-right: 5px;
  }

  .facet-label {
    display: grid;
    align-items: center;
    grid-template-columns: auto 1fr auto;
    gap: 8px;
    width: 100%;
    padding: 5px 0;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }

  .facet-category-links {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-size: 14px;

    svg {
      transform: rotate(-90deg) !important;
      width: 20px;
      height: 20px;
      margin-bottom: 2px;
    }
  }

  .facet-category-links:hover {
    text-decoration: underline;
  }

  .facet-label:has(input:focus-visible),
  .show-more:focus-visible {
    outline: solid 2px #333;
    outline-offset: 4px;
    border-radius: 1px;
  }

  .clamp-lines {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
  }

  .count {
    color: var(--count-color);
  }

  svg {
    transition: transform 500ms;
  }

  .open svg {
    transform: rotate(180deg);
  }

  .reset-filter {
    cursor: pointer;
    font-size: 14px;
    color: #2b6e94;
    background: #fff;
    border: none;
  }

  .ipad-mobile-show-results {
    position: sticky;
    background-color: white;
    bottom: 0;
    padding: 16px 0;

    .show-results-button {
      cursor: pointer;
      width: 100%;
      height: 48px;
      color: white;
      background-color: #1f1a49;
      border-radius: 4px;
    }
  }

  .parent-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  range-sliders {
    margin-bottom: 64px;
  }
`;
