import { css, html, LitElement } from "lit";
import strings from "../../utils/strings.js";
import { BUTTON_SIZES as CLEAR_BUTTON_SIZES } from "../components/clear-button";
import { BUTTON_SIZES as ERROR_BUTTON_SIZES } from "../components/error-button";

class UnsavedPrompt extends LitElement {
  static properties = {
    onRejectClick: { type: Function },
    onConfirmClick: { type: Function },
  };

  constructor() {
    super();
    this.onRejectClick = () => {};
    this.onConfirmClick = () => {};
  }

  render() {
    return html`
      <div class="container">
        <komplett-h2>
          ${strings["product.addreview.unsavedprompt.title"]}
        </komplett-h2>
        <div class="message">
          ${strings["product.addreview.unsavedprompt.message"]}
        </div>
        <div class="actions">
          <komplett-clear-button
            size=${CLEAR_BUTTON_SIZES.LARGE}
            .onClick=${() => this.onRejectClick()}
          >
            ${strings["product.addreview.unsavedprompt.rejectButton"]}
          </komplett-clear-button>
          <komplett-error-button
            size=${ERROR_BUTTON_SIZES.LARGE}
            .onClick=${() => this.onConfirmClick()}
          >
            ${strings["product.addreview.unsavedprompt.confirmButton"]}
          </komplett-error-button>
        </div>
      </div>
    `;
  }

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 16px;
    }

    .message {
      margin-top: 16px;
      font-size: 16px;
      line-height: 24px;
      max-width: 360px;
      text-align: center;
    }

    .actions {
      display: flex;
      margin-top: 24px;
      gap: 16px;
      width: 100%;
    }

    komplett-clear-button,
    komplett-error-button {
      flex-grow: 1;
    }

    @media (min-width: 992px) {
      .container {
        padding: 32px;
      }
    }
  `;
}

customElements.define("komplett-add-review-unsaved-prompt", UnsavedPrompt);
