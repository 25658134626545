import {LitElement, html, css, nothing} from "lit";
import { EVENTS, triggerCustomEvent } from "../utils/customEvents.js";

import strings from "../utils/strings.js";

import "./layout-buttons.js";
import "./stock-checkbox.js";
import "./searchSort/search-sort.js";
import { PAGE_TYPES } from "./search-container.utils";

class SearchBar extends LitElement {
  static properties = {
    useGrid: { type: Boolean },
    layoutUpdate: {
      type: Function,
    },
    sortOptions: { type: Array },
    searchText: { type: String },
    numberOfResults: { type: Number },
    updateSort: { type: Function },
    updateStockFilter: { type: Function },
    useStockFilter: { type: Boolean },
    pageType: { type: String },
  };

  constructor() {
    super();
    this.searchText = "";
    this.numberOfResults = 0;
    this.sortOptions = [];
  }

  _triggerFilterMenu() {
    triggerCustomEvent(EVENTS.TOGGLE_FILTER);
  }

  render() {
    return html`
      <button-group .state=${this.useGrid} .handleUpdate=${(useGrid) => this.layoutUpdate(useGrid)}></button-group>
      <stock-checkbox .updateStockFilter=${this.updateStockFilter} .useStockFilter=${this.useStockFilter}></stock-checkbox>
      <div class="query-info"><b><span>${this.numberOfResults}</span> ${strings.searchBarQueryInfo} ${this.pageType === PAGE_TYPES.SEARCH ? html`</b>"${this.searchText}"` : nothing }</div>
      <button class="filter-button" @click="${this._triggerFilterMenu}">
        <span class="tablet-label">${strings.searchBarMobileHeading}</span>
        <span class="mobil-label">${strings.searchBarIpadHeading} (${this.numberOfResults})</span>
      </button>
      <search-sort .mobile=${false} .sortOptions=${this.sortOptions}></search-sort>
    `;
  }

  static styles = css`
    :host {
      overflow: hidden;
      border-bottom: 1px solid #ccc;
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 16px;
      padding: 8px 16px;
      height: 40px;
    }

    button-group {
      margin-right: auto;
    }

    label {
      font-size: 13px;
      display: flex;
      align-items: center;
      gap: 5px;
    }

    select {
      padding: 5px;
      font-size: 16px;
      border-radius: 4px;
      border: 1px solid #ddd;
    }

    button {
      font-size: 16px;
      background-color: #fff;
      border: 1px solid #ccc;
      padding: 8px 24px;
      border-radius: 4px;
      cursor: pointer;
    }

    .query-info span {
      min-width: 25px;
      text-align: end;
    }

    .filter-button {
      display: none;
    }

    @media (max-width: 1300px) {
      .filter-button {
        display: block;
      }

      .mobil-label,
      search-sort {
        display: none;
      }
    }

    @media (max-width: 768px) {
      :host {
        border-bottom: none;
        padding: 10px;
      }

      button-group,
      stock-checkbox,
      .query-info,
      .tablet-label {
        display: none;
      }

      .filter-button {
        width: 100%;
        padding: 12px 24px;
      }

      .mobil-label {
        display: block;
      }
    }
  `;
}
customElements.define("search-bar", SearchBar);
