import { LitElement, html, css } from "lit";
import { EVENTS, triggerCustomEvent } from "../../utils/customEvents.js";

class SearchSort extends LitElement {
  static properties = {
    mobile: { type: Boolean },
    sortOptions: { type: Array },
    sortOptionFromQuery: { type: Object }
  };

  constructor() {
    super();
    this.sortOptions = [];
    this.sortOptionFromQuery = null;
    this.parseSortFromUrl();
  }

  parseSortFromUrl() {
    const searchParams = new URLSearchParams(window.location.search);
    const sortParam = searchParams.get('sort');
    if (sortParam) {
      this.sortOptionFromQuery = {
        key: "sort",
        value: sortParam
      };
    }
  }

  _triggerSorting(event) {
    triggerCustomEvent(EVENTS.SORT_SEARCH_RESULTS, {
      key: "sort",
      value: event.target.value,
    });
  }

  render() {
    if (this.mobile) {
      return html`
        <div class="mobileSortingOptions">
          ${this.sortOptions.map(
              (sortOption) => html`
                <label class="radio">
                  <input
                      type="radio"
                      id=${sortOption.value}
                      value=${sortOption.value}
                      name="default"
                      ?checked=${this.sortOptionFromQuery && this.sortOptionFromQuery.value === sortOption.value}
                      @change="${() =>
                          this._triggerSorting({
                            target: { value: sortOption.value },
                          })}"
                  />
                  ${sortOption.mobileName}
                </label>
              `
          )}
        </div>
      `;
    }

    return html`
      <div class="select">
        <select @change="${this._triggerSorting}">
          ${this.sortOptions.map((sortOption) => html`
            <option
                value=${sortOption.value}
                ?selected=${this.sortOptionFromQuery && this.sortOptionFromQuery.value === sortOption.value}
            >
              ${sortOption.name}
            </option>
          `)}
        </select>
      </div>
    `;
  }

  static styles = css`
    .mobileSortingOptions {
      display: flex;
      flex-direction: column;
      gap: 2px;
      margin: 5px 0 10px;
    }

    .radio {
      font-size: 14px;
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 8px 0;
      cursor: pointer;

      & input {
        height: 21px;
        width: 21px;
        cursor: pointer;
        margin: 0;

        appearance: none;
        border-radius: 50%;
        background-clip: content-box;
        border: 1px solid #ccc;

        &:hover {
          border: 1px solid #333;
        }

        &:checked {
          background-color: #333;
          padding: 3px;
          border: 1px solid #333;
        }
      }
    }

    .select {
      font-size: 14px;

      & label {
        margin-bottom: 8px;
      }

      & select {
        font-size: 14px;
        padding: 6px 3rem 6px 16px;
        line-height: 22px;
        border-radius: 4px;
        border: 1px solid #ccc;

        background: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14 1.5625L7 8.5625L0 1.5625L0.875 0.6875L7 6.8125L13.125 0.6875L14 1.5625Z' fill='%23222222'/%3E%3C/svg%3E%0A")
          no-repeat;
        background-position: calc(100% - 1.15rem) center;
        appearance: none;

        &:hover,
        &:focus,
        &:focus-visible {
          border-color: #6c6c6c;
          outline-color: #6c6c6c;
        }

        &[disabled] {
          &:hover,
          &:focus,
          &:focus-visible {
            border: 1px solid #333;
          }
        }
      }
    }
  `;
}

customElements.define("search-sort", SearchSort);
