import { css, html, LitElement, nothing } from "lit";
import { repeat } from "lit/directives/repeat.js";
import { shieldAltSolid, close } from "../../utils/svgProvider.js";
import strings, { updateStrings } from "../../utils/strings.js";
import { BUTTON_SIZES as SECONDARY_BUTTON_SIZES } from "../components/secondary-button";
import { BUTTON_SIZES as LINK_LIKE_BUTTON_SIZES } from "../components/link-like-button";

class InsuranceInfo extends LitElement {
  static properties = {
    offer: { type: String },
    translations: { type: Object },
    _isModalOpen: { state: true },
  };

  constructor() {
    super();
    this._isModalOpen = false;
    this.translations = {};
  }

  _openModal() {
    this._isModalOpen = true;
  }

  _closeModal() {
    this._isModalOpen = false;
  }

  connectedCallback() {
    super.connectedCallback();
    updateStrings(this.translations);
  }

  render() {
    return html`
      <div class="container">
        <div class="content">
          ${shieldAltSolid}
          <div class="text">
            <div class="title">${strings["product.insuranceinfo.title"]}</div>
            <div class="price">${this.offer}</div>
          </div>
        </div>
        <komplett-link-like-button
          size=${LINK_LIKE_BUTTON_SIZES.SMALL}
          .onClick=${() => this._openModal()}
        >
          ${strings["product.insuranceinfo.readmore.button"]}
        </komplett-link-like-button>
      </div>
      <komplett-modal
        compactViewBreakpoint="650"
        .isOpen=${this._isModalOpen}
        .onOverlayClick=${() => this._closeModal()}
      >
        <div class="modal-header">
          <komplett-h2>${strings["product.insuranceinfo.title"]}</komplett-h2>
          <button
            @click=${() => this._closeModal()}
            aria-label="Close"
            type="button"
            class="modal-close-button"
          >
            ${close}
          </button>
        </div>
        <div class="modal-content">
          <div class="scroll">
            <ul>
              ${repeat(Array(9), null, (_, index) =>
                strings[`product.insuranceinfo.listitem.${index + 1}`]
                  ? html`<li>
                      ${strings[`product.insuranceinfo.listitem.${index + 1}`]}
                    </li>`
                  : nothing
              )}
            </ul>
            <p>
              ${strings["product.insuranceinfo.info.1"]}
              <komplett-link
                href="${strings["product.insuranceinfo.info.1.link"]}"
                external="true"
              >
                ${strings["product.insuranceinfo.info.1.linktext"]}
              </komplett-link>
            </p>
            <p>${strings["product.insuranceinfo.info.2"]}</p>
          </div>
          <komplett-secondary-button
            size=${SECONDARY_BUTTON_SIZES.LARGE}
            .onClick=${() => this._closeModal()}
          >
            ${strings["product.insuranceinfo.close.button"]}
          </komplett-secondary-button>
        </div>
      </komplett-modal>
    `;
  }

  static styles = css`
    :host {
      display: block;
    }

    .container {
      display: flex;
      gap: 18px;
      justify-content: space-between;
      align-items: center;
    }

    .content {
      display: flex;
      align-items: center;
    }

    .content > svg {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
    }

    .text {
      display: flex;
      margin-left: 8px;
      align-items: center;
      flex-wrap: wrap;
    }

    .title {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: #000;
      margin-right: 12px;
    }

    .price {
      font-size: 14px;
      line-height: 18px;
      font-weight: 400;
      color: #595959;
    }

    .link {
      display: flex;
      flex-shrink: 0;
    }

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      margin: 32px;
      margin-bottom: 0;
    }

    .modal-close-button {
      display: flex;
      border: 0;
      background: none;
      padding: 0;
      border-radius: 100%;
      cursor: pointer;
      outline: 2px solid transparent;
      transition: outline 0.2s ease;
    }

    .modal-close-button:hover,
    .modal-close-button:focus,
    .modal-close-button:active {
      outline-color: #222222;
    }

    .modal-close-button svg {
      width: 40px;
      height: 40px;
    }

    .modal-content {
      display: flex;
      flex-direction: column;
      margin-bottom: 32px;
      height: calc(100% - (40px + 32px));
    }

    .scroll {
      display: flex;
      flex-grow: 1;
      flex-direction: column;
      overflow-y: auto;
      padding: 0 32px;
      margin: 24px 0;
      max-height: calc(100vh - (72px + 52px + 32px + 48px));

      @media (min-width: 650px) {
        max-height: calc(80vh - (72px + 52px + 32px + 48px));
      }
    }

    ul {
      padding-left: 24px;
      margin: 0;
      margin-bottom: 24px;
      font-size: 14px;
      line-height: 20px;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 1.5;
    }

    komplett-modal {
      --komplett-modal-desktop-height: fit-content;
      --komplett-modal-desktop-width: 600px;
    }

    komplett-secondary-button {
      padding: 0 32px;
      width: 100%;
      box-sizing: border-box;
    }

    komplett-link-like-button {
      flex-shrink: 0;
    }
  `;
}

customElements.define("komplett-insurance-info", InsuranceInfo);
