import { LitElement, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { choose } from "lit/directives/choose.js";
import { createRef, ref } from "lit/directives/ref.js";
import { MODAL_TYPES } from "../configService/config-service.utils.js";
import { optionStyles } from "../configService/config-service.styles.js";
import { modalStyles } from "./modal-container.styles.js";

import "./modal-header.js";
import "./modal-footer.js";
import "./modal-services.js";
import "./modal-service-details.js";
import "./modal-accessories.js";

class Modal extends LitElement {
  static properties = {
    isModalOpen: { type: Boolean },
    modalType: { type: String },
    services: { type: Object },
    servicesState: { type: Object },
    selectedService: { state: true },
    resetAccessorySelection: { type: Function },
    onConfirmModal: {
      type: Function,
    },
    onCloseModal: {
      type: Function,
    },
  };

  containerRef = createRef();

  constructor() {
    super();
    this.selectedAccessoryId = null;
    this.selectedAccessoryPrice = null;
  }

  handleClose() {
    this.onCloseModal();
  }

  handleConfirm() {
    this.onConfirmModal({
      selectedServiceGroupId: this.selectedService.serviceGroupId,
      selectedAccessoryId: this.selectedAccessoryId,
      selectedAccessoryPrice: this.selectedAccessoryPrice,
    });

    this.onCloseModal();
    this.requestUpdate();
  }

  handleReset() {
    this.selectedAccessoryId = null;
    this.selectedAccessoryPrice = null;

    this.resetAccessorySelection(this.selectedService.serviceGroupId);
    this.requestUpdate();
  }

  selectAccessory({ serviceGroupId, accessoryId, accessoryPrice }) {
    this.selectedAccessoryId = accessoryId;
    this.selectedAccessoryPrice = accessoryPrice;

    this.onConfirmModal({
      selectedServiceGroupId: serviceGroupId,
      selectedAccessoryId: accessoryId,
      selectedAccessoryPrice: accessoryPrice,
    });

    this.requestUpdate();
  }

  openServicesModal() {
    this.modalType = MODAL_TYPES.SERVICES;

    this.selectedAccessoryId = null;
    this.selectedAccessoryPrice = null;

    this.requestUpdate();
  }

  openAccessoriesModal(selectedServiceGroupId) {
    this.modalType = MODAL_TYPES.ACCESSORIES;

    this.servicesState.set({
      serviceGroupId: selectedServiceGroupId,
    });

    this.requestUpdate();
  }

  updated(changedProperties) {
    if (changedProperties.has("modalType")) {
      this.containerRef?.value?.focus();
    }
  }

  render() {
    if (this.isModalOpen && this.modalType === MODAL_TYPES.ACCESSORIES) {
      const selectedService = Object.entries(this.servicesState._state).find(
        ([selectedServiceGroupId, serviceConfig]) => {
          if (serviceConfig.isSelected) {
            return selectedServiceGroupId;
          }
          return null;
        }
      );

      this.selectedService = this.services?.find(
        (service) => service.serviceGroupId === selectedService?.[0]
      );
    }

    return html` <div
        @click=${() => {
          if (this.isModalOpen) {
            this.handleClose();
          }
        }}
        class=${classMap({
          "is-active": this.isModalOpen,
          "modal-overlay": true,
        })}
      ></div>
      <div
        ${ref(this.containerRef)}
        tabindex="-1"
        class=${classMap({
          "is-active": this.isModalOpen,
          "modal-wrapper": true,
        })}
      >
        ${this.isModalOpen
          ? html`
              <modal-header
                .modalType=${this.modalType}
                .openServicesModal=${() => this.openServicesModal()}
                .handleClose=${() => this.handleClose()}
              ></modal-header>

              ${choose(this.modalType, [
                [
                  MODAL_TYPES.ACCESSORIES,
                  () => html`<modal-accessories
                    .selectedService=${this.selectedService}
                    .servicesState=${this.servicesState}
                    .selectAccessory=${({
                      serviceGroupId,
                      accessoryId,
                      accessoryPrice,
                    }) =>
                      this.selectAccessory({
                        serviceGroupId,
                        accessoryId,
                        accessoryPrice,
                      })}
                  >
                  </modal-accessories>`,
                ],
                [
                  MODAL_TYPES.SERVICES,
                  () => html`<modal-services
                    .services=${this.services}
                    .servicesState=${this.servicesState}
                    .selectAccessory=${({
                      serviceGroupId,
                      accessoryId,
                      accessoryPrice,
                    }) =>
                      this.selectAccessory({
                        serviceGroupId,
                        accessoryId,
                        accessoryPrice,
                      })}
                    .openAccessoriesModal=${(selectedServiceGroupId) =>
                      this.openAccessoriesModal(selectedServiceGroupId)}
                  ></modal-services>`,
                ],
              ])}

              <modal-footer
                .modalType=${this.modalType}
                .servicesState=${this.servicesState}
                .isMandatoryService=${this.selectedService?.isMandatory}
                .isServiceOnly=${!this.selectedService?.children.length}
                .selectedAccessoryId=${this.servicesState._state[
                  this.selectedService?.serviceGroupId
                ]?.selectedAccessoryId}
                .handleClose=${() => this.handleClose()}
                .handleConfirm=${() => this.handleConfirm()}
                .handleReset=${() => this.handleReset()}
              ></modal-footer>
            `
          : nothing}
      </div>`;
  }

  static styles = [optionStyles, modalStyles];
}
customElements.define("modal-container", Modal);
