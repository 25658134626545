import { css, html, LitElement } from "lit";

class SearchBannerList extends LitElement {
  static properties = {
    banners: { type: Array },
  };

  constructor() {
    super();
    this.banners = [];
  }

  connectedCallback() {
    super.connectedCallback();
  }

  render() {
    return html`
      ${this.banners.map(
        (banner) => html`
          <a class="banner" href="${banner.destinationUrl}" target="_blank">
            <img src="${banner.imageUrl}" alt="${banner.altText}" />
          </a>
        `
      )}
    `;
  }

  static styles = css`
    :host {
      overflow: hidden;
      display: flex;
      justify-content: right;
      align-items: center;
      gap: 16px;
      padding: 8px 16px;
      height: fit-content;
      flex-direction: column;
    }

    .banner {
      display: flex;
      width: 100%;
    }

    .banner img {
      width: 100%;
    }
  `;
}

customElements.define("search-banner-list", SearchBannerList);
