import { css, html, LitElement } from "lit";
import strings from "../../utils/strings.js";
import { BUTTON_SIZES } from "../components/secondary-button";

class Guidelines extends LitElement {
  static properties = {
    onBackButtonClick: { type: Function },
  };

  constructor() {
    super();
    this.onBackButtonClick = () => {};
  }

  render() {
    return html`
      <div class="container">
        <komplett-h2>
          ${strings["product.addreview.guidelines.title"]}
        </komplett-h2>
        <div class="scroll">
          <div class="label">
            ${strings["product.addreview.guidelines.allowed.question"]}
          </div>
          <p>${strings["product.addreview.guidelines.allowed.answer"]}</p>
          <div class="label">
            ${strings["product.addreview.guidelines.donotinclude.question"]}
          </div>
          <ul>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.1"]}
            </li>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.2"]}
            </li>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.3"]}
            </li>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.4"]}
            </li>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.5"]}
            </li>
            <li>
              ${strings["product.addreview.guidelines.donotinclude.item.6"]}
            </li>
          </ul>
          <p>${strings["product.addreview.guidelines.info.1"]}</p>
          <p>${strings["product.addreview.guidelines.info.2"]}</p>
          <p>${strings["product.addreview.guidelines.info.3"]}</p>
          <p>${strings["product.addreview.guidelines.info.4"]}</p>
          <p>${strings["product.addreview.guidelines.info.5"]}</p>
        </div>
        <komplett-secondary-button
          size=${BUTTON_SIZES.LARGE}
          .onClick=${() => this.onBackButtonClick()}
        >
          ${strings["product.addreview.guidelines.backButton"]}
        </komplett-secondary-button>
      </div>
    `;
  }

  static styles = css`
    :host {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }

    .container {
      display: flex;
      flex-direction: column;
      padding: 32px 0;
      font-size: 16px;
      line-height: 24px;
      height: 100%;
      box-sizing: border-box;
    }

    komplett-h2 {
      padding: 0 32px;
      box-sizing: border-box;
      width: 100%;
    }

    .scroll {
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding: 0 32px;
      height: calc(100% - 116px);
    }

    .label {
      font-weight: 500;
      line-height: 20px;
      margin-top: 24px;
    }

    ul {
      padding-left: 24px;
      margin: 8px 0 24px;
    }

    p {
      margin: 0;
    }

    komplett-secondary-button {
      padding: 0 32px;
      margin-top: 24px;
      width: 100%;
      box-sizing: border-box;
    }
  `;
}

customElements.define("komplett-add-review-guidelines", Guidelines);
