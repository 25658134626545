import { LitElement, html, css } from "lit";
import { choose } from "lit/directives/choose.js";

import { EVENTS } from "../utils/customEvents.js";
import {
  GRID_VARIANTS,
  PAGE_TYPES,
  trackSearch,
  trackFilter,
  isInParamWhiteList,
} from "./search-container.utils.js";
import strings, { updateStrings } from "../utils/strings.js";

import "./rr-recommendations.js";

import "./search-bar.js";
import "./ProductBoxList/search-result.js";
import "./searchFilter/search-filter.js";
import "./searchFilter/search-filter-tags.js";
import "./searchBanner/searchBannerList.js";

const LIST_VIEW = { KEY: "list_view", OPTIONS: { GRID: "grid", LIST: "list" } };

class SearchContainer extends LitElement {
  static properties = {
    searchText: { type: String },
    categories: { type: Array },
    sortOptions: { type: Array },
    rangeFacets: { type: Array },
    queryParameters: { type: Object },
    pageIdentifier: { type: String },
    numberOfResults: { type: Number },
    totalNumberOfResults: { type: Number },
    page: { type: Number },
    pageType: { type: String },
    useGrid: { type: Boolean },
    strings: { type: Object },
    tracking: { type: Object },
    banners: { type: Array },

    _products: { type: Array },
    _loading: { type: Boolean },
    _end: { type: Boolean },
  };

  constructor() {
    super();
    this.searchText = "";
    this.pageIdentifier = null;
    this.queryParameters = {};
    this.page = 0;
    this.useGrid = false;
    this.numberOfResults = 0;

    this._products = [];
    this._loading = false;
    this._end = false;
    this._cssGridTemplate = "";
    this.sortOptions = [];
    this.useStockFilter = false;

    this.searchParams = new URLSearchParams(window.location.search);
  }

  connectedCallback() {
    super.connectedCallback();
    this.queryParameters.forEach((q) => this.updateSearchQuery(q.key, q.value));
    this.combineSearchAndFacets(this.categories);
    updateStrings(this.strings);

    document.addEventListener("scroll", this._checkScrollProgress);
    document.addEventListener(EVENTS.SORT_SEARCH_RESULTS, this._updateSort);
    window.addEventListener("popstate", this.handlePopState);
    trackSearch(this.tracking);

    this.useStockFilter =
      this.queryParameters.find((q) => q.key === "kom_availabilityStatus")
        ?.value === "Stocked";
  }

  disconnectedCallback() {
    document.removeEventListener("scroll", this._checkScrollProgress);
    document.removeEventListener(EVENTS.SORT_SEARCH_RESULTS, this._updateSort);
    window.removeEventListener("popstate", this.handlePopState);
    super.disconnectedCallback();
  }

  firstUpdated() {
    this._setListMode();
  }

  handlePopState = async () => {
    this.searchParams = new URLSearchParams(window.location.search);

    await this.search({
      append: false,
      page: 0,
      filterUpdate: true,
      resetRangeFacets: true,
    });

    this.combineSearchAndFacets(this.categories);
  };

  updateSearchQuery(parameter, value, add = true) {
    if (add) {
      if (parameter === "categoryPages") {
        this.searchParams.set(parameter, value);
        return;
      }

      if (!this.searchParams.has(parameter, value)) {
        this.searchParams.append(parameter, value);
      }
    } else {
      this.searchParams.delete(parameter, value);
    }
  }

  _updateQueryHistory = () => {
    var newRelativePathQuery =
      window.location.pathname + "?" + this.searchParams.toString();
    history.pushState(null, "", newRelativePathQuery);
  };

  _checkScrollProgress = async () => {
    if (this._loading || this._end) return;

    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - 300
    ) {
      this._loading = true;

      this.loadMoreProducts();
    }
  };

  async loadMoreProducts() {
    const pageMaxNow = Math.ceil(this._products.length / 24) - 1;
    const pageMinNow = Math.floor(this._products.length / 24) - 1;
    if (pageMaxNow === pageMinNow) {
      await this.search({
        append: true,
        page: this.page + 1,
        filterUpdate: false,
        resetRangeFacets: false,
      });
    } else {
      this._loading = false;
      this._end = true;
    }
  }

  async search({
    append = false,
    page = 0,
    filterUpdate = false,
    resetRangeFacets = false,
  }) {
    let url = window.location.origin + `/api/v1/${this.pageType}?`;

    if (this.pageType === PAGE_TYPES.CATEGORY) {
      url = url + `categoryId=${this.pageIdentifier}&`;
    }

    if (this.pageType === PAGE_TYPES.MANUFACTURER) {
      url = url + `manufacturerName=${this.pageIdentifier}&`;
    }

    url = url + this.searchParams.toString();
    url = `${url}${page > 0 ? `&page=${page}` : ""}`;

    const response = await fetch(`${url}`);
    const jsonResponse = await response.json();

    if (jsonResponse?.products) {
      const itemsComponent = this._slottedItems;
      if (!append) {
        this._products = [];
        itemsComponent.setAttribute(
          "products",
          JSON.stringify(jsonResponse.products)
        );
        itemsComponent.setAttribute("addedproducts", JSON.stringify([]));
      } else {
        this._products = [...this._products, ...jsonResponse.products];
        itemsComponent.setAttribute(
          "addedproducts",
          JSON.stringify(this._products)
        );
      }
    }

    if (jsonResponse?.queryParameters) {
      this.queryParameters = jsonResponse.queryParameters;
    }

    if (jsonResponse?.banners) {
      this.banners = jsonResponse.banners;
    }

    if (!append && jsonResponse?.facets) {
      const categories = jsonResponse.facets;
      this.combineSearchAndFacets(categories);
      this.categories = categories;
    }

    if (resetRangeFacets && jsonResponse?.rangeFacets) {
      this.rangeFacets = [];
      setTimeout(() => {
        this.rangeFacets = jsonResponse.rangeFacets;
      }, 0);
    }

    if (filterUpdate && !append && jsonResponse?.rangeFacets) {
      this.rangeFacets = jsonResponse.rangeFacets;
    }

    if (jsonResponse?.numberOfResults) {
      this.numberOfResults = jsonResponse?.numberOfResults;
    }

    if (jsonResponse?.totalNumberOfResultsMatchingQuery) {
      this.totalNumberOfResults =
        jsonResponse?.totalNumberOfResultsMatchingQuery;
    }

    if (jsonResponse?.queryId) {
      const itemsComponent = this._slottedItems;
      itemsComponent.setAttribute("trackingid", jsonResponse?.queryId);
      itemsComponent.setAttribute("sort", jsonResponse?.sort);
    }

    this._loading = false;
    this.page = page;

    if (
      jsonResponse?.numberOfResults < jsonResponse?.pageSize ||
      page >= jsonResponse?.maxPageCount
    ) {
      this._end = true;
    }
  }

  getCheckedFacets() {
    let facets = this.categories.flatMap((categories) => categories.facets);
    let children = facets.flatMap((facet) => facet.children);
    let allFacets = [...facets, ...children];
    let params = Array.from(this.searchParams.entries());
    let result = [];

    for (const [key, value] of params) {
      if (isInParamWhiteList(key)) continue;

      const facet = allFacets.find(
        (facet) =>
          facet?.attribute.name === key && facet?.attribute.value === value
      );

      if (facet) {
        result.push(facet);
      }
    }

    return result;
  }

  combineSearchAndFacets(categories) {
    let facets = categories.flatMap((categories) => categories.facets);
    let children = facets.flatMap((facet) => facet.children);
    let allFacets = [...facets, ...children];
    let params = Array.from(this.searchParams.entries());

    for (const [key, value] of params) {
      if (isInParamWhiteList(key)) continue;

      const facet = allFacets.find(
        (facet) =>
          facet?.attribute.name === key && facet?.attribute.value === value
      );

      const queryParameter = this.queryParameters.find(
        (query) => query.key === key && query.value === value
      );

      if (facet) {
        facet.checked = true;
      } else {
        if (!queryParameter) {
          this.updateSearchQuery(key, value, false);
          this._updateQueryHistory();
        }
      }
    }
  }

  updateFilter(facetList) {
    for (const { name, value, checked } of facetList) {
      this.updateSearchQuery(name, value, checked);
    }
    this._updateQueryHistory();
    trackFilter(this.getCheckedFacets());

    this._end = false;
    this.search({
      append: false,
      page: 0,
      filterUpdate: true,
      resetRangeFacets: false,
    });
  }

  resetFilter() {
    const params = Array.from(this.searchParams.keys());
    for (const key of params) {
      if (key !== "query" && key !== "q") {
        this.searchParams.delete(key);
      }
    }
    this._updateQueryHistory();

    this._end = false;
    this.search({
      append: false,
      page: 0,
      filterUpdate: false,
      resetRangeFacets: true,
    });
  }

  _updateSort = (sortOption) => {
    this.searchParams.delete(sortOption.detail.key);
    this.searchParams.append(sortOption.detail.key, sortOption.detail.value);

    this._updateQueryHistory();
    this._end = false;
    this.search({
      append: false,
      page: 0,
      filterUpdate: false,
      resetRangeFacets: false,
    });
  };

  updateStockFilter(newValue) {
    this.useStockFilter = newValue;
    this.updateSearchQuery(
      "kom_availabilityStatus",
      "Stocked",
      this.useStockFilter
    );
    this._updateQueryHistory();
    this.search({
      append: false,
      page: 0,
      filterUpdate: true,
      resetRangeFacets: false,
    });
  }

  _setListMode() {
    const valueFromUrl = this.searchParams.get(LIST_VIEW.KEY);
    const valueFromLocalStorage = localStorage.getItem(LIST_VIEW.KEY);

    if (valueFromUrl) {
      this.layoutChange(valueFromUrl === LIST_VIEW.OPTIONS.GRID, true);
    } else if (valueFromLocalStorage) {
      this.layoutChange(valueFromLocalStorage === LIST_VIEW.OPTIONS.GRID, true);
    } else {
      this.layoutChange(false, true);
    }
  }

  layoutChange(useGrid, omitLocalStorageUpdate = false) {
    let slottedItems = this._slottedItems;

    this.useGrid = useGrid;
    this.searchParams.set(
      LIST_VIEW.KEY,
      useGrid ? LIST_VIEW.OPTIONS.GRID : LIST_VIEW.OPTIONS.LIST
    );
    this._updateQueryHistory();

    if (!omitLocalStorageUpdate) {
      localStorage.setItem(
        LIST_VIEW.KEY,
        useGrid ? LIST_VIEW.OPTIONS.GRID : LIST_VIEW.OPTIONS.LIST
      );
    }

    if (slottedItems) {
      slottedItems.classList.toggle("list", !useGrid);
      slottedItems.layout = useGrid ? 'grid' : 'list';
    }
  }

  get _slottedItems() {
    const slot = this.shadowRoot.querySelector('slot[name="items"]');
    const slotElements = slot.assignedElements({ flatten: true });
    return slotElements[0]?.firstElementChild;
  }

  handleSlotchange(e) {
    const childNodes = e.target.assignedNodes({ flatten: true });

    if (childNodes.length >= 1) {
      this._cssGridTemplate = this._cssGridTemplate
        ? `${this._cssGridTemplate}|${e.target.getAttribute("name")}`
        : e.target.getAttribute("name");
      this.requestUpdate();
    }
  }

  handleCategorySelect(categoryId) {
    window.location.href = `/v2/category/${categoryId}?${this.searchParams.toString()}`;
  }

  handleSearchFilterRemoveTagClicked(e) {
    const clickedFacet = e.detail.facet;

    const updateFacet = (facets) => {
      for (let facet of facets) {
        if (
          facet.attribute.name === clickedFacet.attribute.name &&
          facet.attribute.value === clickedFacet.attribute.value
        ) {
          facet.checked = false;
          this.updateSearchQuery(
            facet.attribute.name,
            facet.attribute.value,
            false
          );
          return true; // Facet found and updated
        }
      }
      return false; // Facet not found in this branch
    };

    // Search and update the facet in the categories array
    this.categories = this.categories.map((category) => {
      if (category.facets) {
        updateFacet(category.facets);
      }
      return category;
    });

    // Update query params and rerun search
    this._updateQueryHistory();
    this._end = false;
    this.search({
      append: false,
      page: 0,
      filterUpdate: true,
      resetRangeFacets: false,
    });
  }

  render() {
    const gridTemplate = choose(this._cssGridTemplate, [
      [
        GRID_VARIANTS.DEFAULT,
        () => html`<style>
          :host {
            grid-template-rows: auto auto auto 1fr 68px;
            gap: 0px 0px;
            grid-template-areas:
              "filter bar"
              "filter filter-tags"
              "filter banner"
              "filter items"
              "filter loader";
          }
        </style>`,
      ],
      [
        GRID_VARIANTS.CMS,
        () => html`<style>
          :host {
            grid-template-rows: auto auto auto auto 1fr 68px;
            gap: 0px 0px;
            grid-template-areas:
              "cms-content cms-content"
              "filter bar"
              "filter filter-tags"
              "filter banner"
              "filter items"
              "filter loader";
          }
        </style>`,
      ],
      [
        GRID_VARIANTS.RECOMMENDATIONS,
        () => html`<style>
          :host {
            grid-template-rows: auto auto auto auto 1fr 68px;
            gap: 0px 0px;
            grid-template-areas:
              "recommendations recommendations"
              "filter bar"
              "filter filter-tags"
              "filter banner"
              "filter items"
              "filter loader";

            @media (max-width: 599px) {
              grid-template-rows: auto auto 1fr 68px;
              grid-template-areas:
                "filter bar"
                "filter banner"
                "filter items"
                "filter loader";
            }
          }
        </style>`,
      ],
      [
        GRID_VARIANTS.CMS_AND_RECOMMENDATIONS,
        () => html`<style>
          :host {
            grid-template-rows: auto auto auto auto auto 1fr 68px;
            gap: 0px 0px;
            grid-template-areas:
              "cms-content cms-content"
              "recommendations recommendations"
              "filter bar"
              "filter filter-tags"
              "filter banner"
              "filter items"
              "filter loader";

            @media (max-width: 599px) {
              grid-template-rows: auto auto auto 1fr 68px;
              grid-template-areas:
                "cms-content cms-content"
                "filter bar"
                "filter banner"
                "filter items"
                "filter loader";
            }
          }
        </style>`,
      ],
    ]);

    return html`
      ${gridTemplate}
      ${this.numberOfResults === 0
        ? html`<div class="no-results-wrapper">
            ${strings.searchZeroResultsPartOne} <b>"${this.searchText}"</b>
            <div class="no-results-message">
              ${strings.searchZeroResultsPartTwo}
            </div>
          </div>`
        : html`<search-filter
              .categories=${this.categories}
              .rangeFacets=${this.rangeFacets}
              .handleUpdate="${(list) => this.updateFilter(list)}"
              .handleCategorySelect="${(categoryId) =>
                this.handleCategorySelect(categoryId)}"
              .handleReset=${() => this.resetFilter()}
              .sortOptions=${this.sortOptions}
              .numberOfResults=${this.totalNumberOfResults}
              .updateStockFilter=${(v) => this.updateStockFilter(v)}
              .useStockFilter=${this.useStockFilter}
              .pageType=${this.pageType}
            ></search-filter>
            <search-bar
              part="search-bar"
              .useGrid=${this.useGrid}
              .sortOptions=${this.sortOptions}
              .searchText=${this.searchText}
              .numberOfResults=${this.totalNumberOfResults}
              .updateStockFilter=${(v) => this.updateStockFilter(v)}
              .useStockFilter=${this.useStockFilter}
              .layoutUpdate=${(useGrid) => this.layoutChange(useGrid)}
              .pageType=${this.pageType}
            ></search-bar>
            <search-filter-tags
              part="search-filter-tags"
              .categories=${this.categories}
              @remove-tag-clicked=${this.handleSearchFilterRemoveTagClicked}
              .handleReset=${() => this.resetFilter()}
            >
              ></search-filter-tags
            >
            <search-banner-list part="search-banners" .banners=${this.banners}>
            </search-banner-list>
            <slot
              name="cms-content"
              @slotchange=${this.handleSlotchange}
            ></slot>
            <slot
              name="recommendations"
              @slotchange=${this.handleSlotchange}
            ></slot>
            <slot name="items"></slot>
            <div class="loader-wrapper">
              ${this._loading ? html`<span class="loader"></span>` : ""}
            </div>`}
    `;
  }

  static styles = css`
    :host {
      min-height: 100vh;
      display: grid;
      grid-template-columns: auto 1fr;

      @media (max-width: 768px) {
        grid-template-columns: auto 1fr;
      }

      @media (min-width: 769px) and (max-width: 1300px) {
        grid-template-columns: auto 1fr;
      }

      @media (min-width: 1301px) and (max-width: 1728px) {
        grid-template-columns: 240px calc(100% - 240px);
      }

      @media (min-width: 1728px) {
        grid-template-columns: 320px calc(100% - 320px);
      }
    }

    .loader-wrapper {
      grid-area: loader;
      display: flex;
      justify-content: center;
      padding: 10px;
    }

    search-filter {
      grid-area: filter;
    }

    search-filter-tags {
      grid-area: filter-tags;
    }

    search-bar {
      grid-area: bar;
    }

    search-banner-list {
      grid-area: banner;
    }

    slot[name="items"]::slotted(*) {
      grid-area: items;
    }

    slot[name="cms-content"]::slotted(*) {
      grid-area: cms-content;
    }

    slot[name="recommendations"]::slotted(*) {
      grid-area: recommendations;
    }

    .loader {
      width: 48px;
      height: 48px;
      border: 5px solid #2a41bb;
      border-bottom-color: transparent;
      border-radius: 50%;
      display: inline-block;
      box-sizing: border-box;
      animation: rotation 1s linear infinite;
    }

    @keyframes rotation {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .no-results-wrapper {
      font-size: 32px;
      font-weight: 500;
    }

    .no-results-message {
      font-size: 18px;
      font-weight: 400;
      margin-top: 8px;
    }

    @media (max-width: 768px) {
      .no-results-wrapper {
        font-size: 28px;
        margin-left: 8px;
      }

      .no-results-message {
        font-size: 16px;
      }
    }
  `;
}
customElements.define("search-container", SearchContainer);