import { css, html, LitElement } from "lit";

export class H5 extends LitElement {
  constructor() {
    super();
  }

  render() {
    return html`<h5><slot></slot></h5>`;
  }

  static styles = css`
    h5 {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      padding: 0;
    }
  `;
}
