import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { ifDefined } from "lit/directives/if-defined.js";

export const BUTTON_SIZES = {
  SMALL: "small",
  LARGE: "large",
};

export class SecondaryButton extends LitElement {
  static properties = {
    size: { type: String },
    type: { type: String },
    form: { type: String },
    disabled: { type: Boolean },
    onClick: { type: Function },
  };

  constructor() {
    super();
    this.onClick = () => {};
    this.disabled = false;
  }

  render() {
    return html`<button
      class=${classMap({
        small: this.size === BUTTON_SIZES.SMALL,
        large: this.size === BUTTON_SIZES.LARGE,
      })}
      type=${ifDefined(this.type)}
      form=${ifDefined(this.form)}
      ?disabled=${this.disabled}
      @click=${(event) => this.onClick(event)}
    >
      <slot></slot>
    </button>`;
  }

  static styles = css`
    :host {
      display: flex;
    }

    button {
      font-family: "Roboto", sans-serif;
      width: 100%;
      min-width: 64px;
      border-radius: 4px;
      padding: 8px 24px;
      background-color: #1f1a49;
      color: #fff;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.25em;
      margin: 2px;
      border: 2px solid transparent;
      cursor: pointer;
      transition: border-color 0.2s ease, background-color 0.2s ease,
        box-shadow 0.2s ease, color 0.2s ease;
    }

    button.small {
      font-size: 14px;
      line-height: 1.15em;
      padding: 8px 16px;
    }

    button.large {
      padding: 12px 16px;
    }

    button:hover {
      background-color: #625f80;
    }

    button:focus {
      outline: none;
      border-color: #fff;
      box-shadow: #222222 0px 0px 0px 2px;
      background-color: #625f80;
    }

    button:active:not(:disabled) {
      outline: none;
      border-color: #fff;
      box-shadow: #222222 0px 0px 0px 2px;
      background-color: #1d1843;
    }

    button:disabled {
      background-color: #ebebeb;
      color: #737373;
      cursor: not-allowed;
    }
  `;
}
