import { LitElement, html, css } from "lit";
import { modalStyles } from "./modal-container.styles.js";
import strings from "../../utils/strings.js";

class ModalServices extends LitElement {
  static properties = {
    services: { type: Array },
    servicesState: { type: Array },
    selectAccessory: {
      type: Function,
    },
    openAccessoriesModal: {
      type: Function,
    },
  };

  render() {
    return html`
      <div class="modal-content">
        <div class="modal-content-info">
          <h2>
            ${strings[
              "product.box.config.service.additional.services.service.title"
            ]}
          </h2>
          <p>
            ${strings[
              "product.box.config.service.additional.services.service.description"
            ]}
          </p>
        </div>
        ${this.services.map(
          (service) =>
            html`<config-service-option
              .service=${service}
              .selectedAccessoryId=${this.servicesState?._state[
                service.serviceGroupId
              ]?.selectedAccessoryId}
              .defaultAccessoryId=${this.servicesState?._state[
                service.serviceGroupId
              ].defaultAccessoryId}
              .selectDefaultAccessory=${({ accessoryId, accessoryPrice }) =>
                this.selectAccessory({
                  serviceGroupId: service.serviceGroupId,
                  accessoryId,
                  accessoryPrice,
                })}
              .openAccessoriesModal=${(selectedServiceGroupId) =>
                this.openAccessoriesModal(selectedServiceGroupId)}
            ></config-service-option>`
        )}
      </div>
    `;
  }

  static styles = [modalStyles];
}

customElements.define("modal-services", ModalServices);
