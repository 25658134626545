import { html, css } from "lit";
import { stars } from "../../utils/svgProvider";
import { createStarList } from "../../utils";

export const ratingTemplate = (rating, strings) =>
  html`
    <div class="rating">
      <span class="stars"
        >${createStarList(rating.reviewRating).map((i) => html`${stars[i]}`)}
      </span>
      <span>
        (${rating.reviewCount}<span class="review-label"
          >&nbsp;${strings.reviews}</span
        >)
      </span>
    </div>
  `;

export const ratingStyles = css`
  .rating {
    container: rating / inline-size;
    grid-area: rating;
    align-self: start;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    color: #565656;

    .stars {
      display: inline-flex;
    }
  }

  .rating svg {
    margin: 0 -2px;
    height: 100%;
  }

  @container rating (max-width: 210px) {
    .review-label {
      display: none;
    }

    .rating svg {
      width: 20px;
    }
  }
`;
