import { LitElement, html, css } from "lit";
import { addToCart } from "../../utils/addToCart";
import strings from "../../utils/strings";

const INPUT_INITIAL_VALUE = 1;
const POSITIVE_INT_ONLY_REGEX =  /^\+?(0|[1-9]\d*)$/;

class BuyButtons extends LitElement {
	static properties = {
		product: { type: Object },
		quantity: { type: Number },
		buycallback: {
			type: Function,
		},
		index: { type: Number },
	};

	constructor() {
		super();
		this.products = {};
		this.quantity = INPUT_INITIAL_VALUE;
	}

	setQuantity = (count) => {
		if (count < INPUT_INITIAL_VALUE) {
			return;
		}

		this.quantity = count;
	};

	buyProduct() {
		const input = this.shadowRoot.getElementById(`buy-button-input-${this.index}`);
		const { value } = input;

		if(!POSITIVE_INT_ONLY_REGEX.test(value)){
			input.value = INPUT_INITIAL_VALUE;
			this.setQuantity(INPUT_INITIAL_VALUE);
		} else {
			this.setQuantity(value);
		}

		addToCart(this.product, this.quantity, this.buycallback, this.index);
	}

	render() {
		return html`<div class="quantity">
				<button class="subtract" @click=${() => this.setQuantity(this.quantity - 1)}></button>
				<input min="0" 
					   id="buy-button-input-${this.index}"
					   name="quantity" 
					   .value=${this.quantity} 
					   type="number"
				/>
				<button class="add" @click=${() => this.setQuantity(this.quantity + 1)}></button>
			</div>
			<button class="button" data-store="itegra" @click="${() => this.buyProduct()}">
				${strings.addToCart}
			</button>`;
	}

	static styles = css`
		:host {
			--color-primary: #f8b912;
			--color-primary-hover: #fcd97c;
			--color-primary-contrast: #222;
			display: flex;
			gap: 5px;
			flex-wrap: wrap;
		}

		:host * {
			pointer-events: initial;
			cursor: pointer;
		}

		.button,
		.button:active {
			font-size: 14px;
			min-width: 64px;
			padding: 8px 24px;
			line-height: 24px;
			background-color: var(--color-primary);
			color: var(--color-primary-contrast);
			border-radius: 4px;
			border: none;
			cursor: pointer;
			flex: 1 1 auto;

			&:hover:not(:active) {
				background-color: var(--color-primary-hover);
			}

			&[data-store="itegra"] {
				--color-primary: #dc7137;
				--color-primary-hover: #e18f65;
				--color-primary-contrast: #fff;
			}
		}

		.quantity {
			background-color: #fff;
			border: 1px solid #ddd;
			border-radius: 4px;
			display: inline-flex;
			align-items: center;
			justify-content: space-evenly;
			width: 98px;
			height: 38px;

			& input {
				-webkit-appearance: textfield;
				-moz-appearance: textfield;
				appearance: textfield;

				font-family: sans-serif;
				text-align: center;
				border: none;
				width: 35px;
				height: 100%;
				font-size: 16px;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
				}
			}

			& * {
				box-sizing: border-box;
			}

			& button {
				width: 100%;
				height: 100%;
				font-size: 20px;
				font-weight: 400;

				align-items: center;
				justify-content: center;

				outline: none;
				border: none;
				background-color: transparent;
				cursor: pointer;
				margin: 0;
				position: relative;
				-webkit-appearance: none;

				background-repeat: no-repeat;
				background-size: 16px;

				&.subtract {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 5 15 L 5 17 L 27 17 L 27 15 Z'/%3E%3C/svg%3E");
					background-position: 80% 50%;
				}

				&.add {
					background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'%3E%3Cpath d='M 15 5 L 15 15 L 5 15 L 5 17 L 15 17 L 15 27 L 17 27 L 17 17 L 27 17 L 27 15 L 17 15 L 17 5 Z'/%3E%3C/svg%3E");
					background-position: 30% 50%;
				}
			}
		}
	`;
}
customElements.define("buy-button", BuyButtons);
