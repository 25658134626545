import { LitElement, html } from "lit";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

import { modalStyles } from "./modal-container.styles.js";

class ModalServiceDetails extends LitElement {
  static properties = {
    selectedService: { type: Object },
  };

  render() {
    return html`
      <div class="modal-content">
        <div class="modal-content-info">
          <h2
            data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.name`}
          >
            ${this.selectedService.name}
          </h2>
          <p>${unsafeHTML(this.selectedService.informationText)}</p>
        </div>
      </div>
    `;
  }

  static styles = [modalStyles];
}

customElements.define("modal-service-details", ModalServiceDetails);
