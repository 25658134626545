import { css, html, LitElement } from "lit";

export class H2 extends LitElement {
  constructor() {
    super();
  }

  render() {
    return html`<h2><slot></slot></h2>`;
  }

  static styles = css`
    h2 {
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 32px;
      font-weight: 600;
      line-height: 40px;
      margin: 0;
      padding: 0;
    }
  `;
}
