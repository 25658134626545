import { LitElement, html, css } from "lit";
import { sliderStyles } from "./range-slider.styles";
import { debounce } from "../../utils";
import { EVENTS, triggerCustomEvent } from "../../utils/customEvents.js";

class RangeSlider extends LitElement {
	static properties = {
		name: { type: String },
		currentMinValue: { type: Number },
		currentMaxValue: { type: Number },
		totalMinValue: { type: Number },
		totalMaxValue: { type: Number },
	};

	constructor() {
		super();
		this.currentMinValue = 0;
		this.currentMaxValue = 0;
		this.totalMinValue = 0;
		this.totalMaxValue = 0;
		this.debounce = debounce(() => this.handleDelayedAction());
	}

	handleDelayedAction() {
		triggerCustomEvent(EVENTS.SORT_SEARCH_RESULTS, {
			key: this.name,
			value: `${this.currentMinValue} TO ${this.currentMaxValue}`,
		});
	}

	updateMin(e) {
		let newMin = parseInt(e.target.value);

		if (newMin > this.currentMaxValue) {
			newMin = this.currentMaxValue;
		}
		this.currentMinValue = newMin;
		this.debounce();
	}

	updateMax(e) {
		let newMax = parseInt(e.target.value);

		if (newMax < this.currentMinValue) {
			newMax = this.currentMinValue;
		}
		this.currentMaxValue = newMax;
		this.debounce();
	}

	updateSlider(e) {
		const minSlider = this.shadowRoot.querySelector(".range-min");
		const maxSlider = this.shadowRoot.querySelector(".range-max");
		const newMinValue = parseInt(minSlider.value);
		const newMaxValue = parseInt(maxSlider.value);
		//Make sliders to not overlap with each other - value 0.1
		const range = (this.totalMaxValue - this.totalMinValue) * 0.1;

		if (newMinValue >= newMaxValue - range) {
			minSlider.value = newMaxValue - range;
		}
		if (newMaxValue <= newMinValue + range) {
			maxSlider.value = newMinValue + range;
		}
		this.currentMinValue = parseInt(minSlider.value);
		this.currentMaxValue = parseInt(maxSlider.value);

		this.debounce();
	}

	render() {
		const start = this.currentMinValue - this.totalMinValue;
		const range = this.currentMaxValue - this.currentMinValue;
		const totalRange = this.totalMaxValue - this.totalMinValue;
		const progressWidth = (range / totalRange) * 100;
		const progressPosition = (start / totalRange) * 100;
		
		return html`
			<div class="range-facet">
				<div class="facet-heading">${this.name}</div>
				<div class="manual-input">
					<input
						type="number"
						class="field"
						step="100"
						min=${this.totalMinValue}
						max=${this.totalMaxValue}
						.value="${this.currentMinValue}"
						@input="${(e) => this.updateMin(e)}"
					/>
					<div class="separator">-</div>
					<input
						type="number"
						class="field"
						step="100"
						min=${this.totalMinValue}
						max=${this.totalMaxValue}
						.value="${this.currentMaxValue}"
						@input="${(e) => this.updateMax(e)}"
					/>
				</div>
				<div class="slider">
					<div
						class="progress"
						style="width: ${progressWidth}%; left: ${progressPosition}%;"
					></div>
				</div>
				<div class="range-input">
					<input
						type="range"
						class="range-min"
						min=${this.totalMinValue}
						max=${this.totalMaxValue}
						.value=${this.currentMinValue}
						@input=${this.updateSlider}
					/>
					<input
						type="range"
						class="range-max"
						min=${this.totalMinValue}
						max=${this.totalMaxValue}
						.value=${this.currentMaxValue}
						@input=${this.updateSlider}
					/>
				</div>
			</div>
		`;
	}

	static styles = sliderStyles;
}
customElements.define("range-slider", RangeSlider);
