import { css } from "lit";

export const modalStyles = css`
  :host {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5px;
  }

  .modal-overlay {
    position: fixed;
    z-index: 1001;
    display: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s;
    opacity: 0;
  }

  .modal-overlay.is-active {
    display: block;
    opacity: 1;
  }

  .modal-wrapper {
    position: absolute;
    z-index: 1001;
    width: 600px;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    transform: translateY(100%);
    overflow-x: hidden;
  }

  .modal-wrapper:focus {
    outline: none;
  }

  .modal-wrapper.is-active {
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 8px;
    visibility: visible;
    width: 600px;
    transform: translateY(-50%);
    top: calc(25vh - 10%);
    left: calc(50% - 600px / 2);

    @media (max-width: 991px) {
      position: fixed;
      height: 100%;
      width: 100vw;
      top: 0;
      transform: translateY(0%);
      border-radius: 0;
      border: none;
      left: 0;
      right: 0;
    }

    @media (max-width: 600px) {
      position: fixed;
      height: 100%;
      width: 100vw;
      top: 0;
      transform: translateY(0%);
      border-radius: 0;
      border: none;
      left: 0;
      right: 0;
    }
  }

  .modal-content {
    padding: 24px;
    margin: 65px 0;
    max-height: 570px;
    height: calc(100% - 170px);
    width: 100%;
    overflow-y: auto;

    @media (max-width: 600px) {
      max-height: 100%;
      height: calc(100% - 170px);
    }
  }

  .modal-content-info {
    padding-bottom: 16px;
  }

  .modal-content-info h2 {
    font-size: 20px;
    font-weight: 500;
    color: #222;
    margin: 0 0 16px 0;
  }

  .modal-content-info p:not(:empty) {
    font-size: 14px;
    font-weight: 400;
    color: #565656;
    margin: 0;
    margin-bottom: 32px;
  }

  .modal-content-info span {
    font-size: 14px;
    font-weight: 400;
    color: #565656;
  }

  .modal-content-info span strong {
    font-size: 14px;
    font-weight: 500;
    color: #222;
  }
`;
