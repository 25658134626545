import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";
import strings from "../../utils/strings.js";
import { VALIDATION_RULES } from "./_config.js";

class Description extends LitElement {
  static properties = {
    _counter: { state: true },
    disabled: { type: Boolean },
    value: { type: String },
    error: { type: String },
    onChange: { type: Function },
    onFocus: { type: Function },
    onFocusOut: { type: Function },
  };

  constructor() {
    super();
    this._counter = VALIDATION_RULES.description.minLength;
    this.error = "";
    this.disabled = false;
    this.onChange = () => {};
    this.onFocus = () => {};
    this.onFocusOut = () => {};
  }

  updated(changedProperties) {
    if (changedProperties.has("value")) {
      this._setCounter(this.value?.length);
    }
  }

  _setCounter(count = 0) {
    this._counter =
      count < VALIDATION_RULES.description.minLength
        ? VALIDATION_RULES.description.minLength - count
        : 0;
  }

  render() {
    return html`
      <div class="container">
        <komplett-label bold="true" required="true">
          ${strings["product.addreview.description.label"]}
        </komplett-label>
        <komplett-textarea
          ?disabled=${this.disabled}
          value=${this.value}
          placeholder="${strings["product.addreview.description.placeholder"]}"
          .onChange=${(event) => this.onChange(event.currentTarget.value)}
          .onKeyUp=${(event) =>
            this._setCounter(event.currentTarget.value.length)}
          .onFocus=${() => this.onFocus()}
          .onFocusOut=${() => this.onFocusOut()}
        ></komplett-textarea>
        <div
          class=${classMap({
            row: true,
            "has-error": this.error,
          })}
        >
          <komplett-input-error text=${this.error}></komplett-input-error>
          <div class="counter">
            ${strings["product.addreview.info.minimumCharactersRemaining"]}:
            ${this._counter}
          </div>
        </div>
      </div>
    `;
  }

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
    }

    .row {
      margin-top: 12px;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
      min-height: 18px;
      position: relative;
    }

    .counter {
      align-self: flex-end;
      display: flex;
      color: #565656;
      font-size: 14px;
      line-height: 18px;
      position: absolute;
      opacity: 1;
      right: 0;
      transition: opacity 0.2s ease;
    }

    @media (max-width: 500px) {
      .row.has-error .counter {
        opacity: 0;
      }
    }

    komplett-textarea {
      width: 100%;
      margin-top: 12px;
      --komplett-textarea-width: 100%;
      --komplett-textarea-height: 210px;
    }
  `;
}

customElements.define("komplett-add-review-description", Description);
