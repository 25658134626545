import { LitElement, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { live } from "lit/directives/live.js";
import { chevron, closeX } from "../../utils/svgProvider";
import { EVENTS } from "../../utils/customEvents";
import { filterStyles } from "./search-filter.styles";
import { checkboxStyles } from "./checkbox.styles.js";
import { FACET_TYPES, PAGE_TYPES } from "../search-container.utils.js";
import strings from "../../utils/strings.js";

import "../rangeSlider/range-sliders.js";
import "../searchSort/search-sort.js";
import "../stock-checkbox.js";

class SearchFilter extends LitElement {
  static properties = {
    categories: { type: Array },
    rangeFacets: { type: Array },
    handleUpdate: { type: Function },
    handleReset: { type: Function },
    handleCategorySelect: { type: Function },
    numberOfResults: { type: Number },
    accordionStates: { type: Object },
    pageType: { type: String },
    _showMoreStates: { type: Object },
    sortOptions: { type: Array },
    isSlideinAndMobileFiltersActive: { type: Boolean },
    updateStockFilter: {
      type: Function
    },
    useStockFilter: { type: Boolean }
  };

  constructor() {
    super();
    this.categories = [];
    this.accordionStates = {};
    this.isSlideinAndMobileFiltersActive = false;
    this.pageType = this.pageType;
    this.sortOptions = []
  }

  connectedCallback() {
    super.connectedCallback();
    document.addEventListener(EVENTS.TOGGLE_FILTER, () => this.toggleFilter());
  }

  disconnectedCallback() {
    document.removeEventListener(EVENTS.TOGGLE_FILTER, () => this.toggleFilter());
    super.disconnectedCallback();
  }

  toggleFilter() {
    this.isSlideinAndMobileFiltersActive = !this.isSlideinAndMobileFiltersActive;
    this.renderRoot.querySelector(".filter-wrapper").classList.toggle("show");
    this.handleScrollLock();
  }

  //If on facet change, the search triggers a new list of facets this wont work.
  firstUpdated() {
    this.accordionStates = this.initializeAccordionStates();
  }

  initializeAccordionStates() {
    const states = {};
    this.categories.forEach((facet, index) => {
      const isCategoryFacet = facet.facetType === FACET_TYPES.CATEGORY;
      const isManufacturerFacet = facet.facetType === FACET_TYPES.MANUFACTURER;

      const identifier = isCategoryFacet ? facet.id : facet.name;

      states[identifier] = isCategoryFacet || isManufacturerFacet || this.hasSelectedChildren(facet) || index < 3;
    });

    states["sortRules"] = true;

    return states;
  }

  handleScrollLock() {
    if (this.isSlideinAndMobileFiltersActive) {
      document.documentElement.classList.add("scroll-lock-xs");
      document.body.style.overflow = "hidden";
    } else {
      document.documentElement.classList.remove("scroll-lock-xs");
      document.body.style.overflow = "visible";
    }
  }

  toggleAccordion(id) {
    this.accordionStates = {
      ...this.accordionStates,
      [id]: !this.accordionStates[id],
    };
  }

  toggleShowMore(id) {
    this._showMoreStates = {
      ...this._showMoreStates,
      [id]: !this._showMoreStates?.[id],
    };
  }

  renderFilterStrings = (text, numberOfResults) => {
    return text.replace("{numberofresults}", numberOfResults);
  };

  shouldShowChildFacets(facets) {
    return facets.length === 1 && facets[0]?.checked && facets[0]?.children?.length > 0;
  }

  getSelectedChildren(facet) {
    return facet?.facets !== undefined
        ? facet?.facets.filter((c) => c.checked)
        : [];
  }

  hasSelectedChildren(facet) {
    let selectedChildren = this.getSelectedChildren(facet);
    return selectedChildren.length > 0;
  }

  handleFacetAndChildrenReset(facet) {
    const checkedChildren =
      facet?.children !== undefined
        ? facet?.children
            .filter((c) => c.checked)
            .map((c) => ({
              name: c?.attribute.name,
              value: c?.attribute.value,
              checked: false,
            }))
        : [];

    this.handleUpdate([
      ...checkedChildren,
      {
        name: facet?.attribute.name,
        value: facet?.attribute.value,
        checked: false,
      },
    ]);
  }

  getFacetListLength(showChildren, category) {
    return showChildren ? category.facets[0]?.children.length : category.facets.length;
  }

  _getChildFacetsToRender(category) {
    const output =
      category.facetType === FACET_TYPES.CATEGORY && category.facets.length <= 1
        ? category.facets[0]?.children
          ? category.facets[0]?.children
          : []
        : this.shouldShowChildFacets(category.facets)
        ? category.facets[0]?.children
        : category.facets;

    return output;
  }

  _handleParentCategorySelect(facet) {
    this.handleUpdate([
      {
        name: facet?.attribute.name,
        value: facet?.parentCategoryId,
        checked: true,
      },
      {
        name: facet?.attribute.name,
        value: facet?.attribute.value,
        checked: false,
      },
    ]);
  }

  getSelectedFacets(category) {
    return this._getChildFacetsToRender(category)
        .filter(facet => facet.checked)
        .map(facet => facet.name);
  }

  render() {
    return html`
      <div
        @click=${() => {
          if (this.isSlideinAndMobileFiltersActive) {
            this.toggleFilter();
          }
        }}
        class=${classMap({
          "is-active": this.isSlideinAndMobileFiltersActive,
          "filter-overlay": true,
        })}
      ></div>
      <div class="filter-wrapper">
        <div class="filter-header">
          <button class="reset-filter" @click=${() => this.handleReset()}>${strings.searchFilterReset}</button>
          <h2>${strings.searchFilterHeading}</h2>
          <button class="close-filter" @click=${this.toggleFilter}>${closeX}</button>
        </div>
        <div class=${(this.accordionStates["sortRules"] ? "open" : "") + " sorting-facet"}>
          <button class="category-name" @click=${() => this.toggleAccordion("sortRules")}>${strings.searchFilterSortingHeadline} ${chevron}</button>
          <div class="sort-wrapper">
            <search-sort .mobile=${true} .sortOptions=${this.sortOptions}></search-sort>
          </div>
        </div>
        <div class="stock-container">
          <stock-checkbox .updateStockFilter=${this.updateStockFilter} .useStockFilter=${this.useStockFilter}></stock-checkbox>
        </div>
        ${this.categories.slice(0, 20).map((category, index) => {
          const showChildren = this.shouldShowChildFacets(category.facets);

          const isCategoryFacet = category.facetType === FACET_TYPES.CATEGORY;

          const showParentName = isCategoryFacet && category.facets.length <= 1;

          const isRoot = isCategoryFacet && category.facets[0]?.categoryLevel === 0;

          const identifier = isCategoryFacet ? category.id : category.name;

          const selectedFacets = category.facetType !== FACET_TYPES.CATEGORY ? this.getSelectedFacets(category) : [];

          return category.facets.length > 0 ? html`
            <div class=${this.accordionStates[identifier] ? "open" : ""}>
              <button class="category-name" @click=${() => this.toggleAccordion(identifier)}>${category.displayName} ${chevron}</button>
              ${selectedFacets.length > 0
                  ? html`<p class="selected-tags">${selectedFacets.join(', ')}</p>`
                  : nothing}
              ${html`
                <div class="facets-wrapper" style="--max-height: ${this.getFacetListLength(showChildren, category) * 40 + 10}px">
                  <div class="facets">
                    ${showParentName
                      ? html`<button
                            class="show-top-facets"
                            @click=${() =>
                              isCategoryFacet && this.pageType === PAGE_TYPES.CATEGORY
                                ? this.handleCategorySelect(category.facets[0].parentCategoryId)
                                : isCategoryFacet ? this._handleParentCategorySelect(category.facets[0]) : this.handleFacetAndChildrenReset(category.facets[0])}
                          >
                            ${chevron} ${isRoot ? strings.searchFilterShowAllCategories : category.facets[0]?.parentCategoryName}
                          </button>
                        <div class="parent-name-container">
                          <div class="parent-name">${category.facets[0]?.name}</div>
                          <span class="count">${category.facets[0]?.productCount}</span>
                        </div>`
                      : nothing}
                    <ul
                      style=${this.pageType === PAGE_TYPES.CATEGORY && isCategoryFacet ? "max-height: 100%" : ""}
                      class=${this._showMoreStates?.[identifier] ? "open" : ""}
                    >
                      ${this._getChildFacetsToRender(category)
                        .map(
                          (facet) => html`
                            <li class="facet-container">
                              ${isCategoryFacet && this.pageType === PAGE_TYPES.CATEGORY
                                ? html`<a class="facet-category-links" href="" @click=${(e) => {
                                        e.preventDefault();
                                        this.handleCategorySelect(facet.categoryId);
                                  }}>${facet.name}${chevron}</a>`
                                : html`<label class="facet-label">
                                    <input
                                      class="checkbox"
                                      type="checkbox"
                                      value=${facet.name}
                                      .checked=${live(facet.checked)}
                                      @change=${(e) =>
                                        this.handleUpdate([
                                          {
                                            name: facet.attribute.name,
                                            value: facet.attribute.value,
                                            checked: e.target.checked,
                                          },
                                        ])}
                                    /><span class="clamp-lines">${facet.name}</span></label
                                  >`}
                              <span class="count">${facet.productCount}</span>
                            </li>
                          `
                        )}
                    </ul>
                    ${this.getFacetListLength(showChildren, category) > 5
                      ? html`<button class="show-more" @click=${() => this.toggleShowMore(identifier)}>
                          ${this._showMoreStates?.[identifier] ? strings.searchFilterFacetsShowLess : strings.searchFilterFacetsShowAll}
                          (${this.getFacetListLength(showChildren, category)})
                        </button>`
                      : nothing}
                  </div>
                </div>
              `}
            </div>
            ${index === 1 ? html`<range-sliders .rangeFacets=${this.rangeFacets}></range-sliders>` : nothing}
          ` : nothing;
        })}
        ${this.isSlideinAndMobileFiltersActive
          ? html` <div class="ipad-mobile-show-results">
              <button class="show-results-button" @click=${this.toggleFilter}>
                ${this.renderFilterStrings(strings.searchFilterShowNumberOfResults, this.numberOfResults)}
              </button>
            </div>`
          : ""}
      </div>
    `;
  }
  static styles = [filterStyles, checkboxStyles];
}
customElements.define("search-filter", SearchFilter);
