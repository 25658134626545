export const VALIDATION_RULES = {
  title: {
    maxLength: 256,
  },
  description: {
    minLength: 50,
    maxLength: 10000,
  },
  name: {
    minLength: 4,
    maxLength: 25,
  },
};
