import { LitElement, html, css } from "lit";
import { closeX } from "../../utils/svgProvider";
import strings from "../../utils/strings.js";

class SearchFilterTags extends LitElement {
    static properties = {
        categories: { type: Array },
        handleReset: { type: Function },
    };

    constructor() {
        super();
    }

    mapSelectedFacets(data) {
        return data
            .filter(category => category.facetType !== "category")
            .flatMap(category =>
                category.facets?.flatMap(facet => [
                    ...(facet.checked ? [facet] : []),
                ]) || []
            );
    }

    handleRemoveTagClick(facet) {
        const event = new CustomEvent('remove-tag-clicked', {
            detail: { facet },
        });
        this.dispatchEvent(event);
    }

    handleRemoveAllClick() {
        this.handleReset();
    }

    render() {
        const selectedFacets = this.mapSelectedFacets(this.categories);
        const hasSelectedFacets = selectedFacets.length > 0;

        this.style.setProperty('--host-padding', hasSelectedFacets ? '24px 0 22px' : '0');

        return html`
            <div class="selected-facets">
                ${selectedFacets.map(facet => html`
                    <div class="button-container" role="group" aria-label="${facet.attribute.value} ${strings.ariaLabelTag}">
                        <span class="button-text">${facet.attribute.value.replace(/^'|'$/g, '')}</span>
                        <button type="button" class="close-button" aria-label="${facet.attribute.value} ${strings.ariaLabelDismissTag}" @click=${() => this.handleRemoveTagClick(facet)}>
                            <span aria-hidden="true">${closeX}</span>
                        </button>
                    </div>
                `)}
                ${hasSelectedFacets ? html`
                    <button class="remove-all-button" aria-label="${strings.ariaLabelDismissAllTags}" @click=${this.handleRemoveAllClick}>
                        ${strings.removeAll}
                    </button>
                ` : ''}
            </div>
        `;
    }

    static styles = css`
        @media (max-width: 599px) {
            :host {
                display: none;
            }
        }

        :host {
            padding: var(--host-padding, 0);
        }

        .selected-facets {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            align-items: center;
        }

        .button-container {
            display: flex;
            align-items: center;
            background-color: white;
            border: 1px solid #1F1A49;
            border-radius: 8px;
            padding: 8px 24px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            cursor: default;
        }

        .button-text {
            color: #333;
            margin-right: 5px;
        }

        .close-button {
            background: none;
            border: none;
            cursor: pointer;
            padding: 0;
            margin-left: 5px;
        }

        .close-button span {
            display: flex;
            align-items: center;
        }

        .close-button svg {
            width: 20px;
            height: 20px;
        }

        .remove-all-button {
            background-color: #1F1A49;
            color: white;
            border: none;
            border-radius: 8px;
            padding: 8px 24px;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
            cursor: pointer;
        }

        .remove-all-button:hover {
            opacity: 0.9;
        }
    `;
}

customElements.define("search-filter-tags", SearchFilterTags);