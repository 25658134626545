import { css, html, LitElement } from "lit";

export class H1 extends LitElement {
  constructor() {
    super();
  }

  render() {
    return html`<h1><slot></slot></h1>`;
  }

  static styles = css`
    h1 {
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 40px;
      font-weight: 600;
      line-height: 48px;
      margin: 0;
      padding: 0;
    }
  `;
}
