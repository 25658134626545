import { LitElement, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { optionStyles } from "../configService/config-service.styles.js";
import { subscribeToStockModalStyles } from "./subscribe-to-stock-modal-container.styles.js";

import "./subscribe-to-stock-modal-header.js";
import "./subscribe-to-stock-modal-subscription.js";

class SubscribeToStockModal extends LitElement {
  static properties = {
    isModalOpen: { type: Boolean },
    onConfirmModal: {
      type: Function,
    },
    onCloseModal: {
      type: Function,
    },
    productId: { type: String },
  };

  constructor() {
    super();
    this.handleOverlayClick = this.handleOverlayClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClose() {
    this.onCloseModal();
  }

  handleConfirm() {
    this.onCloseModal();
    this.requestUpdate();
  }

  handleReset() {
    this.selectedAccessoryId = null;
    this.selectedAccessoryPrice = null;

    this.resetAccessorySelection(this.selectedService.serviceGroupId);
    this.requestUpdate();
  }

  handleOverlayClick() {
    if (this.isModalOpen) {
      this.handleClose();
    }
  }

  render() {
    return html` <div
      @click=${this.handleOverlayClick}
      class=${classMap({
        "is-active": this.isModalOpen,
        "modal-overlay": true,
      })}
    ></div>
      <div
        class=${classMap({
          "is-active": this.isModalOpen,
          "modal-wrapper": true,
        })}
      >
        ${this.isModalOpen
          ? html`
            <subscribe-to-stock-modal-header
              .handleClose=${this.handleClose}
            ></subscribe-to-stock-modal-header>

            <subscribe-to-stock-modal-subscription
                .handleClose=${this.handleClose}
                productId=${this.productId}
            ></subscribe-to-stock-modal-subscription>`
          : nothing}
      </div>`;
  }

  static styles = [optionStyles, subscribeToStockModalStyles];
}
customElements.define("subscribe-to-stock-modal-container", SubscribeToStockModal);
