import { LitElement, html, css, nothing } from 'lit';
import './product-box.js';

export class ProductCarousel extends LitElement {
    static properties = {
        products: { type: Array },
        strings: { type: Object },
        hasOverflow: { type: Boolean },
        fadeLeft: { type: Boolean },
        fadeRight: { type: Boolean },
        currentIndex: { type: Number },
    };

    constructor() {
        super();
        this.products = [];
        this.hasOverflow = false;
        this.fadeLeft = false;
        this.fadeRight = false;
        this.currentIndex= 0;
        this._debounceTimer = null;
        this._onScrollDebounced = this._onScrollDebounced.bind(this);
    }

    firstUpdated() {
        this._checkOverflow();

        window.addEventListener('resize', () => this._checkOverflow());

        const carousel = this.renderRoot.querySelector('.carousel');

        if (carousel) {
            carousel.addEventListener('scroll', () => this._onScrollDebounced());
        }
    }

    _getScrollAmount() {
        if (window.matchMedia('(max-width: 489px)').matches) {
            return 375;
        } else if (window.matchMedia('(max-width: 769px)').matches) {
            return 480;
        } else {
            return 360;
        }
    }

    _getMaxCurrentIndex() {
        const carousel = this.renderRoot.querySelector('.carousel');

        if (!carousel) return this.products.length - 1;

        const carouselWidth = carousel.clientWidth;
        const productBoxes = carousel.querySelectorAll('.product-box-container');

        if (productBoxes.length === 0) return this.products.length - 1;

        const boxWidth = productBoxes[0].clientWidth;
        const visibleBoxes = Math.floor(carouselWidth / boxWidth);
        return Math.max(0, this.products.length - visibleBoxes);
    }

    _onScrollDebounced() {
        if (this._debounceTimer) {
            clearTimeout(this._debounceTimer);
        }
        this._debounceTimer = setTimeout(() => {
            this._onScroll();
        }, 100);
    }

    _onScroll() {
        const carousel = this.renderRoot.querySelector('.carousel');
        if (!carousel) return;

        const { scrollLeft, scrollWidth, clientWidth } = carousel;
        const maxScrollLeft = scrollWidth - clientWidth;

        this.fadeLeft = scrollLeft > 0;

        this.fadeRight = scrollLeft < maxScrollLeft;

        const carouselRect = carousel.getBoundingClientRect();

        let leftmostIndex = -1;
        let leftmostLeft = Infinity;

        const productBoxes = carousel.querySelectorAll('.product-box-container');

        productBoxes.forEach((box, index) => {
            const boxRect = box.getBoundingClientRect();

            const isFullyVisible =
                boxRect.left >= carouselRect.left &&
                boxRect.right <= carouselRect.right;

            if (isFullyVisible && boxRect.left < leftmostLeft) {
                leftmostLeft = boxRect.left;
                leftmostIndex = index;
            }
        });

        if (leftmostIndex !== -1 && this.currentIndex !== leftmostIndex) {
            this.currentIndex = leftmostIndex;
        }
    }

    _checkOverflow() {
        const carousel = this.renderRoot.querySelector('.carousel');
        if (!carousel) return;

        const hasOverflow = carousel.scrollWidth > carousel.clientWidth;
        if (hasOverflow !== this.hasOverflow) {
            this.hasOverflow = hasOverflow;
        }

        if (hasOverflow) {
            this._onScroll();
        } else {
            this.fadeLeft = false;
            this.fadeRight = false;
        }
    }

    _scrollLeft() {
        this._scrollToProductIndex(this.currentIndex - 1);
    }

    _scrollRight() {
        this._scrollToProductIndex(this.currentIndex + 1);
    }

    _scrollToProductIndex(index) {
        const carousel = this.renderRoot.querySelector('.carousel');
        if (!carousel) return;

        this.currentIndex = Math.max(0, Math.min(index, this._getMaxCurrentIndex()));

        const productBox = carousel.querySelector(`#product-box-${this.currentIndex}`);
        if (!productBox) return;

        const offsetLeft = productBox.offsetLeft - carousel.offsetLeft;

        console.log(offsetLeft, this.currentIndex);

        const containerOffset = this._getScrollAmount();

        const leftPadding = 10;
        const finalPosition = offsetLeft - leftPadding;

        carousel.scrollTo({
            top: 0,
            left: finalPosition,
            behavior: 'smooth'
        });
    }

    render() {
        if (!this.products || this.products.length === 0) {
            return html`<p>No products to display.</p>`;
        }

        return html`
            
                <div 
              class="carousel-container
              ${this.fadeLeft ? 'fade-left' : ''}
              ${this.fadeRight ? 'fade-right' : ''}"
            >
                    ${this.hasOverflow && this.fadeLeft
                            ? html`<button class="arrow arrow-left" @click="${this._scrollLeft}"></button>`
                            : nothing
                    }
                <div class="carousel">
                    ${this.products.map((product, index) => html`
                        <div class="product-box-container">
                            <product-box
                                id="product-box-${index}"
                                .product="${product}"
                                .index="${index}"
                                .strings="${this.strings}"
                            >
                            </product-box>
                        </div>
                    `)}
                </div>
                    ${this.hasOverflow && this.fadeRight
                            ? html`<button class="arrow arrow-right" @click="${this._scrollRight}"></button>`
                            : nothing
                    }
            </div>
        `;
    }

    static styles = css`
        :host {
            display: block;
        }
        
        .carousel-container {
            font-family: "Barlow Semi Condensed", sans-serif;
            width: 100%;
            position: relative;
        }

        .carousel {
            display: flex;
            flex-direction: row;
            gap: 15px;
            overflow-x: auto;
            scroll-behavior: smooth;
            padding: 10px;
        }

        .carousel::-webkit-scrollbar {
            height: 3px;
            background: transparent;
        }
        .carousel::-webkit-scrollbar-thumb {
            background-color: #1F1A49;
            border-radius: 3px;
        }
        .carousel::-webkit-scrollbar-thumb:hover {
            background-color: #888;
        }

        .product-box-container {
            width: 360px;
            flex-shrink: 0;
        }
        
        .arrow {
            position: absolute;
            top: 45%;
            z-index: 2;
            border: none;
            background: #1F1A49;
            border-radius: 8px;
            width: 48px;
            height: 48px;
            cursor: pointer;
            font-size: 18px;
            display: flex;
            align-items: center;
            justify-content: center;
            transition: filter 0.2s ease;
            content: url('data:image/svg+xml;utf8,<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11 6L21 16L11 26" stroke="white" stroke-width="2"/></svg>');
        }
        .arrow:hover {
            filter: brightness(0.8);
        }

        .arrow-left {
            left: 5px;
            transform: rotate(180deg);
        }

        .arrow-right {
            right: 5px;
        }

        .carousel-container::before,
        .carousel-container::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            width: 60px;
            pointer-events: none;
            z-index: 1;
        }
        
        .carousel-container.fade-left::before {
            left: 0;
            background: linear-gradient(
                    to right,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 0) 100%
            );
        }
        
        .carousel-container.fade-right::after {
            right: 0;
            background: linear-gradient(
                    to left,
                    rgba(255, 255, 255, 1) 0%,
                    rgba(255, 255, 255, 0) 100%
            );
        }

        @media (max-width: 769px) {
            .product-box-container {
                width: 480px;
            }
            .arrow {
                top: 40%;
            }
        }

        @media (max-width: 489px) {
            .product-box-container {
                width: 375px;
            }
            .arrow {
                top: 40%;
            }
        }
    `;
}

customElements.define('product-carousel', ProductCarousel);