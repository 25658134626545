import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";

export const BUTTON_SIZES = {
  SMALL: "small",
  LARGE: "large",
};

export class LinkLikeButton extends LitElement {
  static properties = {
    size: { type: String },
    disabled: { type: Boolean },
    onClick: { type: Function },
  };

  constructor() {
    super();
    this.onClick = () => {};
    this.disabled = false;
  }

  render() {
    return html`<button
      class=${classMap({
        small: this.size === BUTTON_SIZES.SMALL,
        large: this.size === BUTTON_SIZES.LARGE,
      })}
      ?disabled=${this.disabled}
      @click=${(event) => this.onClick(event)}
    >
      <slot></slot>
    </button>`;
  }

  static styles = css`
    :host {
      display: flex;
    }

    button {
      font-family: "Roboto", sans-serif;
      min-width: 64px;
      border-radius: 4px;
      padding: 8px 24px;
      background-color: transparent;
      color: #222222;
      font-weight: 500;
      font-size: 16px;
      line-height: 1.25em;
      margin: 2px;
      border: none;
      cursor: pointer;
      transition: box-shadow 0.2s ease, color 0.2s ease;
    }

    slot {
      display: inline;
      position: relative;
    }

    slot::after {
      content: "";
      display: inline-block;
      position: absolute;
      inset: 0;
      border-bottom: 1px solid #222222;
      transition: border-bottom-color 0.2s ease;
      pointer-events: none;
    }

    button:hover:not(:disabled) slot::after,
    button:focus:not(:disabled) slot::after {
      border-bottom-color: transparent;
    }

    button.small {
      font-size: 14px;
      line-height: 1.15em;
      padding: 8px 16px;
    }

    button.large {
      padding: 12px 16px;
    }

    button:focus {
      outline: none;
      box-shadow: #222222 0px 0px 0px 2px;
    }

    button:active:not(:disabled) {
      outline: none;
      box-shadow: #222222 0px 0px 0px 2px;
    }

    button:disabled {
      color: #737373;
      cursor: not-allowed;
    }

    button:disabled slot::after {
      border-bottom-color: #737373;
    }
  `;
}
