import { H1 } from "./h1";
import { H2 } from "./h2";
import { H3 } from "./h3";
import { H4 } from "./h4";
import { H5 } from "./h5";
import { Label } from "./label";
import { Link } from "./link";
import { Input } from "./input";
import { InputError } from "./input-error";
import { Textarea } from "./textarea";
import { SecondaryButton } from "./secondary-button";
import { TertiaryButton } from "./tertiary-button";
import { ClearButton } from "./clear-button";
import { LinkLikeButton } from './link-like-button';
import { ErrorButton } from "./error-button";
import { Modal } from "./modal";

customElements.define("komplett-h1", H1);
customElements.define("komplett-h2", H2);
customElements.define("komplett-h3", H3);
customElements.define("komplett-h4", H4);
customElements.define("komplett-h5", H5);
customElements.define("komplett-label", Label);
customElements.define("komplett-link", Link);
customElements.define("komplett-input", Input);
customElements.define("komplett-input-error", InputError);
customElements.define("komplett-textarea", Textarea);
customElements.define("komplett-secondary-button", SecondaryButton);
customElements.define("komplett-tertiary-button", TertiaryButton);
customElements.define("komplett-clear-button", ClearButton);
customElements.define("komplett-link-like-button", LinkLikeButton);
customElements.define("komplett-error-button", ErrorButton);
customElements.define("komplett-modal", Modal);
