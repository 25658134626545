import { LitElement, html, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { optionStyles } from "./config-service.styles.js";
import strings from "../../utils/strings.js";

class ConfigServiceOption extends LitElement {
  static properties = {
    service: { type: Array },
    defaultAccessoryId: { type: Number },
    selectedAccessoryId: { type: Number || undefined },
    option: { state: true },
    isServiceOnly: { state: true },
    openAccessoriesModal: {
      type: Function,
    },
    selectDefaultAccessory: {
      type: Function,
    },
  };

  getSelected() {
    if (this.service.children.length) {
      return this.service.children.find(
        (accessory) => accessory.childId === this.selectedAccessoryId
      );
    } else if (this.service.serviceGroupId === this.selectedAccessoryId) {
      return { ...this.service, childId: this.service.serviceTypeId };
    }
  }

  getDefault() {
    if (this.service.children.length) {
      return this.service.children.find(
        (accessory) => accessory.childId === this.defaultAccessoryId
      );
    } else if (this.service.serviceGroupId === this.defaultAccessoryId) {
      return { ...this.service, childId: this.service.serviceTypeId };
    }
  }

  render() {
    this.option = this.getSelected() || this.getDefault();

    const isServiceOnly = !this.service?.serviceTypeId && this.service.children.length > 0;

    return html`
      <div
        class=${classMap({
          "is-selected": this.selectedAccessoryId,
          "service-option": true,
        })}
        data-testid=${`${this.service.serviceGroupId}.config.service.option`}
      >
        <label
          class=${classMap({
            "is-disabled": this.service.isMandatory,
          })}
          htmlFor=${this.service.serviceGroupId}
        >
          <input
            id=${this.service.serviceGroupId}
            data-testid=${`${this.service.serviceGroupId}.config.service.option.input`}
            type="checkbox"
            name="accessory"
            .disabled=${this.service.isMandatory}
            .checked=${this.selectedAccessoryId}
            @click=${() => {
              if (this.service.children.length) {
                this.selectDefaultAccessory({
                  accessoryId: this.selectedAccessoryId ? null : this.defaultAccessoryId,
                  accessoryPrice: this.selectedAccessoryId ? null : this.option.price,
                });
              } else {
                this.selectDefaultAccessory({
                  accessoryId: this.selectedAccessoryId ? null : this.service.serviceGroupId,
                  accessoryPrice: this.selectedAccessoryId ? null : this.service.price,
                });
              }
            }}
            .value=${this.service.serviceGroupId}
          />
          <div class="service-details">
            <h3 data-testid=${`${this.service.serviceGroupId}.config.service.option.name`}>
              ${this.service.name}
            </h3>
          </div>
          <div class="service-price">
            <p data-testid=${`${this.service.serviceGroupId}.config.service.option.price`}>
              ${this.option.priceFormatted || nothing}
            </p>
          </div>
        </label>
        <div
          class="service-details"
          @click=${() => this.openAccessoriesModal(this.service.serviceGroupId)}
        >
          <i class="service-frame"><img src="/img/p/200/${this.option.childId}.jpg" /></i>
          <div class="service-description">
            ${this.service.children.length > 1
              ? html`<span
                  data-testid=${`${this.service.serviceGroupId}.config.service.option.selected`}
                  >${strings[
                    "product.box.config.service.additional.services.service.selected.option"
                  ]}</span
                >`
              : nothing}
            <p
              class=${classMap({
                "is-description": !this.service.children.length,
              })}
              data-testid=${`${this.service.serviceGroupId}.config.service.option.description`}
            >
              ${isServiceOnly
                ? this.option.description
                : this.service.children.length
                ? html`<span class="service-description-title">${this.option.name}</span>`
                : this.service.description}
              ${this.service.children.length > 1
                ? html`<a
                    href="#"
                    class="service-description-change"
                    data-testid=${`${this.service.serviceGroupId}.config.service.option.change.link`}
                    @click=${() => this.openAccessoriesModal(this.service.serviceGroupId)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        d="M15.625 2.51953C15.1465 2.51953 14.6777 2.70508 14.3164 3.06641L8.125 9.23828L7.98828 9.375L7.94922 9.57031L7.51953 11.7578L7.32422 12.6758L8.24219 12.4805L10.4297 12.0508L10.625 12.0117L10.7617 11.875L16.9336 5.68359C17.6538 4.96338 17.6538 3.78662 16.9336 3.06641C16.5723 2.70508 16.1035 2.51953 15.625 2.51953ZM15.625 3.73047C15.7715 3.73047 15.9155 3.80615 16.0547 3.94531C16.333 4.22363 16.333 4.52637 16.0547 4.80469L10 10.8594L8.92578 11.0742L9.14062 10L15.1953 3.94531C15.3345 3.80615 15.4785 3.73047 15.625 3.73047ZM2.5 5V17.5H15V9.25781L13.75 10.5078V16.25H3.75V6.25H9.49219L10.7422 5H2.5Z"
                        fill="#565656"
                      />
                    </svg>
                    ${strings[
                      "product.box.config.service.additional.services.service.change.option"
                    ]}
                  </a>`
                : nothing}
            </p>
            <a
              href="#"
              data-testid=${`${this.service.serviceGroupId}.config.service.option.read.more.link`}
              @click=${() => this.openAccessoriesModal(this.service.serviceGroupId)}
              >${strings["product.box.config.service.additional.services.service.read.more"]}</a
            >
          </div>
        </div>
      </div>
    `;
  }

  static styles = [optionStyles];
}
customElements.define("config-service-option", ConfigServiceOption);
