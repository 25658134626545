import { EVENTS, triggerCustomEvent } from "./customEvents";

export const addToCart = (product, quantity, callback, index) => {
	fetch("/cart/api/addProductToCartAsync", {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
		},
		body: JSON.stringify({
			productId: product.materialNumber,
			quantity: quantity,
		}),
	})
		.then(function (response) {
			if (!response.ok) {
				triggerCustomEvent(EVENTS.ADD_ALERT_MESSAGE, {
					message:
						"Sorry but this product is unavailable, please try again later or contact customer support.",
					level: "error",
				});
				return Promise.reject();
			}
			return response.json();
		})
		.then(function (data) {
			if (data.success) {
				if (callback) {
					callback(product, quantity);
				}

				triggerCustomEvent(EVENTS.UPDATE_CART_CAAS, data.shoppingCartSummary);

				//Track "add_to_cart
			} else {
				if (data.shoppingCartSummary && data.numberOfItems) {
					triggerCustomEvent(EVENTS.UPDATE_CART_CAAS, data.shoppingCartSummary);
				}

				if (typeof data.alerts !== "undefined") {
					data.alerts.forEach(function (element) {
						triggerCustomEvent(EVENTS.ADD_ALERT_MESSAGE, {
							message: element.message,
							level: element.level,
						});
					});
				}
			}
		});
};
