import { css } from "lit";

export const subscribeToStockModalStyles = css`
  :host {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    gap: 5px;
  }

  .modal-overlay {
    position: fixed;
    z-index: 1001;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    display: none;
  }

  .modal-overlay.is-active {
    display: block;
    opacity: 1;
  }

  .modal-wrapper {
    position: absolute;
    z-index: 1001;
    width: 500px;
    visibility: hidden;
    transform: translateY(100%);
  }

  .modal-wrapper.is-active {
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 8px;
    visibility: visible;
    top: 25vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 991px) {
    .modal-wrapper.is-active {
      position: fixed;
      height: 100%;
      width: 100vw;
      top: 0;
      border-radius: 0;
      border: none;
      left: 0;
      transform: translateY(0);
      tran
    }
  }

  .modal-subscription {
    padding: 24px;
    margin-top: 50px;
    height: calc(100% - 170px);
    width: 100%;
    overflow-y: auto;
  }

  .modal-subscription-description {
    padding-bottom: 24px;
  }

  .modal-subscription-description h2 {
    font-size: 28px;
    font-weight: 500;
    color: #222;
    margin: 0 0 8px;
  }

  .modal-subscription-description p {
    font-size: 16px;
    margin: 0;
  }

  .modal-subscription-registered-description {
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .modal-subscription-registered-description i {
    display: flex;
    margin: 0 0 8px;
  }

  .modal-subscription-registered-description h2 {
    font-size: 28px;
    font-weight: 500;
    color: #222;
    margin: 0 0 8px;
  }

  .modal-subscription-registered-description p {
    font-size: 16px;
    margin: 0 0 8px;
  }

  .modal-subscription-registered-description svg {
    width: 32px;
    height: 32px;
  }

  .modal-subscription-registered-description strong {
    font-weight: 500;
  }

  .email-label {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
  }

  .modal-subscription-email input {
    width: 100%;
    height: 48px;
    padding: 12px;
    margin-top: 6px;
    margin-bottom: 24px;
    font-size: 16px;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid #ddd;
  }

  .modal-subscription-email input.invalid {
    border-color: #B41400;
  }

  .modal-subscription-content button {
    width: 100%;
    height: 48px;
    padding: 12px;
    font-size: 16px;
    background-color: #13102c;
    color: white;
    border-radius: 4px;
    border: 1px solid #ddd;
  }
    
  .modal-subscription-content button:hover {
    background-color: #2a275a;
    cursor: pointer;
  }

  .modal-subscription-content button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(19, 16, 44, 0.3);
  }

  .modal-subscription-content button:active {
    background-color: #0f0d1c;
    transform: scale(0.98);
  }

  .error-message {
    color: #B41400;
    font-size: 14px;
    margin-top: -18px;
    margin-bottom: 24px;
  }

  .close-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  .close-link-wrapper span {
    vertical-align: middle;
    text-decoration: underline;
    font-weight: 500;
    font-size: 16px;
  }

  .close-link-wrapper a {
    color: #000000;
    text-decoration: none;
    height: 48px;
    align-content: center;
  }

  .close-link-wrapper a:hover {
    cursor:pointer;
  }
`;
