import { html, css } from "lit";
import { getEnergyIcon } from "../../utils/svgProvider";
import { calcRightTextColor } from "../../utils";

const calcColor = (hex) => {
  const hexColor = parseInt(hex.replace(/^#/, ""), 16);
  return hexColor > 0xffffff / 2 ? "#000" : "#fff";
};

export const labelsTemplate = ({ tag, sticker, energyLogo, url }, strings) =>
  html` <div class="labels-container">
    ${tag
      ? html`<span
          class="tag"
          style="--tag-color: ${tag.color}; 
          --text-color: ${calcRightTextColor(tag.color)};"
        >
          ${tag.text}
        </span>`
      : ""}
    ${sticker?.imageUrl
      ? html`<span class="sticker">
          <img src="${sticker.imageUrl}" alt="${sticker.altText}" />
        </span>`
      : ""}
    ${energyLogo?.energyGradeLetter
      ? html`<a class="energy-logo" target="_blank" href="${url}">
          <div>${strings.energylogo}</div>
          ${getEnergyIcon(energyLogo)}
        </a>`
      : ""}
  </div>`;

export const labelsStyles = css`
  .product-box .labels-container {
    grid-column: 1 / span 2;
    grid-row: 1;
    align-self: start;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;

    .tag {
      background: var(--tag-color, #1F1A49);
      color: var(--text-color, white);
      padding: 4px 8px;
      border-radius: 4px;
      font-family: "Barlow Semi Condensed", sans-serif;
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
    }

    .sticker img {
      display: block;
      max-width: 90px;
      max-height: 90px;
      width: auto;
      height: auto;
    }

    .energy-logo {
      font-size: 12px;
      color: black;
      display: block;
    }
  }

  :host([layout="list"]) .product-box .labels-container {
    grid-column: 1;
    grid-row: 1 / span 6;
  }

  @media (max-width: 768px) {
    .product-box .labels-container {
      grid-column: 1;
      grid-row: 1 / span 3;

      .tag {
        font-size: 14px;
      }
    }
  }
`;