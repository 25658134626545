import { html } from "lit";
import { legacyEnergyLogo } from "./legacy-energy-logo";

export const compareSvg = html`<svg
  width="18"
  height="18"
  viewBox="0 0 18 18"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <g id="exchange-alt-solid">
    <path
      id="Vector"
      d="M12.4805 1.2832L11.6895 2.0918L14.0977 4.5H2.25V5.625H14.0977L11.6895 8.0332L12.4805 8.8418L16.2598 5.0625L12.4805 1.2832ZM5.51953 9.1582L1.74023 12.9375L5.51953 16.7168L6.31055 15.9082L3.90234 13.5H15.75V12.375H3.90234L6.31055 9.9668L5.51953 9.1582Z"
      fill="currentColor"
    />
  </g>
</svg>`;

export const starSvg = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20pt"
  height="20pt"
  viewBox="0 0 32 32"
>
  <path
    fill="#f8b912"
    d="M 4.445313 12.542969 C 4.445313 12.199219 4.703125 11.984375 5.222656 11.902344 L 12.195313 10.890625 L 15.320313 4.570313 C 15.496094 4.191406 15.722656 4 16 4 C 16.277344 4 16.503906 4.191406 16.679688 4.570313 L 19.804688 10.890625 L 26.777344 11.902344 C 27.296875 11.984375 27.554688 12.199219 27.554688 12.542969 C 27.554688 12.746094 27.433594 12.96875 27.195313 13.207031 L 22.152344 18.125 L 23.347656 25.070313 C 23.355469 25.132813 23.359375 25.226563 23.359375 25.347656 C 23.359375 25.542969 23.3125 25.707031 23.214844 25.839844 C 23.117188 25.972656 22.976563 26.042969 22.792969 26.042969 C 22.617188 26.042969 22.429688 25.984375 22.234375 25.875 L 16 22.597656 L 9.765625 25.875 C 9.558594 25.984375 9.375 26.042969 9.207031 26.042969 C 9.015625 26.042969 8.867188 25.972656 8.769531 25.839844 C 8.671875 25.707031 8.625 25.542969 8.625 25.347656 C 8.625 25.292969 8.632813 25.199219 8.652344 25.070313 L 9.847656 18.125 L 4.792969 13.207031 C 4.558594 12.957031 4.445313 12.734375 4.445313 12.542969 Z "
  />
</svg>`;

export const starHalfSvg = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20pt"
  height="20pt"
  viewBox="0 0 32 32"
>
  <path
    fill="#f8b912"
    d="M 4.472656 12.382813 C 4.554688 12.128906 4.804688 11.96875 5.222656 11.902344 L 12.195313 10.890625 L 15.320313 4.570313 C 15.503906 4.191406 15.730469 4 16 4 C 16.257813 4 16.484375 4.191406 16.679688 4.570313 L 19.804688 10.890625 L 26.777344 11.902344 C 27.195313 11.96875 27.445313 12.128906 27.527344 12.382813 C 27.609375 12.636719 27.5 12.910156 27.195313 13.207031 L 22.152344 18.125 L 23.347656 25.070313 C 23.394531 25.375 23.367188 25.613281 23.265625 25.785156 C 23.160156 25.957031 23.003906 26.042969 22.792969 26.042969 C 22.632813 26.042969 22.449219 25.984375 22.234375 25.875 L 16 22.597656 L 9.765625 25.875 C 9.550781 25.984375 9.367188 26.042969 9.207031 26.042969 C 8.996094 26.042969 8.839844 25.957031 8.734375 25.785156 C 8.632813 25.613281 8.605469 25.375 8.652344 25.070313 L 9.847656 18.125 L 4.792969 13.207031 C 4.496094 12.910156 4.390625 12.636719 4.472656 12.382813 Z M 16 20.582031 L 16.820313 21.015625 L 21.234375 23.347656 L 20.402344 18.417969 L 20.234375 17.5 L 20.917969 16.847656 L 24.484375 13.375 L 19.542969 12.652344 L 18.625 12.515625 L 18.207031 11.679688 L 16 7.207031 Z "
  />
</svg>`;

export const starEmptySvg = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20pt"
  height="20pt"
  viewBox="0 0 32 32"
>
  <path
    fill="#bbb"
    d="M 4.445313 12.542969 C 4.445313 12.199219 4.703125 11.984375 5.222656 11.902344 L 12.195313 10.890625 L 15.320313 4.570313 C 15.496094 4.191406 15.722656 4 16 4 C 16.277344 4 16.503906 4.191406 16.679688 4.570313 L 19.804688 10.890625 L 26.777344 11.902344 C 27.296875 11.984375 27.554688 12.199219 27.554688 12.542969 C 27.554688 12.746094 27.433594 12.96875 27.195313 13.207031 L 22.152344 18.125 L 23.347656 25.070313 C 23.355469 25.132813 23.359375 25.226563 23.359375 25.347656 C 23.359375 25.808594 23.171875 26.042969 22.792969 26.042969 C 22.617188 26.042969 22.429688 25.984375 22.234375 25.875 L 16 22.597656 L 9.765625 25.875 C 9.558594 25.984375 9.375 26.042969 9.207031 26.042969 C 9.015625 26.042969 8.867188 25.972656 8.769531 25.839844 C 8.671875 25.707031 8.625 25.542969 8.625 25.347656 C 8.625 25.292969 8.632813 25.199219 8.652344 25.070313 L 9.847656 18.125 L 4.792969 13.207031 C 4.558594 12.957031 4.445313 12.734375 4.445313 12.542969 Z M 7.515625 13.375 L 11.765625 17.5 L 10.75 23.347656 L 16 20.582031 L 21.234375 23.347656 L 20.234375 17.5 L 24.484375 13.375 L 18.625 12.515625 L 16 7.207031 L 13.375 12.515625 Z "
  />
</svg>`;

export const stars = [starSvg, starHalfSvg, starEmptySvg];

export const energyIconG = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#ed1c24" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M36.59,26.79A21.39,21.39,0,0,1,29.68,28c-3.64,0-6.43-.93-8.47-2.88a10.8,10.8,0,0,1-3-7.93c0-6.84,4.86-11.19,12-11.19a14.31,14.31,0,0,1,5.73,1l-.87,3.17a11.15,11.15,0,0,0-4.89-.93c-4.6,0-7.83,2.72-7.83,7.74s3,7.8,7.48,7.8a8.32,8.32,0,0,0,3-.41V19H29v-3.1h7.61Z"
      />
    </g>
    <path
      d="M35.59,25.79A21.39,21.39,0,0,1,28.68,27c-3.64,0-6.43-.93-8.47-2.88a10.8,10.8,0,0,1-3-7.93C17.23,9.35,22.06,5,29.19,5a14.31,14.31,0,0,1,5.73,1l-.87,3.17a11.15,11.15,0,0,0-4.89-.93c-4.6,0-7.83,2.72-7.83,7.74s3,7.8,7.48,7.8a8.32,8.32,0,0,0,3-.41V18H28v-3.1h7.61Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconF = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#f37021" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path d="M19.05,6H31.78V9.3H23.07v6.07h8.09v3.26H23.07V28h-4Z" />
    </g>
    <path
      d="M18.05,5H30.78V8.3H22.07v6.07h8.09v3.26H22.07V27h-4Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconE = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#fdb913" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M31.42,18.24H23.13V24.7H32.4V28H19.12V6H31.91V9.3H23.13V15h8.29Z"
      />
    </g>
    <path
      d="M30.42,17.24H22.13V23.7H31.4V27H18.12V5H30.91V8.3H22.13V14h8.29Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconD = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#fff200" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M19.33,6.45A43.79,43.79,0,0,1,25.61,6c4.05,0,6.8.83,8.79,2.5s3.4,4.3,3.4,8c0,3.89-1.31,6.74-3.37,8.6S28.85,28,24.65,28a43.87,43.87,0,0,1-5.32-.29Zm3.94,18.34a14.18,14.18,0,0,0,2.18.1c5.14,0,8.18-2.82,8.18-8.31,0-4.74-2.72-7.5-7.66-7.5a12.89,12.89,0,0,0-2.7.22Z"
      />
    </g>
    <path
      d="M18.33,5.45A43.79,43.79,0,0,1,24.61,5c4.05,0,6.8.83,8.79,2.5s3.4,4.3,3.4,8c0,3.89-1.31,6.74-3.37,8.6S27.85,27,23.65,27a43.87,43.87,0,0,1-5.32-.29Zm3.94,18.34a14.18,14.18,0,0,0,2.18.1c5.14,0,8.18-2.82,8.18-8.31,0-4.74-2.72-7.5-7.66-7.5a12.89,12.89,0,0,0-2.7.22Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconC = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#bfd730" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M35.69,27.05a14.15,14.15,0,0,1-5.53.95c-6.76,0-10.87-4.24-10.87-10.75,0-7,4.9-11.25,11.41-11.25a12.05,12.05,0,0,1,5.15,1L35,10.05a10.34,10.34,0,0,0-4.17-.83c-4.33,0-7.46,2.72-7.46,7.84,0,4.71,2.71,7.68,7.42,7.68A11.87,11.87,0,0,0,35.06,24Z"
      />
    </g>
    <path
      d="M34.69,26.05a14.15,14.15,0,0,1-5.53.95c-6.76,0-10.87-4.24-10.87-10.75C18.29,9.24,23.19,5,29.7,5a12.05,12.05,0,0,1,5.15,1L34,9.05a10.34,10.34,0,0,0-4.17-.83c-4.33,0-7.46,2.72-7.46,7.84,0,4.71,2.71,7.68,7.42,7.68A11.87,11.87,0,0,0,34.06,23Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconB = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#50b848" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M20.72,6.48A32.22,32.22,0,0,1,26.49,6c2.89,0,4.71.39,6.16,1.38a4.58,4.58,0,0,1,2.24,4.07A5,5,0,0,1,31.3,16.1v.1a5.43,5.43,0,0,1,4.36,5.32,5.79,5.79,0,0,1-2,4.49c-1.54,1.32-4.07,2-8,2a38.14,38.14,0,0,1-4.84-.29ZM24.63,15h2c2.76,0,4.27-1.28,4.27-3.11,0-2.05-1.54-3-4.05-3a10.47,10.47,0,0,0-2.24.16Zm0,9.91a12,12,0,0,0,2.08.1c2.54,0,4.81-.93,4.81-3.59s-2.21-3.56-4.93-3.56h-2Z"
      />
    </g>
    <path
      d="M19.72,5.48A32.22,32.22,0,0,1,25.49,5c2.89,0,4.71.39,6.16,1.38a4.58,4.58,0,0,1,2.24,4.07A5,5,0,0,1,30.3,15.1v.1a5.43,5.43,0,0,1,4.36,5.32,5.79,5.79,0,0,1-2,4.49c-1.54,1.32-4.07,2-8,2a38.14,38.14,0,0,1-4.84-.29ZM23.63,14h2c2.76,0,4.27-1.28,4.27-3.11,0-2.05-1.54-3-4.05-3a10.47,10.47,0,0,0-2.24.16Zm0,9.91a12,12,0,0,0,2.08.1c2.54,0,4.81-.93,4.81-3.59s-2.21-3.56-4.93-3.56h-2Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const energyIconA = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="64"
  height="32"
  viewBox="0 0 64 32"
>
  <defs>
    <symbol id="a" viewBox="0 0 10 28">
      <rect width="10" height="28" fill="#fff" />
      <path
        d="M3.68,7.65,3,9.91H1.49l2.56-8H5.91l2.6,8H6.93L6.21,7.65ZM6,6.54l-.63-2c-.15-.48-.28-1-.4-1.49h0c-.12.46-.25,1-.38,1.49l-.62,2Z"
      />
      <path
        d="M8.4,26.1a7.68,7.68,0,0,1-2.52.44,4.26,4.26,0,0,1-3.08-1,3.94,3.94,0,0,1-1.09-2.89c0-2.49,1.77-4.07,4.36-4.07a5.3,5.3,0,0,1,2.08.37l-.31,1.16a4,4,0,0,0-1.78-.34,2.62,2.62,0,0,0-2.85,2.81,2.59,2.59,0,0,0,2.72,2.84A3,3,0,0,0,7,25.23v-2H5.63V22.14H8.4Z"
      />
      <polygon
        points="7.46 13.84 5 10.84 2.54 13.84 4.43 13.84 4.43 17 5.57 17 5.57 13.84 7.46 13.84"
      />
    </symbol>
  </defs>
  <g style="isolation:isolate">
    <path d="M0,0H48L64,16,48,32H0Z" fill="#00a651" />
    <use width="10" height="28" transform="translate(2 2)" xlink:href="#a" />
    <g opacity="0.5" style="mix-blend-mode:multiply">
      <path
        d="M25.43,21.77,23.54,28H19.39L26.45,6h5.12l7.15,22H34.38l-2-6.23Zm6.3-3L30,13.31c-.42-1.34-.78-2.84-1.11-4.11h-.06c-.33,1.27-.69,2.81-1,4.11l-1.69,5.42Z"
      />
    </g>
    <path
      d="M24.43,20.77,22.54,27H18.39L25.45,5h5.12l7.15,22H33.38l-2-6.23Zm6.3-3L29,12.31c-.42-1.34-.78-2.84-1.11-4.11h-.06c-.33,1.27-.69,2.81-1,4.11l-1.69,5.42Z"
      fill="#fff"
    />
  </g>
</svg>`;

export const listView = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M3 3.75V8.25H7.5V3.75H3ZM4.5 5.25H6V6.75H4.5V5.25ZM9 5.25V6.75H20.25V5.25H9ZM3 9.75V14.25H7.5V9.75H3ZM4.5 11.25H6V12.75H4.5V11.25ZM9 11.25V12.75H20.25V11.25H9ZM3 15.75V20.25H7.5V15.75H3ZM4.5 17.25H6V18.75H4.5V17.25ZM9 17.25V18.75H20.25V17.25H9Z"
    fill="currentColor"
  />
</svg>`;

export const groupView = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M1.5 5.25V11.25H7.5V5.25H1.5ZM9 5.25V11.25H15V5.25H9ZM16.5 5.25V11.25H22.5V5.25H16.5ZM3 6.75H6V9.75H3V6.75ZM10.5 6.75H13.5V9.75H10.5V6.75ZM18 6.75H21V9.75H18V6.75ZM1.5 12.75V18.75H7.5V12.75H1.5ZM9 12.75V18.75H15V12.75H9ZM16.5 12.75V18.75H22.5V12.75H16.5ZM3 14.25H6V17.25H3V14.25ZM10.5 14.25H13.5V17.25H10.5V14.25ZM18 14.25H21V17.25H18V14.25Z"
    fill="currentColor"
  />
</svg>`;

export const close = html`<svg
  width="32"
  height="32"
  viewBox="0 0 32 32"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <rect width="32" height="32" rx="16" fill="#F5F5F5" />
  <path
    d="M11.6094 10.8906L10.8906 11.6094L15.2812 16L10.8906 20.3906L11.6094 21.1094L16 16.7188L20.3906 21.1094L21.1094 20.3906L16.7188 16L21.1094 11.6094L20.3906 10.8906L16 15.2812L11.6094 10.8906Z"
    fill="#222222"
  />
</svg>`;

export const add = html`<svg
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.375 3.125V9.375H3.125V10.625H9.375V16.875H10.625V10.625H16.875V9.375H10.625V3.125H9.375Z"
    fill="#230DC0"
  />
</svg>`;

export const back = html`<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.3335 12.666L5.66683 7.99935L10.3335 3.33268"
    stroke="black"
    stroke-width="1.5"
  />
</svg> `;

export const tvCalibration = html`<svg
  width="59"
  height="59"
  viewBox="0 0 59 59"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M4.125 13.1875V42.1875H54.875V13.1875H4.125ZM7.75 16.8125H51.25V38.5625H7.75V16.8125ZM18.625 44V47.625H40.375V44H18.625Z"
    fill="#230DC0"
  />
</svg>`;

export const installation = html`<svg
  width="59"
  height="59"
  viewBox="0 0 59 59"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M10.0156 5.48438L8.99609 6.44726L6.44726 8.99609L5.48438 10.0156L6.2207 11.2051L10.0156 17.5488L10.5254 18.4551H15.8496L23.0996 25.6484C16.6213 32.155 8.38012 40.4245 8.08984 40.7148C5.25073 43.5539 5.24365 48.2055 8.14648 50.9101C10.9785 53.7067 15.5947 53.7775 18.2851 50.9101C18.3064 50.8889 18.3205 50.8747 18.3418 50.8535L29.5 39.6386L40.7148 50.9101L40.8847 51.0234C43.7309 53.7067 48.2551 53.7421 50.9101 50.9101V50.8535H50.9668C53.7421 48.0144 53.7705 43.3982 50.9101 40.7148L50.8535 40.6582L41.3945 31.2558C47.7595 30.6611 52.7439 25.2873 52.8359 18.7949H52.8925C52.8996 18.7595 52.8925 18.717 52.8925 18.6816C52.8925 18.6604 52.8925 18.6462 52.8925 18.625C53.0695 16.5293 52.6164 14.5256 51.5332 12.791L50.2871 10.8652L41.8476 19.3047L39.2988 16.6426L47.9082 8.0332L45.416 7.01367C43.9575 6.39062 42.2724 5.9375 40.375 5.9375C33.4082 5.9375 27.6875 11.6582 27.6875 18.625C27.6875 19.3826 27.8503 20.041 27.9707 20.7207C27.1777 21.5137 26.583 22.165 25.6484 23.0996L18.4551 15.9062V10.5254L17.5488 10.0156L11.2051 6.2207L10.0156 5.48438ZM40.375 9.5625C40.6299 9.5625 40.8069 9.71118 41.0547 9.73242L34.2011 16.5859L35.4472 17.8887L40.5449 23.1562L41.791 24.459L48.9843 17.2656C49.0197 17.6833 49.3171 17.9878 49.2675 18.4551V18.625C49.2675 23.6164 45.1965 27.6875 40.205 27.6875C39.5395 27.6875 38.7324 27.5317 37.8261 27.3476L36.8633 27.1777L36.1836 27.8574L15.7363 48.3613H15.6797V48.4179C14.3911 49.8269 12.1396 49.8623 10.6387 48.3613V48.3046H10.582C9.17309 47.0161 9.13769 44.7646 10.6387 43.2636C11.3254 42.5769 24.6289 29.1601 31.1426 22.6465L31.8789 21.9101L31.5957 20.8906C31.447 20.303 31.3125 19.2905 31.3125 18.625C31.3125 13.6335 35.3835 9.5625 40.375 9.5625ZM10.582 10.0156L14.8301 12.6211V14.4336L14.4336 14.8301H12.6211L10.0156 10.582L10.582 10.0156ZM37.0898 32.0488L48.3613 43.2636V43.3203H48.4179C49.8269 44.6089 49.8623 46.8603 48.3613 48.3613H48.3046V48.4179C47.0161 49.8269 44.7646 49.8623 43.2636 48.3613L32.0488 37.0898L37.0898 32.0488Z"
    fill="#3E2CC6"
  />
</svg>`;

export const carePackage = html`<svg
  width="59"
  height="59"
  viewBox="0 0 59 59"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M9.5625 11.375V35.957L5.42773 40.152C4.60667 40.9712 4.125 42.1018 4.125 43.2636C4.125 45.6561 6.09383 47.625 8.48632 47.625H50.5136C52.9061 47.625 54.875 45.6561 54.875 43.2636C54.875 42.1018 54.3933 40.9695 53.5722 40.1484L49.4375 35.957V11.375H9.5625ZM13.1875 15H45.8125V34.9375H13.1875V15ZM27.6875 18.625V24.0625H22.25V27.6875H27.6875V33.125H31.3125V27.6875H36.75V24.0625H31.3125V18.625H27.6875ZM12.168 38.5625H46.832L51.0234 42.6972C51.1648 42.8386 51.25 43.0643 51.25 43.2636C51.25 43.7023 50.9523 44 50.5136 44H8.48632C8.0477 44 7.75 43.7023 7.75 43.2636C7.75 43.0661 7.83518 42.8404 7.97656 42.6972L12.168 38.5625Z"
    fill="#230DC0"
  />
</svg>`;

export const screenProtection = html`<svg
  width="59"
  height="59"
  viewBox="0 0 59 59"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M20.4375 7.75C17.4568 7.75 15 10.2068 15 13.1875V45.8125C15 48.7932 17.4568 51.25 20.4375 51.25H38.5625C41.5432 51.25 44 48.7932 44 45.8125V13.1875C44 10.2068 41.5432 7.75 38.5625 7.75H20.4375ZM20.4375 11.375H38.5625C39.5679 11.375 40.375 12.1821 40.375 13.1875V45.8125C40.375 46.8178 39.5679 47.625 38.5625 47.625H20.4375C19.4321 47.625 18.625 46.8178 18.625 45.8125V13.1875C18.625 12.1821 19.4321 11.375 20.4375 11.375ZM29.5 42.1875C28.5017 42.1875 27.6875 43.0017 27.6875 44C27.6875 44.9983 28.5017 45.8125 29.5 45.8125C30.4983 45.8125 31.3125 44.9983 31.3125 44C31.3125 43.0017 30.4983 42.1875 29.5 42.1875Z"
    fill="#230DC0"
  />
</svg>`;

export const additionalService = html`<svg
  width="59"
  height="59"
  viewBox="0 0 59 59"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    d="M29.5 5.9375C16.508 5.9375 5.9375 16.508 5.9375 29.5C5.9375 42.4919 16.508 53.0625 29.5 53.0625C42.4919 53.0625 53.0625 42.4919 53.0625 29.5C53.0625 16.508 42.4919 5.9375 29.5 5.9375ZM29.5 9.5625C40.5307 9.5625 49.4375 18.4692 49.4375 29.5C49.4375 40.5307 40.5307 49.4375 29.5 49.4375C18.4692 49.4375 9.5625 40.5307 9.5625 29.5C9.5625 18.4692 18.4692 9.5625 29.5 9.5625ZM27.6875 18.625V27.6875H18.625V31.3125H27.6875V40.375H31.3125V31.3125H40.375V27.6875H31.3125V18.625H27.6875Z"
    fill="#3E2CC6"
  />
</svg>`;

export const chevron = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="32"
  height="33"
  viewBox="0 0 32 33"
  fill="none"
>
  <path
    d="M9.14288 13.1309L16 19.988L22.8572 13.1309"
    stroke="#222222"
    stroke-width="1.5"
  />
</svg>`;

export const closeX = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="24"
  height="24"
  viewBox="0 0 24 24"
  fill="none"
>
  <path
    d="M5.41406 4.33594L4.33594 5.41406L10.9219 12L4.33594 18.5859L5.41406 19.6641L12 13.0781L18.5859 19.6641L19.6641 18.5859L13.0781 12L19.6641 5.41406L18.5859 4.33594L12 10.9219L5.41406 4.33594Z"
    fill="black"
  />
</svg>`;

export const remove = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="20"
  viewBox="0 0 20 20"
  fill="none"
>
  <path
    d="M9.375 2.5C9.04785 2.5 8.71338 2.61475 8.47656 2.85156C8.23975 3.08838 8.125 3.42285 8.125 3.75V4.375H4.375V5.625H5V15.625C5 16.6528 5.84717 17.5 6.875 17.5H14.375C15.4028 17.5 16.25 16.6528 16.25 15.625V5.625H16.875V4.375H13.125V3.75C13.125 3.42285 13.0103 3.08838 12.7734 2.85156C12.5366 2.61475 12.2021 2.5 11.875 2.5H9.375ZM9.375 3.75H11.875V4.375H9.375V3.75ZM6.25 5.625H15V15.625C15 15.9717 14.7217 16.25 14.375 16.25H6.875C6.52832 16.25 6.25 15.9717 6.25 15.625V5.625ZM7.5 7.5V14.375H8.75V7.5H7.5ZM10 7.5V14.375H11.25V7.5H10ZM12.5 7.5V14.375H13.75V7.5H12.5Z"
    fill="#B41400"
  />
</svg>`;

export const bell = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  fill="none"
  viewBox="0 0 24 24"
  focusable="false"
>
  <path
    fill="currentColor"
    fill-rule="evenodd"
    d="M12.75 2.5a.75.75 0 0 0-1.5 0v1.273c-2.222.14-3.655.92-4.499 2.186-.887 1.33-1.001 3.04-1.001 4.541v3c0 .809-.424 1.92-.92 2.915a18 18 0 0 1-.936 1.641l-.014.022-.004.006A.75.75 0 0 0 4.5 19.25h4.338a3.25 3.25 0 0 0 6.324 0H19.5a.75.75 0 0 0 .624-1.166l-.004-.006-.014-.022-.06-.091c-.05-.081-.125-.2-.215-.35-.18-.301-.42-.722-.66-1.2-.497-.994-.921-2.106-.921-2.915v-3c0-1.501-.114-3.21-1.001-4.541-.844-1.266-2.277-2.046-4.499-2.186zM12 5.25c-2.345 0-3.436.694-4.001 1.541-.613.92-.749 2.21-.749 3.709v3c0 1.191-.576 2.58-1.08 3.585q-.178.358-.349.665H18.18a19 19 0 0 1-.35-.665c-.503-1.006-1.079-2.394-1.079-3.585v-3c0-1.499-.136-2.79-.749-3.709C15.436 5.944 14.345 5.25 12 5.25m-1.237 14.487a1.8 1.8 0 0 1-.344-.487h3.162a1.75 1.75 0 0 1-1.581 1 1.75 1.75 0 0 1-1.237-.513"
    clip-rule="evenodd"
  />
</svg>`;

export const checkmark = html`<svg
  xmlns="http://www.w3.org/2000/svg"
  width="34"
  height="34"
  viewBox="0 0 34 34"
  fill="none"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M3.25 17C3.25 9.40608 9.40608 3.25 17 3.25C24.5939 3.25 30.75 9.40608 30.75 17C30.75 24.5939 24.5939 30.75 17 30.75C9.40608 30.75 3.25 24.5939 3.25 17ZM17 0.75C8.02537 0.75 0.75 8.02537 0.75 17C0.75 25.9746 8.02537 33.25 17 33.25C25.9746 33.25 33.25 25.9746 33.25 17C33.25 8.02537 25.9746 0.75 17 0.75ZM24.6209 12.8074C25.0668 12.2804 25.0011 11.4917 24.4741 11.0457C23.9471 10.5998 23.1584 10.6655 22.7124 11.1925L14.4233 20.9888L10.3839 16.9494C9.89573 16.4613 9.10427 16.4613 8.61612 16.9494C8.12796 17.4376 8.12796 18.229 8.61612 18.7172L13.6161 23.7172C13.8633 23.9644 14.2027 24.0968 14.5519 24.0822C14.9012 24.0677 15.2284 23.9076 15.4542 23.6407L24.6209 12.8074Z"
    fill="#115A11"
  />
</svg>`;

export const circleSlashIcon = html`
  <svg
    width="18"
    height="17"
    viewBox="0 0 18 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.24834 2.76167C2.49474 3.51503 1.89719 4.40969 1.48993 5.39437C1.08268 6.37906 0.873708 7.43443 0.875006 8.50001C0.875006 12.9875 4.51251 16.625 9.00001 16.625C13.4875 16.625 17.125 12.9875 17.125 8.50001C17.125 4.01251 13.4875 0.375006 9.00001 0.375006C7.93443 0.373708 6.87906 0.582675 5.89437 0.989934C4.90969 1.39719 4.01503 1.99474 3.26167 2.74834L3.25501 2.75501L3.24834 2.76167ZM4.60084 3.21667C5.91869 2.11708 7.60018 1.55018 9.31479 1.62743C11.0294 1.70467 12.6531 2.42046 13.8667 3.6341C15.0804 4.84774 15.7962 6.47145 15.8734 8.18606C15.9507 9.90066 15.3838 11.5822 14.2842 12.9L4.60001 3.21667H4.60084ZM3.71667 4.10001L13.4 13.7833C12.0822 14.8829 10.4007 15.4498 8.68606 15.3726C6.97145 15.2953 5.34774 14.5795 4.1341 13.3659C2.92046 12.1523 2.20467 10.5286 2.12743 8.81396C2.05018 7.09935 2.61708 5.41786 3.71667 4.10001Z"
      fill="#B40200"
    />
  </svg>
`;

export const infoCircleSolid = html`
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 2.25C6.62402 2.25 2.25 6.62402 2.25 12C2.25 17.376 6.62402 21.75 12 21.75C17.376 21.75 21.75 17.376 21.75 12C21.75 6.62402 17.376 2.25 12 2.25ZM12 3.75C16.5645 3.75 20.25 7.43555 20.25 12C20.25 16.5645 16.5645 20.25 12 20.25C7.43555 20.25 3.75 16.5645 3.75 12C3.75 7.43555 7.43555 3.75 12 3.75ZM11.25 7.5V9H12.75V7.5H11.25ZM11.25 10.5V16.5H12.75V10.5H11.25Z"
      fill="black"
    />
  </svg>
`;

export const errorCircle = html`
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.332 16C5.332 14.066 5.809 12.281 6.766 10.645C7.69665 9.03409 9.03433 7.69606 10.645 6.765C12.28 5.81 14.066 5.333 16 5.333C17.934 5.333 19.719 5.81 21.355 6.767C22.9659 7.69765 24.3039 9.03533 25.235 10.646C26.191 12.282 26.668 14.067 26.668 16.001C26.668 17.935 26.191 19.72 25.234 21.356C24.3034 22.9669 22.9657 24.3049 21.355 25.236C19.719 26.192 17.934 26.669 16 26.669C14.066 26.669 12.281 26.192 10.645 25.235C9.03409 24.3044 7.69607 22.9667 6.765 21.356C5.81 19.72 5.333 17.934 5.333 16H5.332ZM10.707 19.14C10.707 19.39 10.797 19.602 10.973 19.777L12.223 21.027C12.3052 21.1125 12.404 21.1802 12.5134 21.2259C12.6228 21.2717 12.7404 21.2945 12.859 21.293C12.9759 21.294 13.0917 21.2709 13.1992 21.2251C13.3068 21.1794 13.4037 21.1119 13.484 21.027L16 18.516L18.516 21.027C18.5964 21.1117 18.6934 21.1791 18.8009 21.2248C18.9084 21.2706 19.0242 21.2938 19.141 21.293C19.391 21.293 19.601 21.203 19.777 21.027L21.027 19.777C21.1125 19.6948 21.1802 19.596 21.2259 19.4866C21.2717 19.3772 21.2945 19.2596 21.293 19.141C21.294 19.0241 21.2709 18.9083 21.2251 18.8008C21.1794 18.6932 21.1119 18.5963 21.027 18.516L18.516 16L21.027 13.484C21.1117 13.4036 21.1791 13.3066 21.2248 13.1991C21.2706 13.0916 21.2938 12.9758 21.293 12.859C21.293 12.609 21.203 12.399 21.027 12.223L19.777 10.973C19.6948 10.8875 19.596 10.8198 19.4866 10.7741C19.3772 10.7283 19.2596 10.7055 19.141 10.707C19.0241 10.706 18.9083 10.7291 18.8008 10.7749C18.6932 10.8206 18.5963 10.8881 18.516 10.973L16 13.484L13.484 10.973C13.4036 10.8883 13.3066 10.8209 13.1991 10.7752C13.0916 10.7294 12.9758 10.7062 12.859 10.707C12.609 10.707 12.399 10.797 12.223 10.973L10.973 12.223C10.8875 12.3052 10.8198 12.404 10.7741 12.5134C10.7283 12.6228 10.7055 12.7404 10.707 12.859C10.707 13.102 10.797 13.309 10.973 13.484L13.484 16L10.973 18.516C10.8883 18.5964 10.8209 18.6934 10.7752 18.8009C10.7294 18.9084 10.7062 19.0242 10.707 19.141V19.14Z"
      fill="#AA0000"
    />
  </svg>
`;

export const creditWarningSymbol = html`
  <svg
    width="61"
    height="53"
    viewBox="0 0 61 53"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M60.5118 48.8803L32.7617 1.61949C31.7297 -0.156368 29.2069 -0.156368 28.1749 1.61949L0.367482 48.8803C-0.664546 50.6562 0.596821 52.8903 2.66088 52.8903H58.161C60.2251 52.8903 61.4865 50.6562 60.4544 48.8803H60.5118ZM51.0515 46.6462H9.88507C9.5984 46.6462 9.36906 46.3025 9.54106 46.016L30.1243 10.4415C30.2963 10.1551 30.6403 10.1551 30.8123 10.4415L51.3955 46.016C51.5675 46.3025 51.3955 46.6462 51.0515 46.6462Z"
      fill="#930005"
    />
    <path
      d="M30.3825 43.5502C29.58 43.5502 28.9494 43.2637 28.3762 42.6907C27.8029 42.1177 27.5163 41.4874 27.5736 40.6852C27.5736 39.9403 27.8602 39.2526 28.3762 38.6796C28.9494 38.1066 29.58 37.8774 30.3825 37.8774C31.1851 37.8774 31.8157 38.1639 32.3316 38.6796C32.9048 39.2526 33.1915 39.8829 33.1915 40.6852C33.1915 41.2009 33.0195 41.6593 32.7902 42.1177C32.5036 42.5188 32.1596 42.8626 31.7583 43.1491C31.3571 43.3783 30.8411 43.5502 30.3825 43.5502ZM33.0195 19.0825L32.5609 35.9865H28.2615L27.8029 19.0825H33.0768H33.0195Z"
      fill="#930005"
    />
  </svg>
`;

export const chargerIncluded = html`
  <svg
    width="59"
    height="82"
    viewBox="0 0 59 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="8.29688"
      y="29.7539"
      width="42"
      height="42"
      rx="1.5"
      stroke="black"
    />
    <rect x="15.4688" y="20.0039" width="27.67" height="9.75" stroke="black" />
    <rect x="20.8359" y="10.2461" width="2.58" height="9.75" stroke="black" />
    <rect x="35.1641" y="10.2461" width="2.58" height="9.75" stroke="black" />
    <rect x="1.79688" y="2.08203" width="55" height="77.83" stroke="black" />
  </svg>
`;

export const chargerNotIncluded = html`
  <svg
    width="59"
    height="82"
    viewBox="0 0 59 82"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_8834_146081)">
      <rect
        x="8.60938"
        y="29.7734"
        width="42"
        height="42"
        rx="1.5"
        stroke="black"
      />
      <rect
        x="15.7812"
        y="20.0234"
        width="27.67"
        height="9.75"
        stroke="black"
      />
      <rect x="21.1484" y="10.2656" width="2.58" height="9.75" stroke="black" />
      <rect x="35.4766" y="10.2656" width="2.58" height="9.75" stroke="black" />
      <rect x="2.10938" y="2.10156" width="55" height="77.83" stroke="black" />
      <path
        d="M1.78125 1.91016L57.4366 80.1309"
        stroke="black"
        stroke-width="2"
        stroke-linecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_8834_146081">
        <rect
          width="58"
          height="81"
          fill="white"
          transform="translate(0.601562 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
`;

export const chargerForText = html`
  <svg
    fill="none"
    version="1.1"
    viewBox="0 0 58 82"
    width="58"
    height="82"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(.98118 .26988)" stroke="#000" stroke-width="1.3032">
      <rect x=".6516" y="26.074" width="54.734" height="54.734" rx="1.9548" />
      <rect x="9.998" y="13.368" width="36.06" height="12.706" />
      <rect x="16.993" y=".6516" width="3.3623" height="12.706" />
      <rect x="35.665" y=".6516" width="3.3623" height="12.706" />
    </g>
  </svg>
`;

export const shieldAltSolid = html`
  <svg
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16 4.25293C13.75 4.25293 12.2344 5.13965 10.875 5.87793C9.51562 6.61621 8.28125 7.25293 6 7.25293H5V8.25293C5 15.9717 7.60938 20.9951 10.25 24.0342C12.8906 27.0732 15.625 28.1904 15.625 28.1904L16 28.3154L16.375 28.1904C16.375 28.1904 19.1094 27.0967 21.75 24.0654C24.3906 21.0342 27 15.999 27 8.25293V7.25293H26C23.7305 7.25293 22.4844 6.61621 21.125 5.87793C19.7656 5.13965 18.25 4.25293 16 4.25293ZM16 6.25293C17.75 6.25293 18.7539 6.86621 20.1562 7.62793C21.3398 8.27246 22.9102 8.88965 24.9375 9.09668C24.7461 15.8623 22.5078 20.1631 20.25 22.7529C18.2031 25.1006 16.4844 25.8818 16 26.0967C15.5117 25.8779 13.7969 25.0771 11.75 22.7217C9.49219 20.124 7.25391 15.8311 7.0625 9.09668C9.09766 8.88965 10.6602 8.27246 11.8438 7.62793C13.2461 6.86621 14.25 6.25293 16 6.25293Z"
      fill="#1F1A49"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M21.698 12.5586C22.1007 12.9661 22.1007 13.6268 21.698 14.0343L15.8542 19.9473C15.4515 20.3548 14.7985 20.3548 14.3958 19.9473L11.302 16.8169C10.8993 16.4094 10.8993 15.7487 11.302 15.3412C11.7048 14.9337 12.3577 14.9337 12.7605 15.3412L15.125 17.7338L20.2395 12.5586C20.6423 12.1511 21.2952 12.1511 21.698 12.5586Z"
      fill="#1F1A49"
    />
  </svg>
`;

export const CONFIG_SERVICE_TYPES = {
  TV: tvCalibration,
  INSTALL: installation,
  CARE: carePackage,
  SCREEN: screenProtection,
  DEFAULT: additionalService,
};

export const getEnergyIcon = ({
  energyGradeLetter,
  isLegacyEnergyLogo,
  legacyEnergyLogoIcon,
}) => {
  if (isLegacyEnergyLogo) {
    return legacyEnergyLogo[legacyEnergyLogoIcon]?.icon;
  }

  switch (energyGradeLetter.toLowerCase()) {
    case "g":
      return energyIconG;
    case "f":
      return energyIconF;
    case "e":
      return energyIconE;
    case "d":
      return energyIconD;
    case "c":
      return energyIconC;
    case "b":
      return energyIconB;
    case "a":
      return energyIconA;
    default:
      return "";
  }
};
