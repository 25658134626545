import { LitElement, html, css } from "lit";

class Recommentations extends LitElement {
  static properties = {
    products: { type: Array },
  };

  constructor() {
    super();
    this.products = [];
  }

  connectedCallback() {
    super.connectedCallback();

    this.search(this.searchText);
  }

  async search() {
    let url = "https://api.escuelajs.co/api/v1/products?";
    const response = await fetch(`${url}offset=0&limit=6`);
    const jsonResponse = await response.json();
    this.products = jsonResponse;
  }

  render() {
    return html`
      ${this.products.map(
        (product) =>
          html`
            <div class="rr-box">
              <img
                alt="64x64"
                height="150"
                width="200"
                src="data:image/gif;base64,R0lGODlhAQABAIAAAHd3dwAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="
              />
              <h2>${product.title}</h2>
              <p class="price">$${product.price}.00</p>
              <div class="description"><p>${product.description}</p></div>
            </div>
          `
      )}
    `;
  }

  static styles = css`
    :host {
      height: 170px;
      display: flex;
      flex-flow: wrap;
      justify-content: space-around;
      align-items: start;
      gap: 16px;
      overflow: hidden;
      padding: 0 5px 12px;
    }

    .rr-box {
      background-color: #fff;
      box-shadow: 1px 1px 4px -2px rgba(0, 0, 0, 0.25),
        -1px -1px 4px -2px rgba(0, 0, 0, 0.25);
      max-width: 150px;
      gap: 10px;
      text-align: center;
      padding: 10px;
    }

    .rr-box img {
      width: 100%;
      height: auto;
      aspect-ratio: 200 / 100;
    }

    .rr-box h2 {
      font-family: inherit;
      font-size: 20px;
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
    }

    .rr-box .description {
      overflow: hidden;
    }

    .rr-box p {
      margin: 0;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      max-width: 150px;
    }
  `;
}
customElements.define("rr-recommendations", Recommentations);
