import { EVENTS, triggerCustomEvent } from "../utils/customEvents.js";

export const GRID_VARIANTS = Object.freeze({
	DEFAULT: "",
	CMS: "cms-content",
	RECOMMENDATIONS: "recommendations",
	CMS_AND_RECOMMENDATIONS: "cms-content|recommendations",
});

export const PAGE_TYPES = Object.freeze({
  SEARCH: "searchpage",
  CATEGORY: "categorypage",
  MANUFACTURER: "manufacturerpage"
});

export const FACET_TYPES = Object.freeze({
  CATEGORY: "category",
  FACETS: "facets", 
  MANUFACTURER: "manufacturer"
});

export const trackSearch = (tracking) => {
	if (tracking === null || tracking === undefined) {
		return;
	}

	dataLayer.push({
		event: "search",
		search_term: tracking.searchTerm,
		search_suggestion: "",
		search_type: "static",
		filter_category: tracking.selectedCategory,
		filter_manufacturer: tracking.selectedManufacturer ?? undefined,
		search_results: tracking.hits,
		search_corrected: false,
	});
};

export const trackFilter = (facets) => {
	const categoryFacets = facets.filter((f) => f.categoryId !== undefined);
	const selectedCategory = categoryFacets.sort((c) => c.categoryLevel).reverse()[0];

  const manufacturer = facets.filter((f) => f.attribute.name === "kom_Produsent")[0];

	dataLayer.push({
		event: "filter",
		filter_manufacturer: manufacturer?.name,
		filter_category: selectedCategory?.categoryId,
		filters: facets.map((f) => {
			return {
				filter_name: f.attribute.name,
				filter_value: f.name,
			};
		}),
	});
};

export const isInParamWhiteList = (key) => {
	return (
		key === "query" ||
		key === "sort" ||
		key.includes("utm_") ||
		key === "fbclid" ||
		key.includes("li_")
	);
};

export const getAddToCartSearchTracking = (queryId, sort) => {
	return (product, quantity) => {
    triggerCustomEvent(EVENTS.SEARCH_ADD_TO_CART, {
      queryId: queryId,
      materialNumber: product.materialNumber,
      quantity: quantity,
      price: product.price?.listPriceNumber,
      discount: product.price?.discountNumber,
      sort: sort,
    });
	};
};
