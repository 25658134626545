import { css, html, LitElement } from "lit";

export class H4 extends LitElement {
  constructor() {
    super();
  }

  render() {
    return html`<h4><slot></slot></h4>`;
  }

  static styles = css`
    h4 {
      font-family: "Roboto", sans-serif;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      margin: 0;
      padding: 0;
    }
  `;
}
