import { css, html, LitElement } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { repeat } from "lit/directives/repeat.js";
import { starSvg, starEmptySvg } from "../../utils/svgProvider.js";
import strings from "../../utils/strings.js";

class Stars extends LitElement {
  static properties = {
    disabled: { type: Boolean },
    value: { type: Number },
    error: { type: String },
    onChange: { type: Function },
  };

  constructor() {
    super();
    this.value = 0;
    this.disabled = false;
    this.onChange = () => {};
  }

  render() {
    return html`
      <div class="container">
        <komplett-label bold="true" required="true">
          ${strings["product.addreview.rating.label"]}
        </komplett-label>
        <div class="stars">
          ${repeat(
            [1, 2, 3, 4, 5],
            (score) => score,
            (score) => html`
              <button
                @click=${() => this.onChange(score)}
                class=${classMap({ active: this.value >= score })}
                type="button"
                aria-label="Score ${score}"
                ?disabled=${this.disabled}
              >
                ${starEmptySvg}${starSvg}
              </button>
            `
          )}
        </div>
        <komplett-input-error
          marginTop="12"
          text=${this.error}
        ></komplett-input-error>
      </div>
    `;
  }

  static styles = css`
    .container {
      display: flex;
      flex-direction: column;
    }

    .stars {
      margin-top: 12px;
      display: flex;
      gap: 8px;
    }

    button {
      border-radius: 4px;
      display: flex;
      border: none;
      padding: 0;
      margin: 0;
      cursor: pointer;
      background: none;
      position: relative;
      width: 48px;
      height: 48px;
      outline: 2px solid transparent;
      transition: outline 0.2s ease;
    }

    button:focus:not(:disabled),
    button:active:not(:disabled),
    button:hover:not(:disabled) {
      outline-color: #222222;
    }

    button.active svg:last-child {
      opacity: 1;
    }

    button:disabled {
      cursor: not-allowed;
    }

    svg {
      width: 48px;
      height: 48px;
      position: absolute;
      transition: opacity 0.2s ease;
    }

    svg:first-child path {
      fill: #f8b912;
    }

    svg:last-child {
      opacity: 0;
    }
  `;
}

customElements.define("komplett-add-review-stars", Stars);
