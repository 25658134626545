import { LitElement, html, css, nothing } from "lit";
import { classMap } from "lit/directives/class-map.js";
import { unsafeHTML } from "lit/directives/unsafe-html.js";

import { modalStyles } from "./modal-container.styles.js";
import { optionStyles } from "../configService/config-service.styles.js";
import strings from "../../utils/strings.js";

class ModalAccessories extends LitElement {
  static properties = {
    selectedService: { type: Object },
    servicesState: { type: Array },
    selectAccessory: {
      type: Function,
    },
    openAccessoriesModal: {
      type: Function,
    },
  };

  handleDetailsSectionClick(accessoryId) {
    window.open(`/product/${accessoryId}`, "_blank");
  }

  render() {
    return html`
      <div class="modal-content">
        <div class="modal-content-info">
          <h2
            data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.name`}
          >
            ${this.selectedService.name}
          </h2>
          <p>${unsafeHTML(this.selectedService.informationText)}</p>

          ${this.selectedService.children.length
            ? html`<span
                data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.available.accessories`}
                ><strong
                  >${strings[
                    "product.box.config.service.additional.services.service.selected.option"
                  ]}</strong
                >
                ${this.selectedService.children.length}
                ${strings[
                  "product.box.config.service.additional.services.available"
                ]}</span
              >`
            : nothing}
        </div>

        ${this.selectedService.children.length
          ? this.selectedService.children?.map(
              (accessory) =>
                html`<div
                  data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory`}
                  class=${classMap({
                    "is-selected":
                      accessory.childId ===
                      this.servicesState._state[
                        this.selectedService.serviceGroupId
                      ].selectedAccessoryId,
                    "service-option": true,
                  })}
                >
                  <label htmlFor=${accessory.childId}>
                    <input
                      id=${accessory.childId}
                      data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory.input`}
                      type="radio"
                      name="accessory"
                      @click=${() =>
                        this.selectAccessory({
                          serviceGroupId: this.selectedService.serviceGroupId,
                          accessoryId: accessory.childId,
                          accessoryPrice: accessory.price,
                        })}
                      .checked=${accessory.childId ===
                      this.servicesState._state[
                        this.selectedService.serviceGroupId
                      ].selectedAccessoryId}
                      .value=${accessory.childId}
                    />
                    <div class="service-details">
                      <h3
                        data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory.name`}
                      >
                        ${accessory.name}
                      </h3>
                    </div>
                    <div class="service-price">
                      <p
                        data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory.price`}
                      >
                        ${accessory.priceFormatted}
                      </p>
                    </div>
                  </label>
                  <div
                    class="service-details"
                    @click=${() =>
                      this.handleDetailsSectionClick(accessory.childId)}
                  >
                    <i class="service-frame"
                      ><img src="/img/p/200/${accessory.childId}.jpg"
                    /></i>
                    <div class="service-description">
                      <div
                        data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory.description`}
                      >
                        ${accessory.description}
                      </div>
                      <a
                        target="_blank"
                        data-testid=${`${this.selectedService.serviceGroupId}.${accessory.childId}.config.service.option.accessory.product.link`}
                        href="/product/${accessory.childId}"
                        >${strings[
                          "product.box.config.service.additional.services.service.see.product"
                        ]}</a
                      >
                    </div>
                  </div>
                </div>`
            )
          : html`<div
              data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory`}
              class=${classMap({
                "is-selected":
                  this.selectedService.serviceGroupId ===
                  this.servicesState._state[this.selectedService.serviceGroupId]
                    .selectedAccessoryId,
                "service-option": true,
              })}
            >
              <label htmlFor=${this.selectedService.serviceGroupId}>
                <input
                  id=${this.selectedService.serviceGroupId}
                  data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory.input`}
                  type="radio"
                  name="accessory"
                  @click=${() =>
                    this.selectAccessory({
                      serviceGroupId: this.selectedService.serviceGroupId,
                      accessoryId: this.selectedService.serviceGroupId,
                      accessoryPrice: this.selectedService.price,
                    })}
                  .checked=${this.selectedService.serviceGroupId ===
                  this.servicesState._state[this.selectedService.serviceGroupId]
                    .selectedAccessoryId}
                  .value=${this.selectedService.serviceGroupId}
                />
                <div class="service-details">
                  <h3
                    data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory.name`}
                  >
                    ${this.selectedService.name}
                  </h3>
                </div>
                <div class="service-price">
                  <p
                    data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory.price`}
                  >
                    ${this.selectedService.priceFormatted}
                  </p>
                </div>
              </label>
              <div
                class="service-details"
                @click=${() =>
                  this.handleDetailsSectionClick(
                    this.selectedService.serviceTypeId
                  )}
              >
                <i class="service-frame"
                  ><img
                    src="/img/p/200/${this.selectedService.serviceTypeId}.jpg"
                /></i>
                <div class="service-description">
                  <div
                    data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory.description`}
                  >
                    ${this.selectedService.description}
                  </div>
                  <a
                    target="_blank"
                    data-testid=${`${this.selectedService.serviceGroupId}.config.service.option.accessory.product.link`}
                    href="/product/${this.selectedService.serviceTypeId}"
                    >${strings[
                      "product.box.config.service.additional.services.service.see.product"
                    ]}</a
                  >
                </div>
              </div>
            </div>`}
      </div>
    `;
  }

  static styles = [modalStyles, optionStyles];
}

customElements.define("modal-accessories", ModalAccessories);
